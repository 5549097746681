.transaction-history-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 160px;
}
@media (max-width: 1024px) {
  .transaction-history-page-container {
    width: 100%;
    margin-left: 0px;
  }
}
.transaction-history-page-container .transaction-page-description {
  width: 1000px;
  font-size: 12px;
  margin: 20px auto;
}
@media (max-width: 1024px) {
  .transaction-history-page-container .transaction-page-description {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .transaction-history-page-container .transaction-page-description {
    width: 350px;
  }
}
.transaction-history-page-container .card-container {
  width: 765px;
  background-color: #F4F8F9;
  height: 130px;
  margin: 20px auto;
  border: 1px solid rgba(17, 22, 26, 0.16);
  box-shadow: 0 4px 3px 0 rgba(17, 22, 26, 0.16);
}
@media (max-width: 1024px) {
  .transaction-history-page-container .card-container {
    width: 500px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .transaction-history-page-container .card-container {
    width: 350px;
  }
}
.transaction-history-page-container .card-container .header {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  letter-spacing: 0.59px;
  height: 40px;
  text-align: left;
  padding: 0px 10px;
}
.transaction-history-page-container .card-container .sub-header {
  color: #4A4A4A;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 300;
  letter-spacing: 0.59px;
  height: 30px;
  text-align: left;
  padding: 5px 10px;
}
.transaction-history-page-container .card-container .data {
  display: flex;
}
@media (max-width: 1024px) {
  .transaction-history-page-container .card-container .data {
    display: block;
  }
}
.transaction-history-page-container .card-container .data .single-data-container {
  flex: 1;
  padding: 5px 10px;
}
.transaction-history-page-container .card-container .data .single-data-container .label {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.transaction-history-page-container .card-container .data .single-data-container .value {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  width: 240px;
  text-align: left;
}
.transaction-history-page-container .card-container .data .pricing-container {
  flex: 2;
  padding: 5px 10px;
  display: flex;
}
.transaction-history-page-container .card-container .data .pricing-container .single-data {
  width: 70px;
}
.transaction-history-page-container .card-container .data .pricing-container .single-data .label {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.transaction-history-page-container .card-container .data .pricing-container .single-data .value {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}