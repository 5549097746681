.confirm-purchase-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 160px;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container {
    width: 100%;
    margin-left: 0px;
  }
}
.confirm-purchase-page-container .before-trial-text {
  margin: 20px auto;
  width: 1000px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .before-trial-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .before-trial-text {
    width: 350px;
  }
}
.confirm-purchase-page-container .billing-failed-container {
  width: 700px !important;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .billing-failed-container {
    width: 350px !important;
  }
}
.confirm-purchase-page-container .billing-failed-container .status-description-text {
  margin: 20px auto;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .billing-failed-container .status-description-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .billing-failed-container .status-description-text {
    width: 350px;
  }
}
.confirm-purchase-page-container .status-description-text {
  margin: 20px auto;
  width: 550px;
  text-align: left;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .status-description-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .status-description-text {
    width: 350px;
  }
}
.confirm-purchase-page-container .resubscribe {
  height: 40px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 100px;
  width: 300px;
  color: #007AFF;
  background-color: #FFFFFF;
  border: 1px solid #007AFF;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .resubscribe {
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .resubscribe {
    width: 350px;
  }
}
.confirm-purchase-page-container .add-edit-button-description {
  font-size: 12px;
  margin: 5px 0px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner {
  margin: 100px auto;
  width: 500px;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .billing-page-inner {
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .billing-page-inner {
    width: 350px;
    margin: 20px auto !important;
  }
}
.confirm-purchase-page-container .billing-page-inner .transactions-button-container {
  width: 500px;
  margin: 50px auto;
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .billing-page-inner .transactions-button-container {
    width: 350px;
  }
}
.confirm-purchase-page-container .billing-page-inner .transactions-button-container .section-link {
  background-color: #FFFFFF;
  color: #007AFF;
  border: 1px solid #007AFF;
  margin: 20px auto;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .single-box {
  width: 500px;
  border: 1px solid #007AFF;
  margin: 50px auto;
}
@media (max-width: 768px) {
  .confirm-purchase-page-container .billing-page-inner .single-box {
    width: 350px;
    height: auto;
  }
}
.confirm-purchase-page-container .billing-page-inner .single-box .single-box-header {
  background-color: #007AFF;
  color: #FFFFFF;
  height: 60px;
  line-height: 60px;
  font-size: 22px;
}
.confirm-purchase-page-container .billing-page-inner .single-box .details-container {
  min-height: 120px;
  font-size: 18px;
  padding-top: 20px;
  color: #555555;
}
.confirm-purchase-page-container .billing-page-inner .single-box .details-container .description {
  font-size: 16px;
  margin: 10px auto;
}
.confirm-purchase-page-container .billing-page-inner .single-box .details-container .section-link {
  background-color: #FFFFFF;
  color: #007AFF;
  border: 1px solid #007AFF;
  margin: 20px auto;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .usage-container {
  height: 200px;
  width: 300px;
  margin: 10px auto;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .header {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .header .tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .confirm-purchase-page-container .billing-page-inner .usage-container .header .tooltip-text {
    display: none;
  }
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner {
  display: flex;
  width: 300px;
  justify-content: center;
  margin: 20px auto;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .application {
  width: 145px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .application .application-cells .blue-user-icon {
  font-size: 40px;
  margin: 0px 5px;
  color: #007AFF;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .application .application-cells .gray-user-icon {
  font-size: 40px;
  margin: 0px 5px;
  color: #E6E6E6;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .application .application-text {
  margin-top: 10px;
  font-size: 11px;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .divider {
  background-color: #D8D8D8;
  border: 1px solid #979797;
  width: 1px;
  margin: 0px 10px;
  height: 100px;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .usage .days {
  width: 182px;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container {
  max-width: 182px;
  display: flex;
  flex-wrap: wrap;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container .gray-cell {
  margin: 2px;
  min-height: 22px;
  min-width: 22px;
  background-color: #E6E6E6;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container .blue-cell {
  margin: 2px;
  min-height: 22px;
  min-width: 22px;
  background-color: #007AFF;
}
.confirm-purchase-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-text {
  margin-top: 10px;
  font-size: 11px;
}
.confirm-purchase-page-container .billing-page-inner .current-pack-container .pack-header-container {
  display: flex;
  justify-content: space-between;
}
.confirm-purchase-page-container .billing-page-inner .current-pack-container .pack-header-container .header {
  color: #666666;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .current-pack-container .pack-header-container .change-pack-button {
  border: 1px solid #007AFF;
  width: 105px;
  height: 26px;
  background-color: #FFFFFF;
  color: #007AFF;
  outline: none;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.29px;
  line-height: 11px;
  text-align: center;
}
.confirm-purchase-page-container .billing-page-inner .current-pack-container .pack-name {
  color: #4A4A4A;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .section-container {
  margin: 25px 0px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .section-container .add-edit-button-description {
  font-size: 12px;
  margin: 5px 0px;
  text-align: left;
}
.confirm-purchase-page-container .billing-page-inner .section-container .section-header {
  color: #666666;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 5px;
}
.confirm-purchase-page-container .billing-page-inner .section-container .resubscribe-description-text {
  margin: 0px;
  margin-top: 5px;
}
.confirm-purchase-page-container .billing-page-inner .section-container .section-link {
  background-color: #007AFF;
  color: #FFFFFF;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}