.info-modal-after-registration-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  // height: 100vh;
  // width: 100vw;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.85);
  @media (max-width: 768px) {
    left: 0px;
    padding-top: 100px;
    // top: 100px;
  }
  .modal-outer-container {
    margin-left: 220px;
    @media (max-width: 768px) {
      // left: 0px;
      margin-left: 0px;
      margin-top: 10px;

    }
    .modal-inner-container {
      width: 350px;
      top: 50px;
      background-color: #FFFFFF;
      margin: 10px auto;
      border-radius: 15px;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: 350px;
      }
      .header {
        font-size: 25px;
        margin: 10px auto;
        padding-top: 20px;
        color: #007AFF;
      }
      .sub-header {
        text-align: center;
        font-size: 11px;
        margin: 0px auto;
        padding-top: 20px;
        color: #007AFF;
      }
      .info-image-1 {
        // height: 200px;
        width: 200px;
        margin: 20px auto;
      }
    }
  }

}
