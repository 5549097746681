.info-modal-after-first-job-creation {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
}
@media (max-width: 768px) {
  .info-modal-after-first-job-creation {
    left: 0px;
    padding-top: 300px;
  }
}
.info-modal-after-first-job-creation .modal-outer-container {
  margin-left: 220px;
  margin-top: 80px;
}
@media (max-width: 768px) {
  .info-modal-after-first-job-creation .modal-outer-container {
    margin-left: 0px;
  }
}
.info-modal-after-first-job-creation .modal-outer-container .modal-inner-container {
  width: 350px;
  top: 50px;
  background-color: #FFFFFF;
  margin: 10px auto;
  border-radius: 15px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .info-modal-after-first-job-creation .modal-outer-container .modal-inner-container {
    width: 350px;
  }
}
.info-modal-after-first-job-creation .modal-outer-container .modal-inner-container .header {
  font-size: 25px;
  margin: 10px auto;
  padding-top: 20px;
  color: #007AFF;
}
.info-modal-after-first-job-creation .modal-outer-container .modal-inner-container .sub-header {
  text-align: center;
  font-size: 11px;
  margin: 0px auto;
  padding-top: 20px;
  color: #007AFF;
}
.info-modal-after-first-job-creation .modal-outer-container .modal-inner-container .info-image-1 {
  width: 200px;
  margin: 20px auto;
}