// thead, tbody { display: block !important; }
//
// tbody {
//     height: 100px !important;       /* Just for the demo          */
//     overflow-y: auto !important;    /* Trigger vertical scroll    */
//     overflow-x: hidden !important;  /* Hide the horizontal scroll */
// }

.BaseTable__table .BaseTable__body {
  .BaseTable__row {
    @media (max-width: 768px) {
      border: 1px solid #eeeeee;
    }
  }
  .BaseTable__row-cell {
    @media (max-width: 768px) {
      font-size: 10px;
      // padding: 5px;
      padding-right: 0px;
      padding-left: 5px;
    }
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    // background-color: #e3e3e3;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid #e3e3e3;
    background-color: #999;

    &:hover {
      background-color: #666;
    }
  }

  &::-webkit-resizer {
    display: none;
  }
}


.interview-times-container {
  margin: 140px auto;
  // padding-top: 30px;
  margin-left: 220px;
  @media (max-width: 768px) {
    overflow-x: hidden;
    margin-left: 0px;
  }
  .inner-container {
    width: 100%;
    display: block;
    margin-top: 100px;
    @media (max-width: 1024px) {
      margin-top: 50px;
    }
    .sort-select {
      width: 350px;
      margin: 20px auto;
    }
    .job-select-container {
      display: block;
      width: 500px;
      margin: 10px auto;
      select {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 350px;
      }
    }
    .calendar-container {
      width: 1000px;
      overflow-x: scroll;
      margin: 0px auto;
      padding: 10px;
      .desktop-calendar {
        @media (max-width: 768px) {
          display: none;
        }
      }
      .mobile-calendar {
        display: none;
        font-size: 12px;
        @media (max-width: 768px) {
          display: block;
        }
      }
      @media (max-width: 768px) {
        width: 350px;
        max-width: 350px;
        min-width: 350px;
        padding: 0px;
        margin: 20px auto;
      }
      .no-data-text {
        width: 500px;
        margin: 20px auto;
      }
      .table-parent {
        width: 1000px;
        margin: 0px auto;
        max-height: 100px;
        border: 1px solid #e0e0e0;
        .table-head {
          position: sticky !important;
          left: 0;
          top: 0;
          z-index: 99;
          // padding-right: 10px !important;
          background-color: white;
          min-width: 250px !important;
          @media (max-width: 768px) {
            min-width: 90px !important;
          }
        }
        .table-body {
          max-height: 75% !important;
          .column {
            padding: 10px;
          }
        }
        .column {
          padding: 10px;
        }
      }
    }
  }
}

.rbc-timeslot-group {
  height: 70px !important;
}

.rbc-calendar {

  .rbc-time-header-content {
    margin-right: 9px;
  }
  .rbc-time-content {
    // overflow: visible !important;
    &::-webkit-scrollbar {
      width: 10px;
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;

      border: 2px solid white; /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, .5);
    }
    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }
    &::-webkit-scrollbar:vertical {
      height: 10px;
    }
  }

  @media (max-width: 768px) {
    // width: 350px;
  }
}
