html {
  scroll-behavior: smooth;
}
html body {
  font-family: "M PLUS 1p" !important;
}

.btn {
  color: #FFFFFF;
  border: 1px solid #007AFF;
  border-radius: 4px;
  width: auto !important;
  min-width: 120px;
  height: 40px;
  outline: none;
  font-size: 15px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #FFFFFF;
  background-color: #007AFF;
  cursor: pointer;
  text-decoration: none;
  padding: 0px 10px;
}
@media (max-width: 768px) {
  .btn {
    min-width: 100px;
    font-size: 12px;
    max-width: 120px;
  }
}

.btn-primary {
  background: #007AFF;
}
.btn-primary:hover {
  background: rgb(0, 102.48, 214.2);
  transition: all 0.3s ease;
}
.btn-primary:active {
  background: rgb(0, 61, 127.5);
}

.btn-secondary {
  background: #FFFFFF;
  color: #007AFF !important;
}
.btn-secondary:hover {
  background: rgb(234.6, 234.6, 234.6);
  transition: all 0.3s ease;
}
.btn-secondary:active {
  background: rgb(191.25, 191.25, 191.25);
}

.btn-danger {
  background: #ED5356;
  height: 30px;
  padding: 5px 10px;
  border-color: #ED5356;
}
.btn-danger:hover {
  background: rgb(233.1347368421, 46.0652631579, 49.7094736842);
  transition: all 0.3s ease;
}
.btn-danger:active {
  background: rgb(174.2631578947, 18.2368421053, 21.2763157895);
}

.btn-dimension {
  width: 180px !important;
}

.btn-action {
  background: #007AFF;
  height: 30px;
  padding: 5px 10px;
}
.btn-action:hover {
  background: rgb(0, 102.48, 214.2);
  transition: all 0.3s ease;
}
.btn-action:active {
  background: rgb(0, 61, 127.5);
}

.btn-disabled {
  color: #9B9B9B !important;
  border-color: #9B9B9B !important;
}
.btn-disabled:active {
  background: rgb(91.25, 91.25, 91.25) !important;
}

.App {
  text-align: center;
}

.drawer-styles {
  width: 100%;
  height: 100%;
}