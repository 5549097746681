.autosuggest-error {
  color: #e06e6f;
  text-align: left;
  margin: 5px 0px;
  font-size: 10px;
}

.add-locations-container .error-message-container {
  color: #e06e6f;
  text-align: left;
  margin: 5px 0;
  font-size: 10px;
}
.add-locations-container .location-details-container {
  background-color: #F2F3F7;
  padding: 10px;
  margin-top: 10px;
}
.add-locations-container .location-details-container .label {
  color: #9B9B9B;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
  margin: 0 !important;
}
.add-locations-container .location-details-container .value {
  color: #888;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.53px;
  line-height: 26.4px;
  text-align: left;
}
.add-locations-container .label {
  color: #9B9B9B;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin: 20px 0px 5px;
  position: relative;
}
.add-locations-container .label .mandatory-container {
  background-color: #ED5356;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -35px;
}
@media (max-width: 768px) {
  .add-locations-container .label .mandatory-container {
    left: -30px;
  }
}