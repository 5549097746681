@import '../../../variables.scss';

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.outcome-modal-container {
  outline: none;
  width: 720px;
  margin: 100px auto;
  height: calc(100% - 200px);
  
  @media (max-width: 768px) {
    width: 100%;
  }
  .outcome-modal-inner {
    background-color: #FFFFFF;
    margin: 0px auto;
    position: relative;
    margin-left: 240px!important;
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-left: 20px!important;
      // height: 460px;
    }

    .responsive-header {
      min-height: 50px;
      display: flex;
      width: 100%;
      border-bottom: 1px solid $headerBorderBottom;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 50px;
      .login-header-text {
        font-size: 25px;
        color: $headerTextColor;
      }
      .burger {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .form {
      padding: 10px 30px;
      height: calc(100vh - 335px);
      .button-container {
        display: flex;
        justify-content: right;
        gap: 24px;
        margin: 0px auto;
      }
      textarea {
        height: 150px;
        resize: none;
        outline: none;
        width: 100%;
        outline: none;
        box-sizing: border-box;
        border: 1px solid #9B9B9B;
        font-size: 16px;
        margin: 10px 0px;
        padding: 10px;
        &:focus {
          outline: 1px solid #388bee;
          border: 1px solid #388bee;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 12px;
        }
      }
      select {
        height: 30px;
        margin: 10px 0px;
        width: 100%;
        font-size: 15px;
      }
    }
  }
}

.applicant-cell-container {
  box-sizing: border-box;
  background-color: #FDFDFD;
  width: 100%;
  // height: 120px;
  display: flex;
  // border-left: 7px solid #007AFF;
  @media (max-width: 768px) {
    // height: 150px !important;
    height: auto;
    display: block;
    // width: 350px;
  }
  .left-block {
    width: 120px;
    display: flex;
    border-top: 1px solid #CFCFCF;
    @media (max-width: 768px) {
      width: 350px;
    }
    .profile-pic {
      width: 120px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #CFCFCF;
      cursor: pointer;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .right-block {
    width: 630px;
    border-top: 1px solid #CFCFCF;
    // display: flex;
    @media (max-width: 768px) {
      width: 350px;
      border-top: none;
    }
    .status-message {
      height: 30px;
      display: flex;
      justify-content: center;
      .message {
        width: 95%;
        background-color: #007AFF;
        color: #FFFFFF;
        height: 20px;
        font-size: 13px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin: 0px auto;
      }
    }
    .action-details-container {
      width: 630px;
      // border-top: 1px solid #CFCFCF;
      display: flex;
      @media (max-width: 768px) {
        width: 350px;
        border-top: none;
      }
      .seeker-details {
        width: 360px !important;
        padding-left: 15px;
        .mobile-number-message {
          font-size: 12px;
          text-align: left;
        }
        .date-container {
          // width: 200px;
          .date {
            color: #4A4A4A;
            font-size: 11px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 400;
            letter-spacing: 0.29px;
            line-height: 11px;
            @media (max-width: 768px) {
              text-align: left;
              height: 25px;
              font-size: 13px;
              margin: 5px 0px;
            }
          }
        }
        .name-container {
          // display: flex;
          align-items: center;
          @media (max-width: 768px) {
            display: block;
          }
          .phone-number {
            text-align: left;
          }
          .name-inner {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
            }
            .full-name {
              height: 25px;
              font-size: 20px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              text-align: left;
            }
            .age-gender-container {
              // font-size: 13px;
              padding: 0px 10px;
              .icon {
                color: #007AFF;
              }
            }
          }
        }
      }
      .right-block-inner {
        .phone-number {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 25px;
        }
      }
      .action-button-container {
        width: 270px;
        text-align: right;
        box-sizing: border-box;
        @media (max-width: 768px) {
          width: 350px;
        }
        .single-action-button {
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 768px) {
            &:empty {
              display: none;
            }
          }
          .special-buttons {
            max-width: 200px !important;
          }
        }
        .action-text {
          color: #000000;
          font-size: 13px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.39px;
          height: 40px;
          text-align: center;
          @media (max-width: 768px) {
            text-align: left;
          }
        }
        .action-button-none {
          width: 100px;
          height: 40px;
        }
      }
    }
  }
}
