.rbc-time-header {
  margin-right: 0!important;
}
.add-interview-times-container {
  .BaseTable__row {
    // height: 60px !important;
    // border-bottom: 1px solid #eeeeee;
    .BaseTable__row-cell-text {
      height: 100%;
    }
  }
  display: block;
  margin-left: 220px;
  width: calc(100% - 220px);

  margin-top: 180px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    position: fixed;
    height: -webkit-fill-available;
    overflow: auto;
    margin-top: 75px;
  }
  .add-interview-times-inner {
    // display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    @media (max-width: 375px) {
      width: 350px;
    }
    .sub-details {
      margin: 0px auto 20px;
      color: #007AFF;
      font-size: 18px;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
    .details-container {
      display: flex;
      margin-bottom: 0px;
      @media (max-width: 768px) {
        width: 350px;
        justify-content: space-between;
      }
      .today-date {
        width: 100px;
        .today-text {
          background-color: gray;
          color: #FFFFFF;
          border-radius: 4px;
        }
        .month-text {

        }
        .day-text {

        }
        .date-text {
          font-size: 30px;
        }
      }
      .current-job-name {
        .text {
          color: #FFFFFF;
          background-color: gray;
          padding: 10px 20px;
          border-radius: 5px;
        }
        width: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 230px;
          font-size: 13px;
        }
      }
    }
    .mobile-add-section {
      display: none;
      @media (max-width: 768px) {
        width: 350px !important;
        display: flex !important;
        .rbc-calendar {
          width: 350px !important;
        }
        .rbc-event {
          padding: 0px !important;
          z-index: 10 !important;
          &:hover {
            cursor: pointer;
            z-index: 9999 !important;
            &:before {
            }
          }
        }
        .rbc-timeslot-group {
          height: 20px !important;
          max-height: 20px !important;
          .rbc-time-slot {
            border-top: 0px;
          }
        }
        .rbc-timeslot-group-hover {
          z-index: 9;
          &:hover {
            cursor: pointer;
            background: orange;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              content: "面接枠を入れる";
            }
          }
        }
        .rbc-event-label {
          display: none;
        }
      }
    }
    .add-section {
      width: 1100px;
      // height: 500px;
      height: 73vh;
      @media (max-width: 768px) {
        display: none;
      }
      .rbc-event {
        z-index: 10 !important;
        &:hover {
          cursor: pointer;
          z-index: 9999 !important;
          &:before {
          }
        }
      }
      .rbc-timeslot-group {
        height: 20px !important;
        .rbc-time-slot {
          border-top: 0px;
        }
      }
      .rbc-timeslot-group-hover {
        z-index: 9;
        &:hover {
          cursor: pointer;
          background: orange;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            content: "面接枠を入れる";
          }
        }
      }
      .rbc-event-label {
        display: none;
      }

    }
  }
}
