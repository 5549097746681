@import './variables.scss';

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background:darken($bg,8%);
    transition: all 0.3s ease;
  }
  &:active {
    background:darken($bg,25%);
  }
}

.btn {
   color: $primaryTextColor;
   border: 1px solid $primary;
   border-radius: 4px;
   width: auto !important;
   min-width: 120px;
   height: 40px;
   outline: none;
   font-size: 15px;
   box-sizing: border-box;
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
   align-items: center;
   -webkit-justify-content: center;
   justify-content: center;
   color: $primaryTextColor;
   background-color: $primary;
   cursor: pointer;
   text-decoration:none;
   padding: 0px 10px;
   @media (max-width: 768px) {
     min-width: 100px;
     font-size: 12px;
     max-width: 120px;
   }
}

.btn-primary {
   @include button-bg($primary);
}

.btn-secondary{
   @include button-bg($secondary);
   color: $secondaryTextColor !important;
}

.btn-danger {
   @include button-bg($danger);
   height: 30px;
   padding: 5px 10px;
   border-color: $danger;
}

.btn-dimension {
  width: 180px !important;
}

.btn-action {
  @include button-bg($primary);
  height: 30px;
  padding: 5px 10px;
}

.btn-disabled{
  &:hover {
    // background:darken($disabled,8%)!important;
    // transition: all 0.3s ease!important;
  }
  &:active {
    background:darken($disabled,25%)!important;
  }
  color: $disabled !important;
  border-color: $disabled!important;
}