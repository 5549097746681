@import '../../variables.scss';

.confirm-cancel-interview-modal {
  outline: none;
  width: 500px;
  margin: 100px auto;
  background-color: $modalBackground;
  @media (max-width: 1024px) {
    width: 450px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  .responsive-header {
    min-height: 50px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid $headerBorderBottom;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 50px;
    .login-header-text {
      font-size: 25px;
      color: $headerTextColor;
    }
    .burger {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .dialog-message-container {
    display: block;
    width: 90%;
    padding: 0px 20px 20px;
    margin: 0px auto;
    .cancel-reason-container {
      margin-bottom: 30px;
    }
    .info-text2 {
      width: 100%;
      margin: 20px auto;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .warning-text {
      width: 100%;
      margin: 20px auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      color: #ED5356;
    }
    .confirmation-text {
      width: 100%;
      margin: 20px auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info-text {
      width: 100%;
      margin: 20px auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3B86FF;
      font-size: 14px;
    }
    .button-container {
      display: flex;
      justify-content: right;
      gap: 24px;
      margin: 0px auto;
    }
  }
}
