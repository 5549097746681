.shift-container {
  // padding: 30px;
  width: 375px;
  margin: 0px auto;
  @media (max-width: 768px) {
    width: 340px;
    max-width: 100%;
  }
  .days-error-message {
    margin-top: 20px;
    color: #ED5356;
    text-align: left;
    font-size: 12px;
  }
  .time-container {
    margin: 0px;
    display: flex;
    justify-content: space-between;
    .time-inner {
      width: 320px;
      display: flex;
      @media (max-width: 768px) {
        width: 190px;
      }
    }
    .button {
      display: flex;
      .button-inner {
        align-self: flex-end !important;
      }
    }
  }
  .days-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px 0px;
    .day {
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
      width: 40px;
      cursor: pointer;
      border: 1px solid #000000;
      color: #4A4A4A;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}
