.notifications-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 150px;
  padding-bottom: 50px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }
  .notification-list {
    // margin: 150px auto;
    .single-notification {
      width: 765px;
      background-color: #FFFFFF;
      margin: 20px auto;
      border: 1px solid rgba(17, 22, 26, 0.16);
      box-shadow: 0 4px 3px 0 rgba(17, 22, 26, 0.16);
      padding: 10px 20px;
      text-align: left;
      border-bottom: 1px solid #dddfe2;
      @media (max-width: 1024px) {
        width: 500px;
      }
      @media (max-width: 768px) {
        width: 350px;
      }
      .notification-type {
        font-size: 16px;
        margin: 10px 0px;
        @media (max-width: 1024px) {
          font-size: 13px;
        }
      }
      .notification-message {
        font-size: 13px;
        @media (max-width: 1024px) {
          font-size: 11px;
        }
      }
    }
    .not-read {
      background-color: #edf2fa;
    }
  }
}
