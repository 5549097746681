.member-card-container {
  border-radius: 8px;
  border: 1px solid #B4B4B4;
  background: #EDEDED;
  width: 803px;
  padding: 16px;
  margin: 24px auto;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
    padding: 9px 16px;
    margin: 12px;
  }

  .section-link {
    background-color: #F4F8F9;
    color: #007AFF;
    border: 1px solid #007AFF;
    margin: 0 20px;
    width: 150px;
    //height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    float: right;
    @media (max-width: 768px) {
      margin: 5px;
    }
  }

  .name-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #000;
      text-align: center;
      font-family: "Noto Sans JP";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
      letter-spacing: -0.264px;
      // width: 354px;
      text-align: left;
      @media (max-width: 768px) {
        font-size: 18px;
        letter-spacing: -0.198px;
      }
    }
    .name-invited {
      color: #3AA9EE;
    }
    .delete {
      border: 1px solid #D0021B;
      width: 72px;
      height: 26px;
      color: #D0021B;
      font-family: Arial;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: 0.29px;
      line-height: 11px;
      text-align: center;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .email-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8px;
    row-gap: 16px;
    .email {
      width: calc(100% - 595px);
      min-width: 150px;
      @media (max-width: 768px) {
        width: -webkit-fill-available;
      }
      .value {
        color: #4D4D4D;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.176px;
        text-align: left;
        text-wrap: wrap;
        line-break: anywhere;
        @media (max-width: 768px) {
          font-size: 14px;
          letter-spacing: -0.154px;
        }
      }
    }
    .role {
      justify-content: flex-start;
      text-align: left;
      width: 355px;
      @media (max-width: 400px) {
        width: -webkit-fill-available;
      }
      .label {
        color: #3D3D3D;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.176px;
        text-align: left;
        @media (max-width: 768px) {
          font-size: 14px;
          letter-spacing: -0.154px;
        }
      }
      .select-role {
        border: 1px solid #B4B4B4;
        border-radius: 8px;
        height: 36px;
        width: 355px;
        background-color: #FFF;
        @media (max-width: 768px) {
          width: 312px;
          height: 32px;
        }
        @media (max-width: 400px) {
          width: -webkit-fill-available;
        }
      }
      .select-text {
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
        text-align: left;
        color: #000;
        text-overflow: unset;
        @media (max-width: 768px) {
          font-size: 12px;
          letter-spacing: -0.132px;
        }
      }
      .select-text-disabled {
        color: #9A9A9A;
      }
      .explanatory-text {
        color: #000;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.154px;
        text-align: left;
        margin-top: 8px;
        @media (max-width: 768px) {
          font-size: 12px;
          letter-spacing: -0.132px;
        }
      }
    }
    .button-container {
      width: 189px;
      margin-left: auto;
      margin-top: auto;

      .button {
        width: 100%;
        padding: 10px 0px;
        border-radius: 8px;
        cursor: pointer;
        
        background: #FFF;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        letter-spacing: -0.198px;
        @media (max-width: 768px) {
          padding: 8px 0px;
          font-size: 16px;
          letter-spacing: -0.176px;
        }
      }

      .button-red {
        border: 2px solid #EF4D4D;
        color: #EF4D4D;
        &:hover {
          background-color: #EF4D4D20;
        }
      }

      .button-blue {
        border: 2px solid #3AA9EE;
        color: #3AA9EE;
        &:hover {
          background-color: #3AA9EE20;
        }
      }
    }
  }
}

.select-closed-style {
  transform: scaleY(-1);
}