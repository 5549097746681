.member-card-container {
  border-radius: 8px;
  border: 1px solid #B4B4B4;
  background: #EDEDED;
  width: 803px;
  padding: 16px;
  margin: 24px auto;
}
@media (max-width: 768px) {
  .member-card-container {
    width: auto;
    height: auto;
    padding: 9px 16px;
    margin: 12px;
  }
}
.member-card-container .section-link {
  background-color: #F4F8F9;
  color: #007AFF;
  border: 1px solid #007AFF;
  margin: 0 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  float: right;
}
@media (max-width: 768px) {
  .member-card-container .section-link {
    margin: 5px;
  }
}
.member-card-container .name-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.member-card-container .name-container .name {
  color: #000;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.264px;
  text-align: left;
}
@media (max-width: 768px) {
  .member-card-container .name-container .name {
    font-size: 18px;
    letter-spacing: -0.198px;
  }
}
.member-card-container .name-container .name-invited {
  color: #3AA9EE;
}
.member-card-container .name-container .delete {
  border: 1px solid #D0021B;
  width: 72px;
  height: 26px;
  color: #D0021B;
  font-family: Arial;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.29px;
  line-height: 11px;
  text-align: center;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.member-card-container .email-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  row-gap: 16px;
}
.member-card-container .email-container .email {
  width: calc(100% - 595px);
  min-width: 150px;
}
@media (max-width: 768px) {
  .member-card-container .email-container .email {
    width: -webkit-fill-available;
  }
}
.member-card-container .email-container .email .value {
  color: #4D4D4D;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-align: left;
  text-wrap: wrap;
  line-break: anywhere;
}
@media (max-width: 768px) {
  .member-card-container .email-container .email .value {
    font-size: 14px;
    letter-spacing: -0.154px;
  }
}
.member-card-container .email-container .role {
  justify-content: flex-start;
  text-align: left;
  width: 355px;
}
@media (max-width: 400px) {
  .member-card-container .email-container .role {
    width: -webkit-fill-available;
  }
}
.member-card-container .email-container .role .label {
  color: #3D3D3D;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-align: left;
}
@media (max-width: 768px) {
  .member-card-container .email-container .role .label {
    font-size: 14px;
    letter-spacing: -0.154px;
  }
}
.member-card-container .email-container .role .select-role {
  border: 1px solid #B4B4B4;
  border-radius: 8px;
  height: 36px;
  width: 355px;
  background-color: #FFF;
}
@media (max-width: 768px) {
  .member-card-container .email-container .role .select-role {
    width: 312px;
    height: 32px;
  }
}
@media (max-width: 400px) {
  .member-card-container .email-container .role .select-role {
    width: -webkit-fill-available;
  }
}
.member-card-container .email-container .role .select-text {
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.154px;
  text-align: left;
  color: #000;
  text-overflow: unset;
}
@media (max-width: 768px) {
  .member-card-container .email-container .role .select-text {
    font-size: 12px;
    letter-spacing: -0.132px;
  }
}
.member-card-container .email-container .role .select-text-disabled {
  color: #9A9A9A;
}
.member-card-container .email-container .role .explanatory-text {
  color: #000;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.154px;
  text-align: left;
  margin-top: 8px;
}
@media (max-width: 768px) {
  .member-card-container .email-container .role .explanatory-text {
    font-size: 12px;
    letter-spacing: -0.132px;
  }
}
.member-card-container .email-container .button-container {
  width: 189px;
  margin-left: auto;
  margin-top: auto;
}
.member-card-container .email-container .button-container .button {
  width: 100%;
  padding: 10px 0px;
  border-radius: 8px;
  cursor: pointer;
  background: #FFF;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.198px;
}
@media (max-width: 768px) {
  .member-card-container .email-container .button-container .button {
    padding: 8px 0px;
    font-size: 16px;
    letter-spacing: -0.176px;
  }
}
.member-card-container .email-container .button-container .button-red {
  border: 2px solid #EF4D4D;
  color: #EF4D4D;
}
.member-card-container .email-container .button-container .button-red:hover {
  background-color: rgba(239, 77, 77, 0.1254901961);
}
.member-card-container .email-container .button-container .button-blue {
  border: 2px solid #3AA9EE;
  color: #3AA9EE;
}
.member-card-container .email-container .button-container .button-blue:hover {
  background-color: rgba(58, 169, 238, 0.1254901961);
}

.select-closed-style {
  transform: scaleY(-1);
}