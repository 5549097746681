.sales-merit-container {
  width: 100%;
  display: flex;
  height: 40px;
  border-bottom: 1px solid #DDD;
  .text {
    color: #000000;
    font-size: 11px;

    font-weight: normal;
    padding: 13px 10px;
    width: 240px;
    text-align: left;
    @media (max-width: 768px) {
      width: 270px;
    }
  }
}
