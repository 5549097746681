.error {
  border: 1px solid #e06e6f !important;
}

.error-message-leave-space {
  min-height: 15px;
}

.react-autosuggest__container {
  .react-autosuggest__input {
    width: 100%;
    outline: none;
    border: 1px solid #9B9B9B;
    border-radius: 4px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    padding: 10px;
    &:focus {
      outline: 1px solid #388bee;
      border: 1px solid #388bee;
    }
  }
  .react-autosuggest__suggestions-container--open {
    border: 1px solid #9B9B9B;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    .react-autosuggest__suggestions-list {
      list-style-type: none;
      padding: 10px;
    }
  }
}
.single-input-multiline {
  width: 48%;
}

.special-mobile-container {
  display: flex;
  .single-input-special-left {
    width: 25px;
    padding-left: 8px;
    background-color: #DCDCDC;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    outline: none;
    border: 1px solid #9B9B9B;
  }
  .single-input-special-right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    outline: none;
    border: 1px solid #9B9B9B;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    font-size: 14px;
    padding: 10px;
  }
}

.salary-input-container {
  display: flex;
  // width: 48%;
  input {
    width: 88% !important;
    @media (max-width: 1024px) {
      width: 80% !important;
    }
  }
  span {
    line-height: 35px;
  }
}

.single-input-container {
  .single-input {
    outline: none;
    display: block;
    box-sizing: border-box;
    border: 1px solid #9B9B9B;
    border-radius: 4px;
    height: 40px;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    &:focus {
      outline: 1px solid #388bee;
      border: 1px solid #388bee;
    }
  }
  .error-message-container {
    color: #e06e6f;
    text-align: left;
    margin: 5px 0px;
    font-size: 10px;
  }
  .explanatory-text {
    color: #9B9B9B;
    text-align: left;
    margin: 5px 0px 0px;
    font-size: 10px;
  }
  .special-explanatory-text {
    color: #007AFF;
    text-align: left;
    margin: 5px 0px 0px;
    font-size: 10px;
  }
  .label-container {
    color: #9B9B9B;
    font-size: 11px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    margin: 20px 0px 5px;
    position: relative;
    @media (max-width: 768px) {
      font-size: 11px;
    }
    .mandatory-container {
      background-color: #ED5356;
      color: #FFFFFF;
      font-size: 9px;
      padding: 0px 2px;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -35px;
      @media (max-width: 768px) {
        left: -30px;
      }
    }
    .optional-container {
      background-color: #007AFF;
      color: #FFFFFF;
      font-size: 9px;
      padding: 0px 2px;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -35px;
      @media (max-width: 768px) {
        left: -30px;
      }
    }
    .tooltip-text {
      width: 12px;
      margin: 0px 5px;
      cursor: pointer;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .action-button {
      color: #007AFF;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
}
