.company-details-part4-container .form-helper-text {
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .company-details-part4-container .form-helper-text {
    width: 300px;
    margin: 20px auto;
    font-size: 12px;
  }
}
.company-details-part4-container .company-details-part4-form {
  width: 375px;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .company-details-part4-container .company-details-part4-form {
    width: 300px;
  }
}
.company-details-part4-container .company-details-part4-form .single-form-element {
  margin: 15px auto 5px;
}
.company-details-part4-container .company-details-part4-form .is-admin-ceo-same-container .admin-ceo-same-text {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: left;
  margin: 10px 0px;
}
.company-details-part4-container .company-details-part4-form .is-admin-ceo-same-container .admin-ceo-same-values .admin-ceo-same-single {
  display: flex;
}
.company-details-part4-container .company-details-part4-form .is-admin-ceo-same-container .admin-ceo-same-values .admin-ceo-same-single input {
  color: #007AFF;
}
.company-details-part4-container .company-details-part4-form .is-admin-ceo-same-container .admin-ceo-same-values .admin-ceo-same-single .text {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 10px;
}