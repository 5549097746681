@import '../../variables.scss';

.backdrop-layer {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  
  .login-modal-container {
  outline: none;
  width: 500px;
  margin: 100px auto;
  @media (max-width: 1024px) {
    width: 500px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .login-modal-inner {
    // height: 300px;
    background-color: $modalBackground;
    border-radius: 8px 8px 0px 0px;
    
    // padding: 30px;
    .responsive-header {
      display: flex;
      width: 100%;
      border-bottom: 1px solid $headerBorderBottom;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 8px 8px 0px 0px;
      background: #7B70F7;
      .login-header-text {
        color: #FFF;
        text-align: center;
        line-height: 56px;
        font-size: 24px;
        font-family: Noto Sans JP;
        font-weight: 500;
        letter-spacing: 0.72px;
        @media (min-width: 768px) {
          font-weight: 400;
        }
      }
      .burger {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .login-inner-modal {
      display: block;
      width: 350px;
      padding: 10px;
      margin: 0px auto;
      .alert-danger {
        height: 40px;
        color: #ed5356;
        width: 100%;
        font-size: 12px;
        text-align: center;
        background-color: transparent;
        max-width: 500px;
        padding: 10px 0px;
        margin: 10px auto !important;
        color: #ed5356 !important;
        background-color: transparent !important;
      }
      .submit-button-container {
        margin: 20px 0px;
        .btn {
          border-radius: 16px;
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-family: Noto Sans JP;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.32px;
          @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
          }
        }
        .forgot-password-text {
          font-size: 13px;
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
}
}


.header-container {
  position: sticky;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: $headerBackground !important;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  @media (max-width: 1024px) {
    // display: flex !important;
    justify-content: space-between;
  }
  .header {
    position: relative;
    height: 100%;
    width: 100%;
    .header-title {
      color: #333;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans JP;
      font-weight: 700;
      line-height: 24px;
      margin-top: 16px;
    }
    .inner-container {
      padding: 16px;
      padding-top: 0px;
      width: 100% !important;
      border-bottom: 1px solid rgb(228, 228, 228) !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100% !important;
      border-spacing: 0px !important;
      @media (max-width: 1024px) {
        // justify-content: flex-start;
        // padding-left: 10px;
      }
      .link {
        .main-image {
          cursor: pointer;
          margin-top: 5px;
          width: 255px;
        }
      }
      .login-button {
        position: absolute;
        right: 20px;
      }
    }
  }
}
