
.register-page-validation-tooltip-container {
  height: 150px;
  .validation-header {
    height: 30px;
    border-bottom: 1px solid #D0021B;
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .validation-inner-container {
    margin: 0px auto;
    padding: 5px;
    width: 120px;
    .single-validation {
      font-size: 14px;
    }
  }
}

.layer-popover {
  @media (max-width: 768px) {
    display: none;
  }
}

.login-modal-container {
  outline: none;
  width: 500px;
  margin: 100px auto;
  @media (max-width: 1024px) {
    width: 450px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .login-modal-inner {
    // height: 300px;
    background-color: #FFFFFF;
    // padding: 30px;
    .responsive-header {
      height: 50px;
      display: flex;
      width: 100%;
      border-bottom: 1px solid #E4E4E4;
      justify-content: center;
      align-items: center;
      position: relative;
      .login-header-text {
        font-size: 25px;
        color: #484848;
      }
      .burger {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .login-inner-modal {
      display: block;
      width: 350px;
      padding: 10px;
      margin: 0px auto;
      .alert-danger {
        height: 40px;
        color: #ed5356;
        width: 100%;
        font-size: 12px;
        text-align: center;
        background-color: transparent;
        max-width: 500px;
        padding: 10px 0px;
        margin: 10px auto !important;
        color: #ed5356 !important;
        background-color: transparent !important;
      }
      .submit-button-container {
        margin: 20px 0px;
        .forgot-password-text {
          font-size: 13px;
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
}

.register-page-container {
  width: 100%;
  height: 100%;
  padding: 50px 0px;
  .login-text-container {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 500px;
    background-color: #FFFFFF;
    padding: 10px;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
    border-radius: 5px;
    button {
      margin: 10px auto;
    }
    @media (max-width: 1024px) {
      width: 350px;
    }
    @media (max-width: 768px) {
      width: 350px;
      font-size: 12px;
      button {
        min-width: 200px !important;
        margin: 20px auto;
      }
    }
  }
  .register-form-container {
    margin: 0px auto;
    padding: 20px;
    width: 500px;
    min-height: 300px;
    background-color: #FFFFFF;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
    border-radius: 5px;
    @media (max-width: 1024px) {
      width: 350px;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
    .register-page-header-container {
      height: 150px;
      border-bottom: 1px solid #007AFF;
      .header {
        color: #2C2C2C;
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
        width: 100%;
        text-align: center;
        margin: 10px 0px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      .sub-header{
        color: #2C2C2C;
        font-size: 19px;
        font-weight: 400;
        line-height: 23px;
        opacity: 0.8018275669642857;
        width: 100%;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
    .form-fields {
      width: 80%;
      margin: 10px auto;
    }
    .terms-and-conditions {
      text-align: center;
      label {
        margin: 0px;
      }
    }
    .register-submit-button {
      background-color: #007AFF;
      border-radius: 27px;
      width: 250px;
      height: 47px;
      display: flex;
      justify-content: center;
      margin: 0px auto 10px;
      cursor: pointer;
      outline: none;
      align-items: center;
      color: #FFFFFF;
    }
  }
}
