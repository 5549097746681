.company-details-part1-container {
  .form-helper-text {
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    opacity: 0.80;
    text-align: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      width: 300px;
      margin: 20px auto;
      font-size: 12px;
    }
  }
  .company-details-part1-form {
    width: 375px;
    margin: 20px auto;
    @media (max-width: 768px) {
      width: 300px;
    }

    .single-form-element {
      margin: 15px auto 5px;
    }
  }
}
