.rc-slider {
  background: transparent !important;
  .rc-slider-track {
    background-color: #EEA168 !important;
  }
  .rc-slider-handle {
    border-color: #EEA168 !important;
  }
}

.section-container {
  margin: 25px 0px;
  text-align: left;
  .add-edit-button-description {
    font-size: 12px;
    margin: 5px 0px;
    text-align: left;
  }
  .section-header {
    color: #666666;
    font-size: 10px;
    font-weight: 400;
    // border-bottom: 1px solid black;
    // padding-bottom: 2px;
    line-height: 19px;
    text-align: left;
    margin-bottom: 5px;
  }
  .resubscribe-description-text {
    margin: 0px;
    margin-top: 5px;
  }
  .section-link {
    background-color: #007AFF;
    color: #FFFFFF;
    width: 250px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    // margin-top: 5px;
    line-height: 22px;
    text-align: left;
  }
}


.applicant-detail-v2-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);

  margin-top: 160px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    margin-top: 80px;
  }
  .before-trial-text {
    margin: 20px auto;
    width: 1000px;
    font-size: 14px;
    // text-align: left;
    @media (max-width: 1024px) {
      width: 500px;
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .billing-failed-container {
    width: 700px !important;
    margin: 20px auto;
    @media (max-width: 768px) {
      width: 350px !important;
    }
    .status-description-text {
      margin: 20px auto;
      text-align: left;
      width: 100%;
      @media (max-width: 1024px) {
        width: 500px;
        margin: 20px auto;
      }
      @media (max-width: 768px) {
        width: 350px;
      }
    }
  }

  .status-description-text {
    margin: 20px auto;
    width: 550px;
    text-align: left;
    @media (max-width: 1024px) {
      width: 500px;
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .resubscribe {
    // width: 150px;
    height: 40px;
    // line-height: 40px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 100px;
    width: 300px;
    color: #007AFF;
    background-color: #FFFFFF;
    border: 1px solid #007AFF;
    @media (max-width: 1024px) {
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .add-edit-button-description {
    font-size: 12px;
    margin: 5px 0px;
    text-align: left;
  }
  .billing-page-inner {
    margin: 100px auto;
    padding: 0 50px;
    width: 100%;
    font-family: 'Noto Sans JP'!important;
    // border: 1px solid black;
    @media (max-width: 1024px) {
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 20px auto !important;
      padding: 0;
    }
    .transactions-button-container {
      width: 500px;
      margin: 50px auto;
      @media (max-width: 768px) {
        width: 350px;
        // height: auto;
      }
      .section-link {
        background-color: #FFFFFF;
        color: #007AFF;
        border: 1px solid #007AFF;
        margin: 20px auto;
        width: 250px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        // margin-top: 5px;
        line-height: 22px;
        text-align: left;
      }
    }
    .single-box {
      width: 40%;
      border: 1px solid #F1F1F1;
      background-color: #F1F1F1;
      border-radius: 5px;
      color: #555555;
      margin: 0 20px 20px;
      float:left;
      @media (max-width: 768px) {
        margin: 0 0 20px;
        width: 100%;
        height: auto;
        padding: 0 20px;
      }
      .single-box-header {
        background-color: #F1F1F1;
        border-radius: 5px;
        color: #555555;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
      .contact-box-header {
        background-color: #F1F1F1;
        border-radius: 5px;
        color: #555;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.22px;
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
      .details-container {
        min-height: 120px;
        font-size: 18px;
        padding-top: 20px;
        border-radius: 5px;
        .description {
          font-size: 12px;
          margin: 10px auto;
        }
        .section-link {
          background-color: #FFFFFF;
          color: #007AFF;
          border: 1px solid #007AFF;
          margin: 0 20px 20px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .section-link-red {
          background-color: #FF6565;
          color: #FFF;
          margin: 0 20px 20px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .section-link-new {
          color: #007AFF;
          border: 1px solid #007AFF;
          margin: 0 20px 20px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .alert-text {
          color: #F00;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 12px;
        }
      }
      .contact-details-container {
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }

    .inner-single-box {
      width: 100%;
      border: 1px solid #F1F1F1;
      background-color: #F1F1F1;
      border-radius: 5px;
      color: #555555;
      margin: 0 0 20px;
      float:left;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding-left: 0;
      }
      .single-box-header {
        background-color: #F1F1F1;
        border-radius: 5px;
        color: #555555;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
      .details-container {
        min-height: 120px;
        font-size: 18px;
        padding-top: 20px;
        border-radius: 5px;
        .description {
          font-size: 12px;
          margin: 10px auto;
        }
        .section-link {
          background-color: #FFFFFF;
          border-radius: 5px;
          color: #007AFF;
          border: 1px solid #007AFF;
          margin: 20px auto;
          width: 250px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .section-link-yellow {
          background-color: #EEA168;
          color: #ffffff;
          border: 1px solid #EEA168;
          width: 100%;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .visa-description {
          color: #707070;
          font-size: 12px;
        }
      }
    }

    .empty-single-box {
      width: 40%;
      color: #555555;
      margin: 0 20px 20px;
      float:left;
      margin-left: 10%;
      @media (max-width: 768px) {
        margin: 0 0 20px;
        width: 100%;
        height: auto;
        padding: 0 20px;
      }
    }

    .usage-container {
      height: 200px;
      width: 300px;
      margin: 10px auto;
      .header {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .tooltip-text {
          width: 12px;
          margin: 0px 5px;
          cursor: pointer;
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
      .usage-inner {
        display: flex;
        width: 300px;
        justify-content: center; //when showing no application icon
        margin: 20px auto;
        .application {
          width: 145px;
          text-align: left;
          .application-cells {
            .blue-user-icon {
              font-size: 40px;
              margin: 0px 5px;
              color: #007AFF;
            }
            .gray-user-icon {
              font-size: 40px;
              margin: 0px 5px;
              color: #E6E6E6;

            }
            .blue-user-icon {

            }
          }
          .application-text {
            margin-top: 10px;
            font-size: 11px;
          }
        }
        .divider {
          background-color: #D8D8D8;
          border: 1px solid #979797;
          width: 1px;
          margin: 0px 10px;
          height: 100px;
        }
        .usage {
          .days {
            // width: 145px;
            width: 182px; // when showing only days and no application icon
            .days-cell-container {
              // max-width: 145px;
              max-width: 182px; // when showing only days and no application icon
              display: flex;
              flex-wrap: wrap;
              .gray-cell {
                margin: 2px;
                // min-width: 16px;
                // min-height: 16px;
                min-height: 22px;
                min-width: 22px;
                background-color: #E6E6E6;
              }
              .blue-cell {
                margin: 2px;
                // min-width: 16px;
                // min-height: 16px;
                min-height: 22px;
                min-width: 22px;
                background-color: #007AFF;
              }
            }
            .days-text {
              margin-top: 10px;
              font-size: 11px;
            }
          }
        }
      }
    }
    .current-pack-container {
      .pack-header-container {
        display: flex;
        justify-content: space-between;
        .header {
          color: #666666;
          font-size: 10px;
          // border-bottom: 1px solid black;
          // padding-bottom: 2px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
        }
        .change-pack-button {
          border: 1px solid #007AFF;
          width: 105px;
          height: 26px;
          background-color: #FFFFFF;
          color: #007AFF;
          outline: none;
          cursor: pointer;
          font-size: 9px;
          font-weight: 700;
          letter-spacing: 0.29px;
          line-height: 11px;
          text-align: center;
        }
      }
      .pack-name {
        color: #4A4A4A;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }
    .section-container {
      margin: 25px 0px;
      text-align: left;
      .add-edit-button-description {
        font-size: 12px;
        margin: 5px 0px;
        text-align: left;
      }
      .section-header {
        color: #666666;
        font-size: 10px;
        font-weight: 400;
        // border-bottom: 1px solid black;
        // padding-bottom: 2px;
        line-height: 19px;
        text-align: left;
        margin-bottom: 5px;
      }
      .resubscribe-description-text {
        margin: 0px;
        margin-top: 5px;
      }
      .section-link {
        background-color: #007AFF;
        color: #FFFFFF;
        width: 250px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        // margin-top: 5px;
        line-height: 22px;
        text-align: left;
      }
    }
  }


  .appl-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
    gap: 8px;
  }

  .appl-main-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-bottom: 6px;
    align-items: center;
    border-bottom: 1px solid rgba(85, 85, 85, 0.40);
    flex: auto;

    @media (max-width: 300px) {
      flex-direction: column;
    }
  }

  .appl-data-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: auto;
  }

  .appl-icon-container {
    display: flex;
    gap: 7px;
    padding-bottom: 8px;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(85, 85, 85, 0.40);
  }

  .appl-action-icon {
    cursor: default;
    * {
      padding: 2px;
      box-sizing: content-box;
    }
  }

  .appl-enabled {
    cursor: pointer;
    :hover {
      border-radius: 30px;
      background: rgba(85, 85, 85, 0.15);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
    }
  }

  .appl-img-container {
    display: flex;
    width: 30%;
    flex-direction: column;
    gap: 4px;
  }

  .appl-main-img {
    border-radius: 100%;
    border: 1px solid #D9D9D9;
    width: 100%;
    height: fit-content;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .appl-age-text {
    color: #1E1E1E;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.132px;
  }

  .appl-main-info-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    text-align: left;
  }

  .appl-main-status {
    padding: 3px 10px;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    text-wrap: nowrap;
    white-space: nowrap;
    max-width: 300px;
    width: fit-content;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }

  .appl-main-name {
    color: #000;
    font-size: 16.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .appl-main-date {
    color: #000;
    font-size: 16.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-wrap: wrap;
  }

  .appl-data-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
  }

  .appl-data-label {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .appl-data-value {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    * {
      margin-right: 2px;
      height: 10px;
      line-height: 0;
    }
  }

  .contact-detail-text {
    color: #0D99FF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.132px;
  }


  .screening-container {

    display: flex;
    gap: 21px;
    justify-content: flex-end;
    
    .option-container {

      border-radius: 5px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      .option-icon {
        height: 10px;
        width: 10px;
      }

      .option-text {
        color: #FFF;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.res-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(101, 101, 101, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .res-popup-box {
    position: relative;
    background-color: #FFF;
    border-radius: 8px;
    border: 1px solid #E9E9E9;
    padding: 36px 6px;
    max-width: 700px;
    max-height: calc(100% - 280px);

    .res-close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px; 
    }

    .res-image {
      margin-top: 14px;
      cursor: pointer!important;

      .image-style {
        max-height: calc(100vh - 350px)!important;
        max-width: 100%!important;
        object-fit: contain;
      }
      .image-large-style {
        object-fit: contain;
      }
    }
  }
}

.snackbar-details {
  .MuiSnackbarContent-root {
    font-family: 'Noto Sans JP'!important;
    color: #08105D;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #ECECEC;
    background: #FFF;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
    height: 55px;
    width: 492px;

    @media (max-width: 1024px) {
      height: 40px;
      width: 306px;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #ECECEC;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.aapd-body {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 80px;
  padding: 0 0 60px;
  background: #F8F8F8;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
    padding: 0 0 16px;
  }
  font-family: 'Noto Sans JP'!important;

  .main-inner-header-container {
    .inner-header-container {
      .header-text {
        color: #263242;
        font-size: 32px!important;
        font-weight: 700;

        @media (max-width: 1024px) {
          color: #FFF;
        }
      }
    }
    span {
      position: absolute;
    }
  }

  .aapd-header-section {
    position: sticky;
    background-color: white;
    top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 60px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 4px 0px rgba(63, 63, 63, 0.15);
    z-index: 10;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 12px 16px;
    }
  
    .image-name-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 1024px) {
        width: 100%;
      }
  
      .appl-main-img {
        border-radius: 100px;
        width: 100px;
        height: 100px;
        margin-right: 14px;
        object-fit: cover;

        @media (max-width: 1024px) {
          width: 60px;
          height: 60px;
          margin-right: 7px;
        }
      }
      .app-name {
        color: #3B3B3B;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }
    .icon-mobile {
      margin-left: auto;
      display: none;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      @media (max-width: 1024px) {
        display: flex;
      }
      .icon-img {
        width: 60px;
        height: 60px;
      }
      .icon-img-container {
        position: relative;
        .icon-text {
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #0F1A81;

          text-align: center;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }
        .tline {
          display: flex;
          flex-direction: row;
        }
        .t1 {
          font-weight: 500;
        }
        .t2 {
          font-weight: 600;
        }
        .t3 {
          font-weight: 700;
        }
      }
    }
    .icon-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      @media (max-width: 1024px) {
        display: none;
      }
      .icon-img {
        width: 60px;
        height: 60px;
      }
      .icon-img-container {
        position: relative;
        .icon-text {
          position: absolute;
          top: 0;
          left: 0;
          width: 60px;
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #0F1A81;

          text-align: center;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }
        .tline {
          display: flex;
          flex-direction: row;
        }
        .t1 {
          font-weight: 500;
        }
        .t2 {
          font-weight: 600;
        }
        .t3 {
          font-weight: 700;
        }
      }
    }
    .contact-box {

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .contact-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1024px) {
          justify-content:  flex-start;
        }
  
        .contact-icon {
          svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
            @media (max-width: 1024px) {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }
        }
        .contact-mobile {
          color: #5FBBFF;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 111.111% */
          letter-spacing: 0.1px;
          text-decoration-line: underline;
          margin-right: auto;
          @media (max-width: 1024px) {
            margin-right: 0;
            font-size: 14px;
          }
        }
        .contact-email {
          color: #5FBBFF;

          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 111.111% */
          letter-spacing: 0.1px;
          text-decoration-line: underline;
          margin-right: auto;
          @media (max-width: 1024px) {
            margin-right: 0;
            font-size: 14px;
          }
        }
        .contact-copy {
          svg {
            width: 28px;
            height: 32px;
            margin-left: 60px;
            cursor: pointer;
            @media (max-width: 1024px) {
              width: 17px;
              height: 18px;
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
  .main-box {
    
    padding: 0 60px;
    padding-top: 10px;
    background: #F8F8F8;

    @media (max-width: 1024px) {
      padding: 0 16px;
      padding-top: 10px;
    }

    .first-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 16px 16px 32px;
      background: #FFF;
      border: 2px solid #5FBBFF;
      border-radius: 16px;

      @media (max-width: 1024px) {
        flex-direction: column;
        padding: 8px;
      }

      .details {
        gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1024px) {
          gap: 8px;
        }

        .dot-infos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          @media (max-width: 1024px) {
            display: grid;
            grid-template-columns: auto 1fr;
            row-gap: 10px;
          }

          .info-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 16px;

            .dot {
              min-width: 10px;
              min-height: 10px;
              background-color: #FFBE00;
              border-radius: 10px;
              margin-right: 2px;
              @media (max-width: 1024px) {
                min-width: 6px;
                min-height: 6px;
              }
            }
            .info {
              color: #3B3B3B;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              @media (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }
        }

        .appl-btns-mobile {
          display: none;
          flex-direction: row;
          gap: 10px;
          padding-bottom: 0px;
          align-items: flex-start;

          @media (max-width: 1024px) {
            display: flex;
          }

          .appl-enabled {
            cursor: pointer;
          }
        }

        .detail-divider {
          width: calc(100% - 32px);
          height: 2px;
          background-color: #C1C1C1;
          border: 2px;
          margin: 0px 16px;
          display: none;
          @media (max-width: 1024px) {
            display: block;
            width: 100%;
            margin: 0;
          }
        }

        .status-line {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;

          .job-type-box {
            display: flex;
            min-width: 102px;
            height: 32px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 28px;
            color: #FFF;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.154px;
            line-height: 21px;
            @media (max-width: 1024px) {
              padding: 0px 8px;
              height: 24px;
              min-width: 66px;

              font-size: 10px;
              letter-spacing: -0.11;
            }
          }

          .job-status-box {
            display: flex;
            min-width: 298px;
            height: 32px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 28px;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.154px;
            line-height: 24px;
            @media (max-width: 1024px) {
              padding: 0px 8px;
              height: 24px;
              min-width: 206px;

              font-size: 10px;
              letter-spacing: -0.11;
            }
          }
          .job-date {
            color: #08105D;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }

        .main-line {
          display: flex;
          flex-direction: row;
          align-items:center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @media (max-width: 1024px) {
            font-size: 12px;
            align-items: flex-start;
          }

          .line-title {
            color: #08105D;
            margin-right: 12px;
            min-width: 70px;
            text-align: left;
            font-weight: 700;

            @media (max-width: 1024px) {
              min-width: 60px;
              margin-right: 2px;
            }
          }

          .line-title2 {
            color: #000;
            margin-left: 0px;
            margin-right: 12px;
            min-width: 70px;
            text-align: left;

            @media (max-width: 1024px) {
              margin-right: 0px;
              margin-left: 12px;
            }
          }

          .line-text {
            color: #000;
            text-align: left;
          }
        }

        .main-line-reverse {
          width: 100%;
          gap: 72px;
          flex-direction: row-reverse;
          justify-content: flex-end;
          @media (max-width: 1024px) {
            flex-direction: row-reverse;
            gap: 18px;
            justify-content: space-between;
          }
        }
      }
      .apply-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @media (max-width: 1024px) {
          padding-top: 8px;
        }

        .appl-btns {
          display: flex;
          flex-direction: row;
          gap: 24px;
          padding-bottom: 6px;
          align-items: flex-start;

          @media (max-width: 1024px) {
            display: none;
          }

          .appl-enabled {
            cursor: pointer;
          }
        }

        .approve-box {
          display: flex;
          flex-direction: row;
          gap: 10px;
          text-wrap:  nowrap;

          .accept-btn {
            display: inline-flex;
            cursor: pointer;
            padding: 10px 32px 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #33A8ED;

            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            height: 44px;

            @media (max-width: 1024px) {
              font-size: 14px;
              height: 36px;
              padding: 8px 24px 8px 16px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
  
          .reject-btn {
            display: inline-flex;
            cursor: pointer;
            padding: 10px 32px 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #FFF;

            color: #33A8ED;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            border: 2px solid #33A8ED;
            height: 44px;

            @media (max-width: 1024px) {
              font-size: 14px;
              height: 36px;
              padding: 8px 24px 8px 16px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
  
          .disabled-btn {
            display: inline-flex;
            background-color: rgba(85, 85, 85, 0.60);
            padding: 10px 32px 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;

            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            height: 44px;

            @media (max-width: 1024px) {
              font-size: 14px;
              height: 36px;
              padding: 8px 24px 8px 16px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }

  .detail-box {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    padding: 0 60px;
    padding-top: 14px;
    background: #F8F8F8;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 0 16px;
      padding-top: 14px;
    }

    .detail-column {
      display: flex;
      flex-direction: column;
      gap: 14px;
      flex: 1;

      .detail-container {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1024px) {
          box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.15), 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
        }

        .detail-title {
          color: #08105D;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            margin-right: 6px;
          }

          @media (max-width: 1024px) {
            font-size: 16px;

            svg {
              scale: 0.85;
              transform-origin: 0px;
            }
          }
        }

        .detail-title2 {
          color: #08105D;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 8px;

          svg {
            margin-right: 8px;
          }

          @media (max-width: 1024px) {
            font-size: 16px;
          }
        }

        .detail-align {
          display: flex;
          flex-direction: column;
          margin-left: 14px;
        }

        .detail-line-align {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          font-weight: 400;
          font-size: 16px;
          text-align: left;
          margin-top: 18px;

          @media (max-width: 1024px) {
            font-size: 12px;
            flex: 1;
            margin-top: 8px;
            margin-bottom: 8px;
            .detail-label {
              // min-width: unset;
              font-weight: 500;
            }
          }
        }
        
        .detail-line-align-center {
          @media (max-width: 1024px) {
            align-items: center;
          }
        }

        .detail-line {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          font-weight: 400;
          font-size: 16px;
          text-align: left;
          margin-top: 18px;
          margin-left: 14px;

          .wj-dot {
            min-height: 10px;
            min-width: 10px;
            margin-right: 6px;
            background-color: #FFBE00;
            border-radius: 10px;

            @media (max-width: 1024px) {
              min-height: 8px;
              min-width: 8px;
            }
          }
        }

        .detail-line2 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          font-weight: 400;
          font-size: 16px;
          text-align: left;
          margin-top: 18px;
        }

        .detail-label {
          color: #08105D;
          min-width: 140px;
          font-weight: 500;
          @media (max-width: 1024px) {
            font-size: 14px;
            min-width: 120px;
          }
        }

        .detail-label2 {
          color: #555;
          font-size: 20px;
          font-weight: 500;

          @media (max-width: 1024px) {
            font-size: 16px;
          }
        }

        .detail-label3 {
          color: #08105D;
          font-size: 16px;
          font-weight: 500;
          min-width: 140px;
          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }

        .detail-label4 {
          color: #08105D;
          font-size: 16px;
          font-weight: 500;
          min-width: 106px;
        }

        .detail-value {
          color: #232323;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }

        .detail-value2 {
          color: #232323;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }

        .detail-value3 {
          color: #232323;
          font-size: 10px;
        }

        .detail-value4 {
          color: #232323;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }

        .detail-value5 {
          color: #08105D;
          font-weight: 700;
          margin-right: 12px;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }

        .detail-value6 {
          color: #232323;
          font-weight: 400;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }

        .detail-value-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .detail-value-box2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
          @media (max-width: 1024px) {
            height: 21px;
          }
        }

        .detail-divider {
          width: calc(100% - 32px);
          height: 2px;
          border-style: dotted;
          border-color: #B4B4B4;
          border-width: 2px;
          border-bottom: none;      
          margin: 10px 16px;
          @media (max-width: 1024px) {
            width: 100%;
            margin: 10px 0;
          }
        }

        .image-desc {
          color: #555;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          width: 100%;
          padding-top: 8px;
          @media (max-width: 1024px) {
            font-size: 10px;
          }
        }

        .image-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 18px;
          padding: 0 20px;

          .image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
            flex: 1;

            .res-image {
              width: 100%;
              aspect-ratio: 1.89;
              object-fit: cover;
            }

            .res-btn {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 4px;
              cursor: pointer;

              color: #007AFF;
              font-size: 12px;
              font-weight: 400;
              text-decoration-line: underline;
            }
          }
        }

        .tag-box2 {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }
        .tag-box {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          justify-content: flex-start;
          margin-top: 12px;
          flex-wrap: wrap;

          .tag {
            padding: 10px 24px;
            border-radius: 32px;
            background: #F0F0F0;
            color: #333;
            font-size: 14px;
            font-weight: 500;
            @media (max-width: 1024px) {
              padding: 8px 16px;
              font-size: 14px;
            }
          }
        }

        .work-exp-container {
          display: flex;
          flex-direction: column-reverse;
          gap: 16px;
          margin-top: 6px;
          margin-left: 14px;
        }

        .work-exp {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          .work-exp-dot {
            min-height: 10px;
            min-width: 10px;
            background-color: #FFBE00;
            border-radius: 10px;
            margin-right: 6px;
            margin-top: 6px;

            @media (max-width: 1024px) {
              min-height: 8px;
              min-width: 8px;
            }
          }

          .work-exp-detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 23px;

            @media (max-width: 1024px) {
              line-height: 20px;
            }

            .work-line {
              color: #08105D;
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 4px;
              @media (max-width: 1024px) {
                font-size: 12px;
              }
            }
            .work-line-sub {
              color: #232323;
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              @media (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }
        }

        .shadow-box {
          border-radius: 6px;
          border: 1px solid #D6D6D6;
          background: #FFF;
          box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.10);
          width: 100%;
          padding: 12px 10px;
        }

        .range-box {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 18px;
          margin-left: 32px;
          margin-right: 32px;
          margin-top: 9px;
          width: -webkit-fill-available;
          @media (max-width: 1024px) {
            margin-left: 16px;
            width: calc(100% - 16px);
            margin-bottom: 12px;
            margin-top: 8px;
          }

          .range-val {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            gap: 10px;
            z-index: 2;

            @media (max-width: 1024px) {
              font-size: 10px;
            }

            .range-val-text {
              color: #858585;
            }

            .range-val-text-on {
              color: #007AFF;
            }

            .range-icon-before {
              width: 16px;
              height: 16px;
              border-radius: 16px;
              border-color: #74CBFF;
              border-width: 3px;
              border-style: solid;
              background-color: #FFF;
            }

            .range-icon {
              width: 16px;
              height: 16px;
              border-radius: 16px;
              border-color: #007AFF;
              border-width: 3px;
              border-style: solid;
              background-color: #FFF;
            }

            .range-icon-after {
              width: 16px;
              height: 16px;
              border-radius: 16px;
              border-color: #B9B9B9;
              border-width: 3px;
              border-style: solid;
              background-color: #FFF;
            }
          }

          .range-line {
            position: absolute;
            top: calc(100% - 10px);
            left: 32px;
            height: 4px;
            background: #B9B9B9;
            width: calc(100% - 38px);
            display: flex;
            flex-direction: row;

            @media (max-width: 1024px) {
              left: 24px;
            }

            .range-line-before {
              height: 100%;
              background: #74CBFF;
            }

            .range-line-after {
              height: 100%;
              background: #007AFF;
            }
          }
        }

        .wj-exp-show {

          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 10px auto;
          cursor: pointer;

          .wj-exp-show-icon {
            margin-right: 10px;
            svg {
              width: 24px;
              height: 24px;
            }
          }

          color: #33A8ED;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        .wj-exp {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: #232323;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          gap: 16px;
          margin-bottom: 8px;
          margin-top: 8px;
          margin-left: 14px;

          .wj-date {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #08105D;

            .wj-dot {
              min-height: 10px;
              min-width: 10px;
              margin-right: 6px;
              background-color: #FFBE00;
              border-radius: 10px;

              @media (max-width: 1024px) {
                min-height: 8px;
                min-width: 8px;
              }
            }
          }

          .wj-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .wj-title {
              font-weight: 400;
            }

            .wj-subtext {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

body {
  .MuiTooltip-tooltip:has(.styled-tooltip) {
    background-color: #DEDEDE;
    border-radius: 8px;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.20), 2px 4px 4px 0px rgba(0, 0, 0, 0.20);
    padding: 8px;

    font-family: 'Noto Sans JP'!important;
    color: rgba(0, 0, 0, 0.80);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.154px;
    max-width: 280px;
    .styled-tooltip {
      text-align: center;
    }
  }
}

.sidebar-container {
  z-index: 99;
}