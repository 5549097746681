.carousel {
  height: 250px;
  margin: 0 0 50px 0;
  max-height: 250px;
  max-width: 350px;
  position: relative;
}

.image-slide {
  height: 100%;
  width: 100%;
  transition: background-image 0.3s ease-in-out;
}

.carousel-close-icon {
  pointer-events: all !important;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
  width: 30px !important;
  height: 30px !important;
}

.slide-arrow {
  color: #000000;
  cursor: pointer;
  font-size: 2rem;
  position: fixed;
}

.slide-arrow.right {
  position: absolute;
  top: 100px;
  right: -40px;
}

.slide-arrow.left {
  position: absolute;
  top: 100px;
  left: -40px;
}