@charset "UTF-8";
.tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .tooltip-text {
    display: none;
  }
}

.add-jobs-inner-container .multiselect-selected-view {
  text-align: left;
  min-height: 30px;
  font-size: 13px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.add-jobs-inner-container .fileContainer {
  border: 1px solid #9B9B9B;
}
.add-jobs-inner-container .fileContainer .deleteImage {
  background-image: url("../../../images/svg/close-icon-with-circle.svg");
  background-color: #FFFFFF;
  background-repeat: round;
  font-size: 0px;
  width: 20px;
  height: 20px;
}
.add-jobs-inner-container .fileContainer .uploadPicturesWrapper div {
  justify-content: flex-start !important;
}
.add-jobs-inner-container .fileContainer .uploadPicturesWrapper .uploadPictureContainer {
  width: 20%;
  margin: 4%;
}
.add-jobs-inner-container .special-explanatory-text {
  text-align: left;
  margin: 5px 0 0;
  font-size: 10px;
}
.add-jobs-inner-container .input-box-container .label-container {
  color: #9B9B9B;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin: 20px 0px 5px;
  position: relative;
}
.add-jobs-inner-container .input-box-container .label-container .mandatory-container {
  background-color: #ED5356;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -35px;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .input-box-container .label-container .mandatory-container {
    left: -30px;
  }
}
.add-jobs-inner-container .input-box-container .error-message-container {
  color: #e06e6f;
  text-align: left;
  margin: 5px 0px;
  font-size: 10px;
}
.add-jobs-inner-container .input-box-container .drop-list {
  position: relative;
}
.add-jobs-inner-container .input-box-container .drop-list .single-select {
  width: 100%;
  border-radius: 5px;
  -webkit-appearance: none !important; /* Remove default arrow in Chrome */
  -moz-appearance: none !important; /* Remove default arrow in Firefox */
  appearance: none !important; /* Remove default arrow in all browsers */
}
.add-jobs-inner-container .input-box-container .drop-list::after {
  content: "" !important;
  position: absolute !important;
  top: 22px !important;
  right: 10px !important;
  width: 22px !important;
  height: 22px !important;
  background-image: url("../../../images/svg/down-new.svg") !important; /* Custom arrow icon */
  background-size: contain !important;
  background-repeat: no-repeat !important;
  transform: translateY(-50%) !important;
  pointer-events: none !important; /* Makes sure the icon doesn’t block clicks */
}
.add-jobs-inner-container .input-box-container .drop-list:has(.single-select:disabled)::after {
  background-image: url("../../../images/svg/down-new-disabled.svg") !important;
}
.add-jobs-inner-container .work-hours-sub-heading {
  color: #000000;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  line-height: 26px;
}
.add-jobs-inner-container .job-images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 12px;
}
.add-jobs-inner-container .job-images-container input {
  display: none;
}
.add-jobs-inner-container .job-images-container label {
  margin-bottom: 0px !important;
  width: 100%;
}
.add-jobs-inner-container .job-images-container div {
  flex: 0 1 33%;
}
.add-jobs-inner-container .job-images-container .already-added-image-container {
  aspect-ratio: 1;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #33A8ED;
  border-radius: 10px;
  position: relative;
  margin-top: 0px;
}
.add-jobs-inner-container .job-images-container .already-added-image-container .close-icon {
  position: absolute;
  top: -11px;
  height: 28px;
  width: 28px;
  right: -11px;
  cursor: pointer;
}
.add-jobs-inner-container .job-images-container .already-added-image-container .close-icon img {
  height: 28px;
  width: 28px;
}
.add-jobs-inner-container .job-images-container .already-added-image-container .move-icon {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 50%;
  right: calc(100% - 2px);
  transform: translateY(-50%);
  cursor: pointer;
}
.add-jobs-inner-container .job-images-container .already-added-image-container .already-added-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.add-jobs-inner-container .job-images-container .single-job-image, .add-jobs-inner-container .job-images-container .single-job-image-disabled {
  margin: 0 10px;
  aspect-ratio: 1;
  width: calc(100% - 20px);
  height: 100%;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../images/image-upload-icon-normal.png");
}
.add-jobs-inner-container .job-images-container .single-job-image:hover, .add-jobs-inner-container .job-images-container .single-job-image-disabled:hover {
  background-image: url("../../../images/image-upload-icon-hover.png");
}
.add-jobs-inner-container .job-images-container .single-job-image-disabled {
  cursor: default;
}
.add-jobs-inner-container .job-images-container .single-job-image-disabled:hover {
  background-image: url("../../../images/image-upload-icon-normal.png");
}
.add-jobs-inner-container .carousel-container {
  position: relative;
}
.add-jobs-inner-container .carousel-container .single-img .carousel-close-icon {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 10px;
  width: 25px;
  height: 25px;
}
.add-jobs-inner-container .work-hours-heading {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  border-bottom: 1px solid #CAD5DF;
  margin: 25px 0px 0px;
  text-align: left;
  position: relative;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .work-hours-heading {
    font-size: 18px;
  }
}
.add-jobs-inner-container .work-hours-heading .mandatory-container {
  background-color: #ED5356;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -35px;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .work-hours-heading .mandatory-container {
    left: -30px;
  }
}
.add-jobs-inner-container .work-hours-sub-heading-special {
  font-size: 12px;
  text-align: left;
  padding: 0px;
}
.add-jobs-inner-container .work-hours-heading-special {
  color: #6FCCCF;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin: 20px auto 10px;
  padding-top: 20px;
  text-align: left;
  border-bottom: 1px solid #6FCCCF;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .work-hours-heading-special {
    padding-left: 0px;
    font-size: 18px;
  }
}
.add-jobs-inner-container .work-hours-heading-special .tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .add-jobs-inner-container .work-hours-heading-special .tooltip-text {
    display: none;
  }
}
.add-jobs-inner-container .add-shift-button {
  margin-top: 10px;
  background-color: #007BFF;
  outline: none;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 14px;
  border-radius: 24px;
  width: 341px;
  height: 38px;
}
.add-jobs-inner-container .work-hours-list-container {
  border-top: 1px solid #CAD5DF;
  margin-top: 25px;
}
.add-jobs-inner-container .work-hours-list-container .control-arrow {
  background-color: #000000;
}
.add-jobs-inner-container .work-hours-list-container .work-hour-single {
  display: flex;
  border-bottom: 1px solid #CAD5DF;
  justify-content: space-between;
  height: 50px;
}
.add-jobs-inner-container .work-hours-list-container .work-hour-single .work-hour-left {
  display: flex;
  align-items: center;
  color: #9B9B9B;
  font-size: 13px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
}
.add-jobs-inner-container .work-hours-list-container .work-hour-single .work-hour-right {
  display: flex;
  align-items: center;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}
.add-jobs-inner-container .merits-container {
  width: 375px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .merits-container {
    width: 300px;
    display: block;
  }
}
.add-jobs-inner-container .merits-container .single-merit-category-container {
  width: 170px;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .merits-container .single-merit-category-container {
    width: 300px;
  }
}
.add-jobs-inner-container .merits-container .sales-merits-header {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 30px auto 20px;
  text-align: left;
}
.add-jobs-inner-container .multiple-select-container {
  width: 375px;
  max-width: 100%;
  margin: 0px auto;
  justify-content: space-between;
  display: flex !important;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .multiple-select-container {
    width: 340px;
  }
}
.add-jobs-inner-container .multiple-select-container .salary-long-dash {
  display: flex;
  align-items: flex-end;
  padding-bottom: 45px;
}
.add-jobs-inner-container .special-multiple-select-container {
  width: 375px;
  margin: 0px auto;
  justify-content: space-between;
  display: flex !important;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .special-multiple-select-container {
    width: 340px;
  }
}
.add-jobs-inner-container .special-multiple-select-container .select-inner {
  width: 320px;
  display: flex;
}
@media (max-width: 768px) {
  .add-jobs-inner-container .special-multiple-select-container .select-inner {
    width: 170px;
  }
}
.add-jobs-inner-container .special-multiple-select-container .button {
  display: flex;
}
.add-jobs-inner-container .special-multiple-select-container .button .button-inner {
  align-self: flex-end !important;
}

.clickable-link {
  color: #0078FF;
  cursor: pointer;
  text-align: center;
  padding-top: 15px;
  font-size: 17px;
  text-decoration-line: underline;
}

.MuiCheckbox-colorPrimary.Mui-checked:not(.Mui-disabled) {
  color: #0078FF !important;
}

.MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}