.transaction-history-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);

  margin-top: 100px!important;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }
  .transaction-page-description {
    font-size: 12px;
    width: auto!important;
    border-radius: 8px;
    border: 2px solid #3AA9EE;

    .transaction-page-container {
      display: flex;
      flex-wrap: wrap;
      padding: 24px 32px;
      gap: 28px;

      @media (max-width: 768px) {
        padding: 8px 10px;
        gap: 8px;
        flex-direction: column;
      }
      
      .transaction-page-column {
        flex: 1 1 0%;

        .transaction-page-column-heading {
          border-radius: 4px;
          padding-top: 21px;
          border-radius: 4px;
          color: #FFF;

          text-align: center;
          font-family: "Noto Sans JP";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          @media (max-width: 768px) {
            font-size: 20px;
          }

          &__red {
            padding-bottom: 32px;
            background: #EA696C;

            @media (max-width: 768px) {
              padding-bottom: 23px;
              margin-top: 54px;
            }
          }

          &__blue {
            padding-bottom: 13px;
            background: #33A8ED;
            padding-top: 12px;
          }
        }

        .transaction-page-column-text {
          margin-top: 9px;
          color: #08105D;
          text-align: justify;
          font-family: "Noto Sans JP";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          letter-spacing: -0.132px;
          @media (max-width: 768px) {
            margin-top: 14px;
            margin-bottom: 14px;
          }
        }

        .transaction-page-button {
          border-radius: 16px;
          background: #FFF;
          box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.20);
          padding: 16px 0;

          font-family: "Noto Sans JP";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.22px;
          width: 100%;
          margin-top: 24px;

          &:hover {
            transform: scale(1.05);
          }

          @media (max-width: 768px) {
            margin-top: 16px;
            font-size: 20px;
            padding: 16px 0;
            border-radius: 16px;

            &:hover {
              transform: unset;
            }
          }
        }

        .btn1 {
          border: 3px solid #DA736F;
          color: #DA736F;
        }

        .btn2 {
          border: 3px solid #28C1DD;
          color: #28C1DD;
        }

        .btn3 {
          border: 3px solid #1A9EFF;
          color: #1A9EFF;
        }

        .btn4 {
          border: 3px solid #7B70F7;
          color: #7B70F7;
        }
      }
    }
  }
  .card-container {
    width: 765px;
    background-color: #F4F8F9;
    height: 130px;
    margin: 20px auto;
    border: 1px solid rgba(17, 22, 26, 0.16);
    box-shadow: 0 4px 3px 0 rgba(17, 22, 26, 0.16);
    @media (max-width: 1024px) {
      width: 500px;
      height: auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
    .header {
      color: #000000;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: 0.59px;
      height: 40px;
      text-align: left;
      padding: 0px 10px;
    }
    .sub-header {
      color: #4A4A4A;
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 300;
      letter-spacing: 0.59px;
      height: 30px;
      text-align: left;
      padding: 5px 10px;
    }
    .data {
      display: flex;
      @media (max-width: 1024px) {
        display: block;
      }
      .single-data-container {
        flex: 1;
        padding: 5px 10px;
        .label {
          color: #9B9B9B;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
        }
        .value {
          color: #4A4A4A;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          width: 240px;
          text-align: left;
        }
      }
      .pricing-container {
        flex: 2;
        padding: 5px 10px;
        display: flex;
        .single-data {
          width: 70px;
          .label {
            color: #9B9B9B;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
          }
          .value {
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            // width: 240px;
            text-align: left;
          }
        }
      }
    }
  }
}

.info-box-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 8px;

  .info-box {
    border-radius: 50px;
    background: #FFBE00;
    padding: 4px 10px;

    color: #000;

    text-align: justify;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.132px;
  }

  @media (max-width: 768px) {
    gap: 30px;
    justify-content: center;
  }

  &__table {
    @extend .info-box-container;

    @media (max-width: 768px) {
      gap: 6px;
      .info-box {
        font-size: 9px;
      }
    }
  }
}

.info-small-heading {
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 9px;
  }
}

.custom-td, .custom-th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.custom-root-class, .custom-label-class {
  color: #555555 !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #197DFB !important;
}

.MuiRadio-root {
  color: #CBCBCB !important;
}

.width-65 {
  width: 65% !important;
}

.MuiButtonBase-root {
  margin-right: auto;
}

.MuiIconButton-label {
  transform: scale(1.2);
}

.extra-small {
  font-size: xx-small;
}

.bg-light-pink {
  background: #FFF7F7;
}

.bg-light-blue {
  background: #F7FAFF;
}

.large-text {
  font-size: 40px;
  color: #4F576B;
  margin: 10px 0px;
}

.select-plan-container-1 {
  color: #08105D;

  font-family: "Noto Sans JP";
  
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  font-size: 24px;
  letter-spacing: -0.264px;
  @media (max-width: 768px) {
    font-size: 18px;
    margin: 0 12px;
  }
  &__small {
    font-size: 14px;
    letter-spacing: -0.154px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.select-plan-main-text {
  color: #08105D;

  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.22px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.select-plan-container-2 {
  margin: 24px auto;
  width: 731px;
  font-family: "Noto Sans JP";

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
  }
}

.container-2-mobile {
  font-size: 14px!important;
  @media (max-width: 768px) {
    font-size: 12px!important;
  }
}

.new-container-2-mobile {
  margin-top: 12px;
  margin-bottom: 51px;
  color: #1F1F1F;

  font-family: "Noto Sans JP";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.billing-step-container {
  display: flex;
  width: 45px;
  height: 45px;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 2px solid #FFBE00;
  color: #08105D;
  margin: auto 0;

  font-family: "Noto Sans JP";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.264px;
  margin-right: 8px;
  @media (max-width: 768px) {
    font-size: 14px!important;
    width: 32px;
    height: 32px;
  }
}

.billing-step1-container {
  border-radius: 8px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 29px;
  padding: 24px 0;
  margin-top: 13px;
  position: relative;
  @media (max-width: 768px) {
    gap: 8px;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px 42px;
    margin-right: 36px;
    margin-left: 36px;
  }
  
  .billing-step1-text1 {
    color: #08105D;

    font-family: "Noto Sans JP";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.264px;
    @media (max-width: 768px) {
      font-size: 20px!important;
      width: 100%;
      max-width: 173px;
    }
  }
  
  .billing-step1-text2 {
    color: #FFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background: #FFBE00;
    padding: 10px;
    
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.264px;
    @media (max-width: 768px) {
      font-size: 20px!important;
      padding: 4px;
      width: 100%;
      max-width: 173px;
    }

    &__small {
      font-size: 16px;
      letter-spacing: -0.176px;
      padding: 0;
      @media (max-width: 768px) {
        font-size: 14px!important;
      }
    }
  }
}

.billing-step1-circle {
  position: absolute;
  top: -45px;
  right: 0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 62px;
  background: #FFBE00;
  color: #FFF;

  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.198px;
  @media (max-width: 768px) {
    font-size: 10px;
    width: 60px;
    height: 60px;
    top: -32px;
  }
}

.billing-heading-text {
  color: #08105D;
  display: flex;
  align-items: center;
  font-family: "Noto Sans JP";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.352px;
  @media (max-width: 768px) {
    font-size: 18px;
    text-align: left;
  }
}

.billing-heading-text2 {
  color: #08105D;
  margin-top: 41px;
  font-family: "Noto Sans JP";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: -0.264px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 12px;
  }
}

.billing-plan-image {
  border-radius: 12px;
  border: 1px solid #6B6B6B;
  background: #FFF;
  aspect-ratio: 698/321;
}

.billing-heading-text3 {
  color: #08105D;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans JP";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.352px;
  margin-top: 66px;
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 18px;
  }
}

.image-5-container {
  margin: 40px auto;
  width: 731px;
  font-family: "Noto Sans JP";

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
  }

  img {
    width: 406px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.billing-header-red {
  color: #F24822;
}

.billing-warning-container {
  border-radius: 12px;
  border: 2px solid #197DFB;
  background: rgba(217, 217, 217, 0.00);
  padding: 24px 36px;
  margin: 12px auto;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 160%; /* 72px */
  letter-spacing: -0.495px;
  width: 731px;
  font-family: "Noto Sans JP";

  @media (max-width: 1024px) {
    width: 500px;
    margin: 12px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 12px;
  }

  .billing-warning-1 {
    color: rgba(0, 0, 0, 0.80);
    font-weight: 700;
  }
  .billing-warning-2 {
    color: #EA696C;
    font-weight: 700;
  }
  .billing-warning-3 {
    color: #000;
    font-weight: 400;

    a {
      color: #000;
      font-weight: 900;
    }
  }
}

.divider-line {
  margin: 24px auto;
  border: none;
  border-top: 2px dashed rgba(85, 85, 85, 0.60);;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 731px;

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
  }
}

.billing-subtext {
  margin: 8px auto;
  color: #1E1E1E;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.385px;
  font-family: "Noto Sans JP";

  width: 731px;

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
  }
}

.billing-body-container {
  margin: 24px auto;
  padding: 12px 36px;
  border-radius: 4px;
  border: 1px solid rgba(85, 85, 85, 0.1);
  background: #FFF;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.15);
  width: 731px;
  font-family: "Noto Sans JP";

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
    padding: 12px 24px;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
  }

  .billing-body-text {
    border-radius: 2px;
    background: #BDE3FF;
    backdrop-filter: blur(2px);
    margin: 24px 0px;
    text-align: left;
    padding: 20px 24px;

    color: rgba(0, 0, 0, 0.80);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 57px */
    letter-spacing: -0.418px;
    font-family: "Noto Sans JP";

    strong {
      font-weight: 900;
      letter-spacing: 0.2px;
    }
  }
}

.billing-body-container-2 {
  margin: 24px auto;
  padding: 12px 12px;
  border-radius: 3px;
  border: 1px solid #0000003f;
  background: rgba(217, 217, 217, 0.00);
  width: 731px;
  position: relative;

  @media (max-width: 1024px) {
    width: 500px;
    margin: 20px auto;
  }
  @media (max-width: 768px) {
    width: auto;
    margin: 24px 12px;
    padding: 12px 4px;
  }

  img {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }

  .inquiry-line {
    text-align: right;

    color: #0D99FF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.44px;
    font-family: "Noto Sans JP";
    
    a {
      color: #0D99FF;
      text-decoration-line: underline;
    }
  }
}

.transactions-button-container {
  width: 500px;
  margin: 20px auto!important;
  font-family: "Noto Sans JP";
  @media (max-width: 768px) {
    width: 350px;
    // height: auto;
  }
  .section-link {
    margin: 20px auto;
    width: 350px!important;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 4px!important;
    border: 2px solid #1A9EFF!important;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    color: rgba(0, 0, 0, 0.80)!important;
    text-align: center!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 150%!important; /* 82.5px */
    letter-spacing: -0.605px!important;
  }
}

.new-transactions-button-container {
  button {
    border-radius: 16px;
    border: 3px solid #3AA9EE;
    padding: 16px 32px;
    background: #FFF;
    color: #3AA9EE;

    font-family: "Noto Sans JP";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    letter-spacing: -0.264px;
    width: 326px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      padding: 12px 24px;
      border-radius: 12px;
      font-size: 16px;
      width: 250px;
      margin-bottom: 16px;
    }
  }
}

.table-top-text {
  text-align: right;
  color: #08105D;

  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
}

.new-table-container {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #787878;
  overflow: hidden;

  table {
    tr {
      &:first-of-type th {
        border-top: 0;
        &:first-of-type {
          border-left: 0;
        }
        &:last-of-type {
          border-right: 0;
        }
      }
      &:last-of-type td {
        border-bottom: 0;
      }
      td {
        &:first-of-type {
          border-left: 0;
        }
        &:last-of-type {
          border-right: 0;
        }
      }
    }
  }
}

.new-table-header {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 67.5px */
  padding-top: 8px;
  padding-bottom: 8px;


  @media (max-width: 1024px) {
    font-size: 16px!important;
  }
  @media (max-width: 768px) {
    font-size: 14px!important;
  }
  @media (max-width: 550px) {
    font-size: 12px!important;
  }
}

.new-table-header-red {
  border: 1px solid #807451;
  background: #EA696C;
}

.new-table-header-blue {
  border: 1px solid #808080;
  background: #33A8ED;
}


.new-table-title {
  background: #ffe8a4;
  text-wrap: nowrap;
  white-space: nowrap;
}

.new-table-value {
  color: #08105D;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 60px */
  letter-spacing: -0.143px;
  border: 1px solid #807451;
  font-family: "Noto Sans JP";

  @media (max-width: 1024px) {
    font-size: 14px!important;
  }
  @media (max-width: 768px) {
    font-size: 14px!important;
  }
  @media (max-width: 550px) {
    font-size: 12px!important;
  }
}

.table-container {
  @media (max-width: 768px) {
    margin: 0px!important;
  }
}

.new-table-radio-value {
  color: #08105D;
  text-align: right;
  font-size: 16px!important;
  font-style: normal;
  font-weight: 700!important;
  line-height: 150%!important; /* 60px */
  letter-spacing: -0.44px!important;
  margin: 4px auto!important;
  width: 105px;
  float: left;
  margin-left: 14px!important;

  @media (max-width: 1024px) {
    font-size: 14px!important;
    width: 100px;
  }
  @media (max-width: 768px) {
    font-size: 14px!important;
    width: 75px;
  }
  @media (max-width: 550px) {
    font-size: 12px!important;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 0 !important;
    float: unset;
  }
}

table th:first-child,
table td:first-child {
  width: 60px; /* Let it take up variable space */
}

table th:not(:first-child),
table td:not(:first-child) {
  width: auto; /* Distribute remaining space equally */
}

.MuiRadio-root {
  color: #08105D !important;
  height: 1.25rem!important;
  overflow: hidden!important;
  padding: 20px!important;
  
  @media (min-width: 550px) {
    padding: 10px 0!important;
  }
}
.MuiIconButton-label {
  transform: scale(1.35)!important;
  
}
  .MuiTypography-body1 {
    margin-left: 4px!important;
    @media (max-width: 768px) {
      margin-left: 0px!important;
    }
  }

.PrivateSwitchBase-root-2 {
  background-color: #FFF!important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #08105D !important;
}

.table-radio {
  background-color: #FFF!important;
  @media (max-width: 768px) {
    padding: 2px!important;
    width: 20px!important;
    // height: auto;
  }
}

.plan-button {
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}