.drawer-styles {
  width: 100%;
  height: 100%;
}

.special-country-list {
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  border: 1px solid #4A4A4A;
  border-radius: 24px;
  min-width: 85px;
  height: 32px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.special-age-list-container {
  border: 1px solid #4a4a4a;
  border-radius: 12px;
  // min-width: 230px;
  // background-color: lightgray;
  margin: 5px auto !important;
  height: 32px;
}

.special-age-list {
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  // border: 1px solid lightgray;
  border-radius: 24px;
  // min-width: 200px;
  // background-color: lightgray;
  // width: 210px;
  padding: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  // justify-content: center;
}
.speacial-loading-background {
  background-color: #FFFFFF !important;
}

.company-details-error-message {
  color: #ED5356 !important;
  font-size: 13px !important;
  font-family: YuGo !important;
}

.company-profile-container {
  width: calc(100vw - 220px);
  // height: screen.height;
  margin-left: 220px;
  background-color: #FFFFFF;
  padding-bottom: 30px;
  margin-top: 160px;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
  .special-overlay-loader {
    position: fixed;
    padding-top: 150px;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
  }
  .page-header {
    background-color: #FFFFFF;
    width: 100%;
    color: #4F576B;
    font-family: YuGo;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 40px;
    line-height: 48px;
    text-align: left;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    height: 90px;
    padding-left: 40px;
    @media (max-width: 768px) {
      padding-left: 20px;
    }
    .sidebar-container-icon {
      display: none;
      cursor: pointer;
      @media (max-width: 768px) {
        display: block;
        margin-right: 15px;
      }
    }
  }
  .card-main-container {
    width: 900px;
    height: auto;
    margin: 0px auto 20px;
    padding-top: 25px;
    font-family: YuGo !important;
    background-color: #FFFFFF;
    @media (max-width: 1024px) {
      width: 700px;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
    .section-header {
      padding-left: 30px;
      color: #4A4A4A;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.38px;
      line-height: 50px;
      height: 50px;
      width: 100%;
      text-align: left;
      border-bottom: 3px solid #CAD5DF;
      display: flex;
      justify-content: space-between;
    }
    .table-row {
      display: flex;
      margin: 20px 30px;
      @media (max-width: 768px) {
        display: block;
      }
      .table-column-left {
        width: 350px;
        min-width: 350px;
        color: #9B9B9B;
        font-family: YuGo;
        font-size: 14px;
        font-weight: 400;
        line-height: 26.4px;
        padding-bottom: 5px;
        text-align: left;
        position: relative;
        @media (max-width: 1024px) {
          width: 200px;
          min-width: 200px;
        }
        @media (max-width: 768px) {
          width: 350px;
        }
        .optional-container {
          background-color: #007AFF;
          color: #FFFFFF;
          font-size: 9px;
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: -35px;
          @media (max-width: 768px) {
            left: -30px;
          }
        }
        .mandatory-container {
          background-color: #ED5356;
          color: #FFFFFF;
          font-size: 9px;
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: -35px;
          @media (max-width: 768px) {
            left: -30px;
          }
        }
      }
      .special-option-box {
        display: flex;
      }
      .table-column-right {
        color: #4A4A4A;
        font-family: YuGo;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.41px;
        line-height: 26.4px;
        text-align: left;
        width: 550px;
        // display: flex;
        @media (max-width: 1024px) {
          width: 500px;
        }
        @media (max-width: 768px) {
          width: 350px;
        }
        .option-button {
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        input {
          border: 1px solid lightgray;
          border-radius: 5px;
          width: 100%;
          padding-left: 10px;
          height: 40px;
          @media (max-width: 768px) {
            width: 80%;
          }
        }
      }
      .table-column-right-special {
        // display: flex;
        flex-wrap: wrap;
      }
    }

  }
  .submit-button-container {
    padding: 10px;
    display: flex;
    width: 300px;
    margin: 0px auto;
    justify-content: space-between;
  }

}
