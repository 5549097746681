.custom-event-container {
  height: 100%;
  display: flex;
  width: 100% !important;
  justify-content: space-between !important;
  text-transform: capitalize;
  font-size: 13px;
  align-items: center;
  padding: 2px 5px;
}
.custom-event-container .booked {
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}