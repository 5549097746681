.error-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px !important;
}
@media (max-width: 768px) {
  .error-modal {
    height: 450px;
    margin-left: 0 !important;
  }
}
.error-modal .dialog-title {
  text-align: center;
  padding: 10px;
  background-color: #ED5356;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.error-modal .dialog-title .main-close-icon {
  cursor: pointer;
}
.error-modal .dialog-message-container {
  text-align: center;
  min-width: 400px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .error-modal .dialog-message-container {
    max-width: 265px;
    min-width: 265px;
  }
}
.error-modal .dialog-message-container .ok-button {
  border: 2px solid #ED5356;
  color: #ED5356;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .error-modal .dialog-message-container .special-error-message {
    font-size: 10px;
  }
}
.error-modal .dialog-message-container .close-icon-center .error-modal-header-text {
  font-size: 35px;
  color: #ED5356;
}