.success-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px!important;
  @media (max-width: 768px) {
    height: 450px;
    margin-left: 0!important;
  }
  .dialog-title {
    text-align: center;
    padding: 10px;
    background-color: #6FCCCF;
    height: 45px;
    // width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .main-close-icon {
      cursor: pointer;
    }
  }
  .dialog-message-container {
    text-align: center;
    height: 290px;
    min-width: 400px;
    max-width: 400px;
    overflow: hidden;
    @media (max-width: 768px) {
      max-width: 265px;
      min-width: 265px;
      height: 225px;
      max-height: 225px;
    }
    .ok-button {
      border: 2px solid #6FCCCF;
      color: #6FCCCF;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 20px auto;
      font-weight: 400 !important;
      font-size: 14px !important;
    }
    .close-icon-center {
      margin: 0px auto;
      .success-modal-header-text {
        color: #6FCCCF;
        font-size: 30px;
        font-weight: 400 !important;
      }
    }
  }
}

.warning-modal-text {
  font-size: 14px !important;
  font-weight: 400 !important;
}
