.drawer-styles {
  width: 100%;
  height: 100%;
}

.user-settings-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 160px;
  margin-bottom: 100px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }

  .toggle-user-settings-button {
    background-color: #007AFF;
    width: 100px;
    height: 100px;
    position: fixed;
    // height: 32px;
    right: 10px;
    bottom: 100px;
    color: #FFFFFF;
    // border: 1px solid #007AFF;
    border-radius: 60px;
    margin: 20px 0px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
    z-index: 999;
    @media (max-width: 768px) {
      width: 100px;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .settings-page-description {
    .guide {
      width: 100px;
      background-color: #007AFF;
      color: #FFFFFF;
      cursor: pointer;
      outline: none;
      margin: 10px;
    }
    width: 500px;
    margin: 0px auto;
    font-size: 14px;
    text-align: left;
    @media (max-width: 1024px) {
      margin: 0px auto;
    }

    @media (max-width: 768px) {
      width: 350px;
      font-size: 10px;
    }

  }
  .settings-form-container {
    width: 500px;
    margin: 0px auto;
    @media (max-width: 1024px) {
      margin: 0px auto;
    }

    @media (max-width: 768px) {
      width: 350px;
    }
    .save-details {
      background-color: #F7E51B;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      width: 295px;
      height: 50px;
      margin: 20px auto;
      outline: none;
      cursor: pointer;
    }
  }
  .password-form-container {
    width: 500px;
    margin: 100px auto;
    @media (max-width: 1024px) {
      margin: 100px auto;
      width: 350px;
    }
    .save-password {
      background-color: #F7E51B;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      width: 295px;
      height: 50px;
      margin: 20px auto;
      outline: none;
      cursor: pointer;
    }
  }
}
.verify-modal-container {
  .dialog-container {
    width: 400px;
    margin: 150px auto;
    // height: 500px;
    background-color: #FFFFFF;
    height: auto;
    padding: 20px 0px;
    @media (max-width: 1024px) {
      margin: 150px auto;
      width: 350px;
    }
    @media (max-width: 768px) {
      width: 300px;
      min-width: 350px;
    }
    .verify-header {
      color: #000000;
      font-family: Arial;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0.45px;
      width: 300px;
      margin: 10px auto;
      text-align: center;
    }
    .description {
      color: #00B38C;
      font-family: ArialMT;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      width: 320px;
      margin: 10px auto;
      text-align: center;
    }
    .form-container {
      width: 300px;
      margin: 30px auto 5px;
    }
    .resend-text {
      color: #4A4A4A;
      font-family: ArialMT;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      width: 300px;
      margin: 5px auto 30px;
      text-align: center;
      .resend-link {
        color: #007AFF;
        cursor: pointer;
      }
    }
    .submit-button-container {
      width: 300px;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;
    }
  }
}
