.confirm-modal {
  outline: none;
  width: 500px;
  margin: 100px auto;
  background-color: #FFFFFF;
}
@media (max-width: 1024px) {
  .confirm-modal {
    width: 450px;
  }
}
@media (max-width: 768px) {
  .confirm-modal {
    width: 90%;
  }
}
.confirm-modal .responsive-header {
  min-height: 50px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 50px;
}
.confirm-modal .responsive-header .login-header-text {
  font-size: 25px;
  color: #484848;
}
.confirm-modal .responsive-header .burger {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.confirm-modal .dialog-message-container {
  display: block;
  width: 90%;
  padding: 0px 20px 20px;
  margin: 0px auto;
}
.confirm-modal .dialog-message-container .warning-text {
  width: 100%;
  margin: 20px auto 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #ED5356;
}
.confirm-modal .dialog-message-container .confirmation-text {
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal .dialog-message-container .button-container {
  display: flex;
  justify-content: right;
  gap: 24px;
  margin: 0px auto;
}