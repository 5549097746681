.MuiDrawer-paperAnchorLeft-4 {
  width: 100%;
}

.drawer-styles {
  width: 100%;
  height: 100%;
}

.snackbar-details-icon .MuiSnackbarContent-root {
  font-family: "Noto Sans JP" !important;
  color: #08105D;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #ECECEC;
  background: #FFF;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
  width: 492px;
}
@media (max-width: 1024px) {
  .snackbar-details-icon .MuiSnackbarContent-root {
    width: 306px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #ECECEC;
    font-size: 14px;
    line-height: 14px;
  }
}

.jobs-landing-container {
  margin: 60px auto 0px;
  padding-top: 25px;
  padding-bottom: 100px;
  position: relative;
  overflow-x: hidden;
  background-color: #FFFFFF;
  margin-left: 220px;
  width: calc(100% - 220px);
  min-height: calc(100vh - 60px);
}
@media (max-width: 768px) {
  .jobs-landing-container {
    width: 100%;
    margin-left: 0px;
  }
}
.jobs-landing-container .header-sidebar-main-container {
  width: 220px;
  display: none;
}
@media (max-width: 375px) {
  .jobs-landing-container .header-sidebar-main-container {
    width: 375px;
    height: 100%;
  }
}
.jobs-landing-container .add-job-description {
  background-color: #FE7374;
  color: #FFFFFF;
  border-radius: 20px;
  height: 40px;
  font-size: 22px;
  width: 700px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  display: flex;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .jobs-landing-container .add-job-description {
    width: 350px;
    font-size: 11px;
  }
}
.jobs-landing-container .create-job-button {
  background-color: #33A8ED;
  width: 100px;
  height: 100px;
  position: fixed;
  right: 10px;
  bottom: 100px;
  color: #FFFFFF;
  border-radius: 60px;
  margin: 20px 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
  z-index: 999;
}
@media (max-width: 768px) {
  .jobs-landing-container .create-job-button {
    width: 100px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.jobs-landing-container .job-list-container {
  margin: 20px auto;
  width: 900px;
  position: relative;
}
@media (max-width: 1024px) {
  .jobs-landing-container .job-list-container {
    margin: 20px auto;
  }
}
.jobs-landing-container .job-list-container .first-time-job {
  background-color: #FFFFFF;
  width: 100%;
  height: 100vh;
  margin: 20px auto;
}
.jobs-landing-container .job-list-container .first-time-job .first-time-job-message {
  text-align: center;
  color: #9B9B9B;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.78px;
  line-height: 29px;
}
.jobs-landing-container .job-list-container .first-time-job .first-time-job-message .guide {
  width: 100px;
  background-color: #33A8ED;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  margin: 10px;
}
@media (max-width: 768px) {
  .jobs-landing-container .job-list-container .first-time-job .first-time-job-message {
    width: 350px;
  }
}
.jobs-landing-container .job-list-container .first-time-job .create-first-job-button {
  border: 1px solid #33A8ED;
  width: 70%;
  height: 50px;
  line-height: 50px;
  color: #33A8ED;
  background-color: #FFFFFF;
  margin: 30px auto;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .jobs-landing-container .job-list-container .first-time-job .create-first-job-button {
    width: 100px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.jobs-landing-container .job-list-container .archive-btn-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.jobs-landing-container .job-list-container .archive-btn-container .archive-btn {
  padding: 10px 32px;
  border-radius: 8px;
  font-family: "Noto Sans JP" !important;
  outline: none;
  border: 2px solid #FFBE00;
  color: #FFBE00;
  background-color: #FFF;
}
.jobs-landing-container .job-list-container .archive-btn-container .archive-btn:focus {
  outline: none;
}
.jobs-landing-container .job-list-container .archive-btn-container .archive-btn:hover {
  background: rgba(255, 213, 85, 0.5450980392);
}
.jobs-landing-container .job-list-container .archive-btn-container .btn-disabled {
  border: 2px solid #9B9B9B;
  color: #9B9B9B;
  cursor: default;
}
.jobs-landing-container .job-list-container .archive-btn-container .btn-disabled:hover {
  background: #FFF;
}
@media (max-width: 768px) {
  .jobs-landing-container .job-list-container {
    width: 350px;
  }
}