.shift-container {
  width: 375px;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .shift-container {
    width: 340px;
    max-width: 100%;
  }
}
.shift-container .days-error-message {
  margin-top: 20px;
  color: #ED5356;
  text-align: left;
  font-size: 12px;
}
.shift-container .time-container {
  margin: 0px;
  display: flex;
  justify-content: space-between;
}
.shift-container .time-container .time-inner {
  width: 320px;
  display: flex;
}
@media (max-width: 768px) {
  .shift-container .time-container .time-inner {
    width: 190px;
  }
}
.shift-container .time-container .button {
  display: flex;
}
.shift-container .time-container .button .button-inner {
  align-self: flex-end !important;
}
.shift-container .days-container {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 0px;
}
.shift-container .days-container .day {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  width: 40px;
  cursor: pointer;
  border: 1px solid #000000;
  color: #4A4A4A;
}
@media (max-width: 768px) {
  .shift-container .days-container .day {
    width: 30px;
    height: 30px;
  }
}