.backdrop-layer {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
}
.backdrop-layer .login-modal-container {
  outline: none;
  width: 500px;
  margin: 100px auto;
}
@media (max-width: 1024px) {
  .backdrop-layer .login-modal-container {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .backdrop-layer .login-modal-container {
    width: 100%;
  }
}
.backdrop-layer .login-modal-container .login-modal-inner {
  background-color: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
}
.backdrop-layer .login-modal-container .login-modal-inner .responsive-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  background: #7B70F7;
}
.backdrop-layer .login-modal-container .login-modal-inner .responsive-header .login-header-text {
  color: #FFF;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  font-family: Noto Sans JP;
  font-weight: 500;
  letter-spacing: 0.72px;
}
@media (min-width: 768px) {
  .backdrop-layer .login-modal-container .login-modal-inner .responsive-header .login-header-text {
    font-weight: 400;
  }
}
.backdrop-layer .login-modal-container .login-modal-inner .responsive-header .burger {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal {
  display: block;
  width: 350px;
  padding: 10px;
  margin: 0px auto;
}
.backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal .alert-danger {
  height: 40px;
  color: #ed5356;
  width: 100%;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  max-width: 500px;
  padding: 10px 0px;
  margin: 10px auto !important;
  color: #ed5356 !important;
  background-color: transparent !important;
}
.backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container {
  margin: 20px 0px;
}
.backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container .btn {
  border-radius: 16px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
}
@media (max-width: 768px) {
  .backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container .btn {
    max-width: 100%;
    width: 100%;
  }
}
.backdrop-layer .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container .forgot-password-text {
  font-size: 13px;
  text-align: center;
  padding: 10px;
}

.header-container {
  position: sticky;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: #FFFFFF !important;
  display: flex;
  justify-content: center;
  max-width: 100vw;
}
@media (max-width: 1024px) {
  .header-container {
    justify-content: space-between;
  }
}
.header-container .header {
  position: relative;
  height: 100%;
  width: 100%;
}
.header-container .header .header-title {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
}
.header-container .header .inner-container {
  padding: 16px;
  padding-top: 0px;
  width: 100% !important;
  border-bottom: 1px solid rgb(228, 228, 228) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100% !important;
  border-spacing: 0px !important;
}
.header-container .header .inner-container .link .main-image {
  cursor: pointer;
  margin-top: 5px;
  width: 255px;
}
.header-container .header .inner-container .login-button {
  position: absolute;
  right: 20px;
}