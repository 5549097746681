.error-message {
  width: 100%;
  max-width: 350px;
  font-size: 10px;
  color: #ED5356;
  text-align: left;
  margin: 5px auto;
}

.forgot-password-container {
  background-color: #FFFFFF;
  margin-top: 80px;
}
.forgot-password-container .submit-container {
  margin: 10px auto 30px;
}
.forgot-password-container .submit-container .forgot-password-text {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
.forgot-password-container .submit-container .forgot-password-page-submit-button {
  color: #4A4A4A;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 208px;
  background-color: #F8E71C;
  margin: 10px auto;
  height: 40px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.forgot-password-container .main-image-container {
  width: 450px;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .forgot-password-container .main-image-container {
    width: 350px;
  }
}
.forgot-password-container .main-image-container .main-image {
  height: 90px;
  width: auto;
}
@media (max-width: 768px) {
  .forgot-password-container .main-image-container .main-image {
    height: 50px;
  }
}
.forgot-password-container .main-image-container .page-header {
  color: #4A4A4A;
  font-size: 22.4px;
  font-weight: 700;
  letter-spacing: 0.36px;
  line-height: 19.2px;
  text-align: center;
}
.forgot-password-container .main-image-container .looking-for-job-button {
  color: #4A4A4A;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 208px;
  background-color: #F8E71C;
  margin: 30px auto;
  height: 40px;
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.forgot-password-container .form-container {
  width: 450px;
  background-color: #D8EFFD;
  margin: 20px auto;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .forgot-password-container .form-container {
    width: 100%;
  }
}
.forgot-password-container .form-container .form-header {
  color: #007AFF;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.42px;
  line-height: 24px;
  width: 350px;
  margin: 25px auto;
  text-align: left;
}
@media (max-width: 768px) {
  .forgot-password-container .form-container .form-header {
    width: 330px;
  }
}
.forgot-password-container .form-container .reset-password-header {
  color: #007AFF;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.42px;
  line-height: 24px;
  width: 350px;
  margin: 25px auto 10px;
  text-align: center;
}
@media (max-width: 768px) {
  .forgot-password-container .form-container .reset-password-header {
    width: 330px;
  }
}
.forgot-password-container .form-container .reset-password-description {
  color: #4A4A4A;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  width: 280px;
  margin: 0px auto 30px;
  text-align: center;
}
.forgot-password-container .fields-container {
  padding-bottom: 20px;
  width: 350px;
  margin: 0px auto;
}
.forgot-password-container .fields-container .double-fields-container {
  display: flex;
  width: 350px;
  margin: 15px auto 5px;
  justify-content: space-between;
}
.forgot-password-container .fields-container .double-fields-container .select-container {
  max-width: 170px;
}
.forgot-password-container .fields-container .double-fields-container .special-field {
  outline: none;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  height: 35px;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  background-color: #FFFFFF;
}
.forgot-password-container .fields-container .double-fields-container .special-field:focus {
  outline: 1px solid #388bee;
  border: 1px solid #388bee;
}
.forgot-password-container .fields-container .single-fields-container {
  width: 350px;
  margin: 15px auto 5px;
  display: block;
}
.forgot-password-container .fields-container .single-fields-container .single-field {
  outline: none;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  width: 350px;
  height: 35px;
  font-size: 16px;
  padding: 10px;
}
.forgot-password-container .fields-container .single-fields-container .single-field:focus {
  outline: 1px solid #388bee;
  border: 1px solid #388bee;
}
@media (max-width: 768px) {
  .forgot-password-container .fields-container .single-fields-container .single-field {
    width: 330px;
  }
}
.forgot-password-container .fields-container .checkbox-container {
  width: 350px;
  display: flex;
  margin: 5px auto 5px;
}
@media (max-width: 768px) {
  .forgot-password-container .fields-container .checkbox-container {
    width: 330px;
  }
}