.snackbar-filter {
  top: 320px !important;
  left: calc(50% + 110px) !important;
}
@media (max-width: 1200px) {
  .snackbar-filter {
    top: 380px !important;
  }
}
@media (max-width: 920px) {
  .snackbar-filter {
    top: 440px !important;
  }
}
@media (max-width: 768px) {
  .snackbar-filter {
    top: 350px !important;
    left: 50% !important;
    transform: translateX(-50%);
    width: calc(100% - 16px);
  }
}
@media (max-width: 370px) {
  .snackbar-filter {
    top: 400px !important;
  }
}
.snackbar-filter .MuiSnackbarContent-root {
  font-family: "Noto Sans JP" !important;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #ECECEC;
  background: #FFF;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
  height: 73px;
  width: 492px;
}
@media (max-width: 1024px) {
  .snackbar-filter .MuiSnackbarContent-root {
    height: 50px;
    width: 306px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #ECECEC;
    font-size: 18px;
    line-height: 18px;
  }
}

.filter-bar-box {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  max-width: 100%;
  margin: 0 auto;
  z-index: 99;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #FFF;
  padding-top: 12px;
}
.filter-bar-box .filter-info-text {
  color: #000;
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  width: 100%;
  padding: 4px 20px;
}
@media (max-width: 768px) {
  .filter-bar-box .filter-info-text {
    font-size: 10px;
  }
}
.filter-bar-box .filter-bar-type-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5px;
  width: -webkit-fill-available;
}
.filter-bar-box .filter-bar-type-container .filter-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 37px;
  flex-wrap: nowrap;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #ACACAC;
  flex: 1;
}
.filter-bar-box .filter-bar-type-container .filter-type .filter-name {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-wrap: nowrap;
}
.filter-bar-box .filter-bar-type-container .filter-type .filter-count {
  color: #FFF;
  font-family: "Noto Sans JP";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: -0.11px;
  background: #33A8ED;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-bar-box .filter-bar-type-container .filter-type .filter-type-highlighter {
  height: 3px;
  border-radius: 6px;
  background: #33A8ED;
  position: absolute;
  bottom: -1.5px;
  left: 0;
  width: 100%;
}
.filter-bar-box .filter-detail-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 4px 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  border-bottom: 1px solid #ACACAC;
  padding-bottom: 16px;
}
@media (max-width: 1300px) {
  .filter-bar-box .filter-detail-container {
    flex-direction: column;
    margin: 4px 16px;
  }
}
.filter-bar-box .filter-detail-container .filter-dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
@media (max-width: 1300px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  width: 136px;
  flex-wrap: nowrap;
  min-width: 136px;
  cursor: pointer;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown:hover, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled:hover, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled:hover, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large:hover {
  background: #E9E9E9;
}
@media (max-width: 768px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    padding: 6px 8px;
    width: 117px;
  }
}
@media (max-width: 500px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    width: 100% !important;
    max-width: unset !important;
  }
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
  min-width: 376px;
  width: -webkit-fill-available;
  max-width: 376px;
}
@media (max-width: 1500px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    min-width: 307px;
    max-width: 307px;
  }
}
@media (max-width: 1410px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    min-width: 202px;
    max-width: 202px;
  }
}
@media (max-width: 1300px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    min-width: 307px;
    max-width: 307px;
    width: unset;
  }
}
@media (max-width: 400px) {
  .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large {
    min-width: unset;
  }
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled:hover {
  background: #FFF;
  cursor: default;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled {
  border: 1px solid #A5DEFF;
  background: #A5DEFF;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled:hover {
  background: #A5DEFF;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-text, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-text, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-text, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-text, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-text__disabled {
  color: #000;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-text__disabled, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-text__disabled {
  color: #B8B8B8;
}
@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-menu, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-menu, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-menu, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-menu {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  background: #FFF;
  animation: smooth-appear 0.5s ease-out forwards;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-menu .filter-drop-down-main, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-menu .filter-drop-down-main, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-menu .filter-drop-down-main, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-menu .filter-drop-down-main {
  background: #FFF;
  width: 100%;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.15), 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-menu .filter-drop-down-main .os-scrollbar, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-menu .filter-drop-down-main .os-scrollbar, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar {
  bottom: 14px;
  width: 12px;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track {
  background-color: #A4A4A4;
  width: 2px;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track .os-scrollbar-handle, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track .os-scrollbar-handle, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track .os-scrollbar-handle, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-dropdown-menu .filter-drop-down-main .os-scrollbar .os-scrollbar-track .os-scrollbar-handle {
  background-color: #1A9EFF;
  width: 4px;
  left: -1px;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item1, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item1, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item1, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item1 {
  padding: 6px 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: #202020;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item1:nth-child(even), .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item1:nth-child(even), .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item1:nth-child(even), .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item1:nth-child(even) {
  background-color: #F3F3F3;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container {
  padding: 8px;
  cursor: default;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container .search-box, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container .search-box, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container .search-box, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container .search-box {
  border-radius: 28px;
  background: #F1F1F1;
  height: 40px;
  width: -webkit-fill-available;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container .search-box .search-box-input, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container .search-box .search-box-input, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container .search-box .search-box-input, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container .search-box .search-box-input {
  width: 100%;
  color: #000;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  outline: none;
  border: none;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container .search-box .search-box-input:focus, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container .search-box .search-box-input:focus, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container .search-box .search-box-input:focus, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container .search-box .search-box-input:focus {
  outline: none;
  border: none;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container .search-box .search-box-input::placeholder, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container .search-box .search-box-input::placeholder, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container .search-box .search-box-input::placeholder, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container .search-box .search-box-input::placeholder {
  color: #49454F;
  opacity: 1;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .search-box-container .search-box .search-box-icon, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .search-box-container .search-box .search-box-icon, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .search-box-container .search-box .search-box-icon, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .search-box-container .search-box .search-box-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-options, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-options, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-options, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-options {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item2, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item2, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item2, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: -webkit-fill-available;
  padding: 12px 0;
  margin: 0px 8px;
  border-bottom: 1px solid #ACACAC;
  text-align: left;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item2:last-child, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item2:last-child, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item2:last-child, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item2:last-child {
  border-bottom: none;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item2 .checkbox, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item2 .checkbox, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item2 .checkbox, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item2 .checkbox {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #ACACAC;
  margin-right: 8px;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item2 .checkbox-on, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item2 .checkbox-on, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item2 .checkbox-on, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item2 .checkbox-on {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}
.filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown .filter-item2 .value, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__large .filter-item2 .value, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__disabled .filter-item2 .value, .filter-bar-box .filter-detail-container .filter-dropdown-container .filter-dropdown__filled .filter-item2 .value {
  text-align: left;
  line-break: anywhere;
  text-wrap: wrap;
  color: #000;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: wrap;
}
.filter-bar-box .filter-detail-container .filter-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
}
@media (max-width: 1200px) {
  .filter-bar-box .filter-detail-container .filter-action-container {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save {
  gap: 16px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 920px) {
  .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save {
    width: unset;
  }
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save {
  flex: 1;
  justify-content: center;
}
@media (max-width: 1300px) {
  .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save {
    justify-content: unset;
    flex: unset;
  }
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled {
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 2px solid #33A8ED;
  color: #33A8ED;
  cursor: pointer;
}
@media (max-width: 920px) {
  .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled {
    flex: 1;
  }
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled:hover {
  background-color: rgba(51, 168, 237, 0.1254901961);
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled {
  border: 2px solid #B8B8B8;
  color: #B8B8B8;
  cursor: default;
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled:hover {
  background-color: #FFF;
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled {
  border: 2px solid #33A8ED;
  background: #33A8ED;
  color: #FFF;
  cursor: default;
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled:hover, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled:hover {
  background: #33A8ED;
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button .filter-action-icon, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button .filter-action-icon, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled .filter-action-icon, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled .filter-action-icon, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled .filter-action-icon, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled .filter-action-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled .filter-action-text {
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}
@media (max-width: 768px) {
  .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__disabled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__disabled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box .filter-action-button__filled .filter-action-text, .filter-bar-box .filter-detail-container .filter-action-container .filter-action-box__save .filter-action-button__filled .filter-action-text {
    display: none;
  }
}

.embla {
  width: 100%;
  padding: 0px 20px;
  position: relative;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(1rem) * -1);
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
}

.embla__buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.embla__button {
  cursor: pointer;
  pointer-events: all;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4.5px;
  background: linear-gradient(270deg, #FFF 33.33%, rgba(255, 255, 255, 0) 104.76%);
}

.embla__arrow-left-container {
  background: linear-gradient(90deg, #FFF 33.33%, rgba(255, 255, 255, 0) 104.76%);
}

.embla__arrow {
  padding: 0px 0px 0px 20px;
}

.arrow_left {
  transform: scaleX(-1);
  padding: 0px 0px 0px 20px;
}