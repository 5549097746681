
.add-jobs-main-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  margin-left: 220px;
  width: calc(100% - 220px);
  overflow-x: hidden;
  display: flex;
  @media (max-width: 768px) {
    top: 0;
    margin: 0px auto;
    width: 100%;
    background-color: #FFFFFF;
  }
  // height: 100vh;
  // width: 100vw;
  // background-color: rgba(0, 0, 0, 0.85);
  @media (max-width: 768px) {
    left: 0px;
  }
  .add-jobs-inner-header-container {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .help-icon {
    position: absolute;
    top: 10%;
    right: 20%;
    cursor: pointer
  }
  .add-job-outer-container {
    // margin-left: 220px;
    margin: 0px auto;
    @media (max-width: 768px) {
      // left: 0px;
      margin: 0px auto;
      width: 100%;
    }
    .add-job-inner-container {
      width: 500px;
      top: 50px;
      background-color: #FFFFFF;
      box-shadow: 0 20px 35px 1px rgba(0, 0, 0, 0.08);
      margin: 80px 0 50px; //   50px;
      border-radius: 15px;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: calc(100% - 8px);
        margin-left: 4px;
        margin-right: 4px;
      }
      .company-details-header-container {
        background-color: #263242;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        height: 100px;
        padding: 0 16px;
        .header {
          color: #FFFFFF;
          font-size: 28px;
          font-weight: 400;
          // line-height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100px;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        .sub-header {
          color: #FFFFFF;
          font-size: 17px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          // align-items: center;
          height: 40px;
          line-height: 20px;
          opacity: 0.8;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .single-stage {
        max-width: 375px;
        margin: 20px auto;
        @media (max-width: 768px) {
          width: calc(100% - 32px);
          margin: 20px 16px;
          max-width: unset;
        }
      }
      .stage-container {
        height: 80px;
        display: flex;
        justify-content: space-between;
        width: 400px;
        margin: 10px auto;
        @media (max-width: 768px) {
          width: 300px;
        }
        .single-stage {
          width: 75px;
          @media (max-width: 768px) {
            width: 75px;
          }
          .stage-id {
            border: 1px solid #263242;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px auto;
            @media (max-width: 768px) {
              font-size: 15px;
              height: 35px;
              width: 35px;
            }
          }
          .stage-name {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            @media (max-width: 768px) {
              font-size: 10px;
            }
          }
        }
      }
      .company-details-close-button {
        background-color: #007AFF;
        border-radius: 27px;
        width: 150px;
        height: 47px;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        line-height: 20px;
        outline: none;
        text-align: center;
      }

      .details-page-button-container {
        width: 375px;
        display: flex;
        justify-content: space-between;
        margin: 0px auto;
        padding-bottom: 20px;
        @media (max-width: 768px) {
          width: 300px;
        }

        .submit-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .submit-warning {
            color: #FF6565;
            text-align: center;
            font-family: "Noto Sans JP";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            letter-spacing: -0.154px;
            margin-left: 12px;
            margin-top: 6px;
          }
        }
        .next-button {
          background-color: #007AFF;
          border-radius: 27px;
          width: 150px;
          height: 47px;
          color: #FFFFFF;
          font-size: 17px;
          font-weight: 400;
          line-height: 20px;
          outline: none;
          text-align: center;
          border: none;
          text-wrap: nowrap;
          &:hover {
            background-color: #006ce0;
          }
        }
        .previous-button {
          color: #007AFF;
          border-radius: 27px;
          width: 50px;
          display: flex;
          align-items: center;
          height: 47px;
          font-size: 17px;
          font-weight: 400;
          line-height: 20px;
          cursor: pointer;
          text-align: left;
          border: none;
        }
        .dummy-button {
          width: 150px;
          height: 2px;
          background-color: transparent;
        }
      }
    }
  }

}


.video-modal-container {
  margin-left: 220px!important;
  @media (max-width: 768px) {
    margin-left: 0!important;
  }
  .video-desktop {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
    .close-button-container {
      width: 100%;
      height: 40px;
      display: flex;
      background-color: #000000;
      align-items: center;
      justify-content: flex-end;
      .close-video-button {
        cursor: pointer;
        font-size: 30px;
        margin-right: 10px;
        color: #FFFFFF;
      }
    }
  }
  .video-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
}

.image-modal-container {
  min-width: 800px;
  overflow-y: scroll;
  @media (max-width: 1024px) {
    min-width: 100% !important;
  }
  @media (max-width: 768px) {
    min-width: 100% !important;
  }
  &::-webkit-scrollbar {
    width: 20px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;

    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar:vertical {
    height: 10px;
  }
  .image-modal-inner {
    width: 800px;
    margin: 0px auto;
    min-width: 800px;
    position: relative;
    @media (max-width: 1024px) {
      width: 760px;
      min-width: 760px;
    }
    @media (max-width: 768px) {
      width: 300px;
      min-width: 300px;
    }
    .create-post-modal-image {
      width: 800px !important;
      margin: 0px auto;
      @media (max-width: 1024px) {
        width: 760px !important;
      }
      @media (max-width: 768px) {
        width: 300px !important;
      }
    }
    .close-button-container {
      width: 100%;
      height: 40px;
      position: sticky;
      display: flex;
      top: 0px;
      background-color: #000000;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 1024px) {
        width: 760px;
      }
      @media (max-width: 768px) {
        width: 300px;
      }
      .close-video-button {
        cursor: pointer;
        font-size: 30px;
        margin-right: 10px;
        color: #FFFFFF;
      }
    }
  }
}

// .add-jobs-container {
//   margin: 0px auto;
//   margin-top: 140px;
//   background-color: #FFFFFF;
//   overflow-x: hidden;
//   position: relative;
//   margin-left: 220px;
//   width: calc(100% - 220px);
//   display: flex;
//   @media (max-width: 768px) {
//     margin-left: 0px;
//     width: 100%;
//     background-color: #FFFFFF;
//   }
//   .create-post-container {
//     width: 50%;
//     padding-top: 50px;
//     max-width: 50%;
//     @media (max-width: 1024px) {
//       display: none;
//     }
//     .create-post-image {
//       max-width: 500px;
//       cursor: pointer;
//       border: 5px solid #007AFF;
//     }
//   }
//   .add-jobs-form-container {
//     margin: 0px auto 50px;
//     max-width: 50%;
//     background-color: #FFFFFF;
//     width: 450px;
//     padding-top: 50px;
//     @media (max-width: 1024px) {
//       max-width: 100%;
//       margin: 0px auto;
//     }
//
//     @media (max-width: 768px) {
//       width: 300px;
//     }
//     .show-image-modal-mobile {
//       display: none;
//       @media (max-width: 1024px) {
//         display: block;
//       }
//       @media (max-width: 768px) {
//         display: block;
//       }
//     }
//     .checkbox-container {
//       text-align: left;
//       width: 450px;
//       margin: 20px auto;
//       display: flex;
//       align-items: center;
//       span {
//         padding-left: 0px !important;
//       }
//       .convenient-times-text {
//         width: 450px;
//       }
//       @media (max-width: 1024px) {
//         width: 300px;
//       }
//       @media (max-width: 768px) {
//         width: 300px;
//       }
//     }
//     .form-section-header {
//       color: #6FCCCF;
//       font-size: 24px;
//       font-weight: 600;
//       letter-spacing: 0.38px;
//       line-height: 25px;
//       margin: 20px auto;
//       padding-top: 20px;
//       text-align: left;
//       border-bottom: 1px solid #6FCCCF;
//       @media (max-width: 768px) {
//         padding-left: 0px;
//       }
//       .tooltip-text {
//         width: 12px;
//         margin: 0px 5px;
//         cursor: pointer;
//         @media (max-width: 1024px) {
//           display: none;
//         }
//       }
//     }
//     .post-job-button {
//       margin-top: 30px;
//       color: #4A4A4A;
//
//       font-size: 16px;
//       font-weight: 600;
//       letter-spacing: 1px;
//       line-height: 19px;
//       width: 450px;
//       height: 70px;
//       outline: none;
//       cursor: pointer;
//       background-color: #F8E71C;
//       text-align: center;
//       @media (max-width: 768px) {
//         width: 100vw;
//         position: fixed;
//         bottom: 0px;
//         left: 0px;
//       }
//     }
//     .view {
//       background-color: #afa20f;
//       cursor: default;
//     }
//   }
// }

.MuiRadio-root {
  width: auto !important;
}

.PT1-color {
  background-color: #FC6B6D !important;
}
.PT2-color {
  background-color: #28C1DD !important;
}
.FT-color {
  background-color: #1A9EFF !important;
}
.SSV-color {
  background-color: #7B70F7 !important;
}
