.screening-body {
    font-family: "Noto Sans JP";
    font-weight: 400;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .screening-title {
        color: #1D1B20;
        font-size: 36px;
        margin-bottom: 24px;
        white-space: nowrap;
        @media (max-width: 768px) {
          font-size: 20px;
        }
    }

    .screening-text {
        color: rgba(85, 85, 85, 0.80);
        font-size: 24px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
    }

    .screening-btn-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;
        gap: 44px;
        
        .screening-btn {
            color: #1A9EFF;
            cursor: pointer;
            font-size: 22px;
            @media (max-width: 768px) {
              font-size: 15px;
            }
        }
    }

    .option-container {
        background-color: #1A9EFF;
        border-radius: 5px;
        padding: 5px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        width: fit-content;
        transform: translateY(-4px);
        margin: 0 8px;

        @media (max-width: 768px) {
          transform: translateY(-2px);
          margin: 0 4px;
        }

        .option-icon {
          height: 10px;
          width: 10px;
        }
  
        .option-text {
          color: #FFF;
          font-size: 12px;
          font-weight: 400;
        }
      }
}