.custom-toolbar-container {
  display: flex;
  z-index: 999;
  justify-content: space-between;
  margin-bottom: 10px;
}
.custom-toolbar-container .arrow-right {
  width: 0;
  height: 0;
  cursor: pointer;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #EA696C;
}
.custom-toolbar-container .arrow-left {
  width: 0;
  height: 0;
  cursor: pointer;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 25px solid #EA696C;
}