.confirm-purchase-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);

  margin-top: 160px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }
  .before-trial-text {
    margin: 20px auto;
    width: 1000px;
    font-size: 14px;
    // text-align: left;
    @media (max-width: 1024px) {
      width: 500px;
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .billing-failed-container {
    width: 700px !important;
    margin: 20px auto;
    @media (max-width: 768px) {
      width: 350px !important;
    }
    .status-description-text {
      margin: 20px auto;
      text-align: left;
      width: 100%;
      @media (max-width: 1024px) {
        width: 500px;
        margin: 20px auto;
      }
      @media (max-width: 768px) {
        width: 350px;
      }
    }
  }

  .status-description-text {
    margin: 20px auto;
    width: 550px;
    text-align: left;
    @media (max-width: 1024px) {
      width: 500px;
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .resubscribe {
    // width: 150px;
    height: 40px;
    // line-height: 40px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 100px;
    width: 300px;
    color: #007AFF;
    background-color: #FFFFFF;
    border: 1px solid #007AFF;
    @media (max-width: 1024px) {
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .add-edit-button-description {
    font-size: 12px;
    margin: 5px 0px;
    text-align: left;
  }
  .billing-page-inner {
    margin: 100px auto;
    width: 500px;
    // border: 1px solid black;
    @media (max-width: 1024px) {
      margin: 20px auto;
    }
    @media (max-width: 768px) {
      width: 350px;
      margin: 20px auto !important;
    }
    .transactions-button-container {
      width: 500px;
      margin: 50px auto;
      @media (max-width: 768px) {
        width: 350px;
        // height: auto;
      }
      .section-link {
        background-color: #FFFFFF;
        color: #007AFF;
        border: 1px solid #007AFF;
        margin: 20px auto;
        width: 250px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        // margin-top: 5px;
        line-height: 22px;
        text-align: left;
      }
    }
    .single-box {
      width: 500px;
      border: 1px solid #007AFF;
      margin: 50px auto;
      @media (max-width: 768px) {
        width: 350px;
        height: auto;
      }
      .single-box-header {
        background-color: #007AFF;
        color: #FFFFFF;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
      }
      .details-container {
        min-height: 120px;
        font-size: 18px;
        padding-top: 20px;
        color: #555555;
        .description {
          font-size: 16px;
          margin: 10px auto;
        }
        .section-link {
          background-color: #FFFFFF;
          color: #007AFF;
          border: 1px solid #007AFF;
          margin: 20px auto;
          width: 250px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 400;
          // margin-top: 5px;
          line-height: 22px;
          text-align: left;
        }
      }
    }
    .usage-container {
      height: 200px;
      width: 300px;
      margin: 10px auto;
      .header {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .tooltip-text {
          width: 12px;
          margin: 0px 5px;
          cursor: pointer;
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
      .usage-inner {
        display: flex;
        width: 300px;
        justify-content: center; //when showing no application icon
        margin: 20px auto;
        .application {
          width: 145px;
          text-align: left;
          .application-cells {
            .blue-user-icon {
              font-size: 40px;
              margin: 0px 5px;
              color: #007AFF;
            }
            .gray-user-icon {
              font-size: 40px;
              margin: 0px 5px;
              color: #E6E6E6;

            }
            .blue-user-icon {

            }
          }
          .application-text {
            margin-top: 10px;
            font-size: 11px;
          }
        }
        .divider {
          background-color: #D8D8D8;
          border: 1px solid #979797;
          width: 1px;
          margin: 0px 10px;
          height: 100px;
        }
        .usage {
          .days {
            // width: 145px;
            width: 182px; // when showing only days and no application icon
            .days-cell-container {
              // max-width: 145px;
              max-width: 182px; // when showing only days and no application icon
              display: flex;
              flex-wrap: wrap;
              .gray-cell {
                margin: 2px;
                // min-width: 16px;
                // min-height: 16px;
                min-height: 22px;
                min-width: 22px;
                background-color: #E6E6E6;
              }
              .blue-cell {
                margin: 2px;
                // min-width: 16px;
                // min-height: 16px;
                min-height: 22px;
                min-width: 22px;
                background-color: #007AFF;
              }
            }
            .days-text {
              margin-top: 10px;
              font-size: 11px;
            }
          }
        }
      }
    }
    .current-pack-container {
      .pack-header-container {
        display: flex;
        justify-content: space-between;
        .header {
          color: #666666;
          font-size: 10px;
          // border-bottom: 1px solid black;
          // padding-bottom: 2px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
        }
        .change-pack-button {
          border: 1px solid #007AFF;
          width: 105px;
          height: 26px;
          background-color: #FFFFFF;
          color: #007AFF;
          outline: none;
          cursor: pointer;
          font-size: 9px;
          font-weight: 700;
          letter-spacing: 0.29px;
          line-height: 11px;
          text-align: center;
        }
      }
      .pack-name {
        color: #4A4A4A;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }
    .section-container {
      margin: 25px 0px;
      text-align: left;
      .add-edit-button-description {
        font-size: 12px;
        margin: 5px 0px;
        text-align: left;
      }
      .section-header {
        color: #666666;
        font-size: 10px;
        font-weight: 400;
        // border-bottom: 1px solid black;
        // padding-bottom: 2px;
        line-height: 19px;
        text-align: left;
        margin-bottom: 5px;
      }
      .resubscribe-description-text {
        margin: 0px;
        margin-top: 5px;
      }
      .section-link {
        background-color: #007AFF;
        color: #FFFFFF;
        width: 250px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        // margin-top: 5px;
        line-height: 22px;
        text-align: left;
      }
    }
  }
}
