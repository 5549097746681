@charset "UTF-8";
.rbc-time-header {
  margin-right: 0 !important;
}

.add-interview-times-container {
  display: block;
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 180px;
}
.add-interview-times-container .BaseTable__row .BaseTable__row-cell-text {
  height: 100%;
}
@media (max-width: 1024px) {
  .add-interview-times-container {
    width: 100%;
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .add-interview-times-container {
    margin-left: 0px;
    position: fixed;
    height: -webkit-fill-available;
    overflow: auto;
    margin-top: 75px;
  }
}
.add-interview-times-container .add-interview-times-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}
@media (max-width: 375px) {
  .add-interview-times-container .add-interview-times-inner {
    width: 350px;
  }
}
.add-interview-times-container .add-interview-times-inner .sub-details {
  margin: 0px auto 20px;
  color: #007AFF;
  font-size: 18px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .sub-details {
    font-size: 15px;
  }
}
.add-interview-times-container .add-interview-times-inner .details-container {
  display: flex;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .details-container {
    width: 350px;
    justify-content: space-between;
  }
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date {
  width: 100px;
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date .today-text {
  background-color: gray;
  color: #FFFFFF;
  border-radius: 4px;
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date .date-text {
  font-size: 30px;
}
.add-interview-times-container .add-interview-times-inner .details-container .current-job-name {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-interview-times-container .add-interview-times-inner .details-container .current-job-name .text {
  color: #FFFFFF;
  background-color: gray;
  padding: 10px 20px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .details-container .current-job-name {
    width: 230px;
    font-size: 13px;
  }
}
.add-interview-times-container .add-interview-times-inner .mobile-add-section {
  display: none;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .mobile-add-section {
    width: 350px !important;
    display: flex !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-calendar {
    width: 350px !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event {
    padding: 0px !important;
    z-index: 10 !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event:hover {
    cursor: pointer;
    z-index: 9999 !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group {
    height: 20px !important;
    max-height: 20px !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group .rbc-time-slot {
    border-top: 0px;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover {
    z-index: 9;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover:hover {
    cursor: pointer;
    background: orange;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover:hover:before {
    content: "面接枠を入れる";
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event-label {
    display: none;
  }
}
.add-interview-times-container .add-interview-times-inner .add-section {
  width: 1100px;
  height: 73vh;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .add-section {
    display: none;
  }
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event {
  z-index: 10 !important;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event:hover {
  cursor: pointer;
  z-index: 9999 !important;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group {
  height: 20px !important;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group .rbc-time-slot {
  border-top: 0px;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover {
  z-index: 9;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover:hover {
  cursor: pointer;
  background: orange;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover:hover:before {
  content: "面接枠を入れる";
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event-label {
  display: none;
}