.error {
  border: 1px solid #e06e6f !important;
}

.react-select-container {
  border: 2px solid green;
  background-color: green;
}

.error-message-leave-space {
  min-height: 15px;
}
@media (max-width: 768px) {
  .error-message-leave-space {
    min-height: 30px;
  }
}

select {
  height: 40px;
  background-color: #FFFFFF;
  -webkit-appearance: menulist !important;
  padding: 3px;
  font-size: 14px;
  overflow: hidden;
}
select:focus {
  outline: none;
  border: 1px solid #007AFF;
}

.react-select__control {
  border: 2px solid green;
  background-color: green;
}

.multiline-select {
  width: 46%;
}

.select-container .error-message-container {
  color: #e06e6f;
  text-align: left;
  margin: 5px 0px;
  font-size: 10px;
}
.select-container .explanatory-text {
  color: #9B9B9B;
  text-align: left;
  margin: 5px 0px 0px;
  font-size: 10px;
}
.select-container .drop-list {
  width: 100%;
}
.select-container .drop-list .single-select {
  width: 100%;
  height: 40px;
  border: 1px solid #9B9B9B;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  padding: 3px;
  font-size: 14px;
  overflow: hidden;
}
.select-container .drop-list .single-select em {
  font-size: 14px;
  padding-left: 5px;
  font-style: normal;
}
.select-container .drop-list .single-select:before {
  border: none !important;
  border-bottom: none !important;
}
.select-container .drop-list .single-select:after {
  border: none !important;
  border-bottom: none !important;
}
.select-container .drop-list .single-select:focus {
  outline: none;
  border: 1px solid #007AFF !important;
}
.select-container .label-container {
  color: #9B9B9B;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin: 20px 0px 5px;
  position: relative;
}
@media (max-width: 768px) {
  .select-container .label-container {
    font-size: 11px !important;
  }
}
.select-container .label-container .mandatory-container {
  background-color: #ED5356;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -35px;
}
@media (max-width: 768px) {
  .select-container .label-container .mandatory-container {
    left: -30px;
  }
}
.select-container .label-container .optional-container {
  background-color: #007AFF;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -35px;
}
@media (max-width: 768px) {
  .select-container .label-container .optional-container {
    left: -30px;
  }
}
.select-container .label-container .tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .select-container .label-container .tooltip-text {
    display: none;
  }
}