.cell-container {
  width: 1024px;
  height: 166px;
  border-radius: 8px;
  background: #F5F5F5;
  border: 2px solid #B7B7B7;
  margin: 20px auto;
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
}
@media only screen and (max-width: 768px) {
  .cell-container {
    width: 416px;
    height: 221px;
    border: 1.5px solid #B7B7B7;
  }
}
.cell-container .label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  color: #555555;
}
@media only screen and (max-width: 768px) {
  .cell-container .label {
    font-size: 10px;
  }
}
.cell-container .value {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  color: #000000;
}
@media only screen and (max-width: 768px) {
  .cell-container .value {
    font-size: 14px;
  }
}
.cell-container .plan-name-container {
  height: 20px;
  top: 16.98px;
  left: 8.77px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .plan-name-container {
    top: 12.98px;
  }
}
.cell-container .plan-name-container .plan-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  color: #08105D;
}
@media only screen and (max-width: 768px) {
  .cell-container .plan-name-container .plan-name {
    font-size: 18px;
  }
}
.cell-container .transaction-date-container {
  height: 20px;
  top: 53.69px;
  left: 8.77px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .transaction-date-container {
    top: 41.69px;
  }
}
.cell-container .transaction-date-container .transaction-date {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  color: #000000;
}
@media only screen and (max-width: 768px) {
  .cell-container .transaction-date-container .transaction-date {
    font-size: 14px;
  }
}
.cell-container .transaction-id-label-container {
  height: 20px;
  top: 102.39px;
  left: 8.77px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .transaction-id-label-container {
    top: 74.67px;
    left: 8.19px;
  }
}
.cell-container .transaction-id-container {
  height: 20px;
  top: 122.39px;
  left: 8.77px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .transaction-id-container {
    top: 93.66px;
  }
}
.cell-container .pre-tax-amount-label-container {
  height: 20px;
  top: 102.39px;
  left: 348.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .pre-tax-amount-label-container {
    top: 74.67px;
    left: 271.81px;
  }
}
.cell-container .pre-tax-amount-container {
  height: 20px;
  top: 122.39px;
  left: 348.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .pre-tax-amount-container {
    top: 93.66px;
    left: 271.81px;
  }
}
.cell-container .tax-amount-label-container {
  height: 20px;
  top: 102.39px;
  left: 499.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .tax-amount-label-container {
    top: 123.25px;
    left: 8.19px;
  }
}
.cell-container .tax-amount-container {
  height: 20px;
  top: 122.39px;
  left: 499.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .tax-amount-container {
    top: 139.23px;
    left: 8.19px;
  }
}
.cell-container .total-amount-label-container {
  height: 20px;
  top: 102.39px;
  left: 629.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .total-amount-label-container {
    top: 121.75px;
    left: 271.81px;
  }
}
.cell-container .total-amount-container {
  height: 20px;
  top: 122.39px;
  left: 629.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .total-amount-container {
    top: 140.73px;
    left: 271.81px;
  }
}
.cell-container .cycle-duration-label-container {
  height: 20px;
  top: 102.39px;
  left: 768.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .cycle-duration-label-container {
    top: 170.62px;
    left: 8.19px;
  }
}
.cell-container .cycle-duration-container {
  height: 20px;
  top: 122.39px;
  left: 768.43px;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .cell-container .cycle-duration-container {
    top: 189.61px;
    left: 8.19px;
  }
}
.cell-container .download-invoice-button-container {
  display: inline-flex;
  height: 44px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  top: 8.98px;
  right: 8.77px;
  position: absolute;
  border-radius: 8px;
  border: 2px solid #FFBE00;
  background: #FFFFFF;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .cell-container .download-invoice-button-container {
    top: 171.61px;
    left: 352px;
    height: 40px;
    padding: 8px 16px;
  }
}
.cell-container .download-invoice-button-container .download-invoice-button {
  width: 28px;
  height: 28px;
}
@media only screen and (max-width: 768px) {
  .cell-container .download-invoice-button-container .download-invoice-button {
    width: 24px;
    height: 24px;
  }
}