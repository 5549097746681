.remove-navbar-expand-md {
  display: none !important;
}

.remove-footer {
  display: none !important;
}

.remove-sidebar-main {
  display: block;
}
@media (max-width: 768px) {
  .remove-sidebar-main {
    display: none;
  }
}

.remove-fixed {
  position: static !important;
}

.sidebar-container {
  background-color: #253242;
  width: 220px;
  position: fixed !important;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 1;
}
@media (max-width: 768px) {
  .sidebar-container {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .sidebar-container {
    width: 375px;
    height: 100%;
  }
}
.sidebar-container .sidebar-inner .sidebar-logo-container {
  height: 75px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.18);
}
@media (max-width: 1024px) {
  .sidebar-container .sidebar-inner .sidebar-logo-container {
    padding-left: 10px;
  }
}
.sidebar-container .sidebar-inner .sidebar-logo-container .sidebar-logo {
  position: relative;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-logo-container .sidebar-logo {
    padding-left: 0px;
    width: 100%;
  }
}
.sidebar-container .sidebar-inner .sidebar-logo-container .sidebar-logo .icon {
  font-size: 14px;
  color: #FFFFFF;
  display: none;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-logo-container .sidebar-logo .icon {
    display: block;
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
.sidebar-container .sidebar-inner .sidebar-options-container {
  margin-top: 30px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .notification-redirect {
  background-color: #FE7374;
  color: #FFFFFF;
  border-radius: 20px;
  height: 40px;
  font-size: 14px;
  width: auto;
  cursor: pointer;
  width: 210px;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  display: flex;
  margin-bottom: 15px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container {
  color: #FFFFFF;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container:hover {
  color: #253242;
  border: 1px solid #253242;
  background-color: #FFFFFF;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .icon:hover {
  color: #253242 !important;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container:hover div {
  display: flex;
  background-color: #FFFFFF;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-parent {
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 48px;
  height: 48px;
  text-align: left;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-parent:hover {
  color: #253242;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-parent {
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
  }
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-parent .icon {
  font-size: 14px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-parent .options {
  padding-left: 10px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-child {
  display: none;
  color: #253242;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 48px;
  height: 48px;
  text-align: left;
  padding-left: 40px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-child {
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
  }
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-child .icon {
  font-size: 14px;
  color: #253242;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1-special-container .level-1-hover-child .options {
  padding-left: 10px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .icon-container {
  float: right;
  margin-right: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #FFF;
  height: 24px;
  padding: 3px 6px;
  font-size: 12px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .icon-container * {
  height: 14px;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1 {
  color: #f0f8fe;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 48px;
  height: 48px;
  text-align: left;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1:hover {
  background-color: #33465C;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-options-container .level-1 {
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
  }
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1 .icon {
  font-size: 14px;
  color: #FFFFFF;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1 .options {
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1 .level-2 {
  color: #FFFFFF;
  line-height: 24px;
  font-size: 13px;
  margin: 5px 0px;
  font-weight: 100;
  letter-spacing: 0.88px;
  padding-left: 14px;
}
@media (max-width: 768px) {
  .sidebar-container .sidebar-inner .sidebar-options-container .level-1 .level-2 {
    font-size: 18px;
    margin: 10px 0px;
    padding-left: 40px;
  }
}
.sidebar-container .sidebar-inner .sidebar-options-container .level-1 .level-2 .level-3 {
  margin: 10px 0px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}