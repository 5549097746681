.company-details-part5-container {
  margin: 0px auto;
  width: 400px;
  @media (max-width: 768px) {
    width: 300px;
  }
  .main-detail {
    color: #000000;
    font-size: 19px;
    font-weight: bold;
    margin: 30px auto;
    line-height: 23px;
    opacity: 0.80;
    width: 400px;
    text-align: center;
    @media (max-width: 768px) {
      width: 300px;
      font-size: 14px;
      margin: 20px auto;
    }
  }
  .sub-detail {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    opacity: 0.80;
    text-align: center;
    @media (max-width: 768px) {
      width: 300px;
      font-size: 13px;
    }
  }
}
