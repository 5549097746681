.error-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px!important;
  @media (max-width: 768px) {
    height: 450px;
    margin-left: 0!important;
  }
  .dialog-title {
    text-align: center;
    padding: 10px;
    background-color: #ED5356;
    height: 45px;
    // width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .main-close-icon {
      cursor: pointer;
    }
  }
  .dialog-message-container {
    text-align: center;
    // min-height: 290px;
    min-width: 400px;
    // max-width: 400px;
    overflow: hidden;
    @media (max-width: 768px) {
      max-width: 265px;
      min-width: 265px;
      // height: 225px;
      // max-height: 225px;
    }
    .ok-button {
      border: 2px solid #ED5356;
      color: #ED5356;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 20px auto;
    }
    .special-error-message {
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
    .close-icon-center {
      // margin: 10px auto;
      .error-modal-header-text {
        font-size: 35px;
        color: #ED5356;
      }
    }
  }
}
