.error {
  border: 2px solid #E45353 !important;
  background: #FFF8F8 !important;
}

.error-message-leave-space {
  min-height: 15px;
}

.react-autosuggest__container .react-autosuggest__input {
  width: 100%;
  outline: none;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px;
}
.react-autosuggest__container .react-autosuggest__input:focus {
  outline: 1px solid #388bee;
  border: 1px solid #388bee;
}
.react-autosuggest__container .react-autosuggest__suggestions-container--open {
  border: 1px solid #9B9B9B;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}
.react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestions-list {
  list-style-type: none;
  padding: 10px;
}

.single-input-multiline {
  width: 48%;
}

.special-mobile-container {
  display: flex;
}
.special-mobile-container .single-input-special-left {
  width: 25px;
  padding-left: 8px;
  background-color: #DCDCDC;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  outline: none;
  border: 1px solid #9B9B9B;
}
.special-mobile-container .single-input-special-right {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  outline: none;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  font-size: 14px;
  padding: 10px;
}

.salary-input-container {
  display: flex;
}
.salary-input-container input {
  width: 88% !important;
}
@media (max-width: 1024px) {
  .salary-input-container input {
    width: 80% !important;
  }
}
.salary-input-container span {
  line-height: 35px;
}

.single-input-container .single-input {
  outline: none;
  display: block;
  box-sizing: border-box;
  border: none;
  background: #F2F3F7;
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}
.single-input-container .single-input:focus {
  outline: 1px solid #388bee;
  border: 1px solid #388bee;
}
.single-input-container .valid-input {
  border: 2px solid #3ac430 !important;
  background: #f8fff8 !important;
}
.single-input-container .valid-input:focus {
  border: 1px solid #388bee !important;
  background: #F2F3F7 !important;
}
.single-input-container .error-message-container {
  text-align: left;
  margin: 5px 0px;
  color: #E45353;
  font-size: 12px;
  font-family: Noto Sans JP;
  line-height: 20px;
}
.single-input-container .explanatory-text {
  color: #888;
  font-size: 11px;
  font-family: Noto Sans JP;
  line-height: 20px;
  text-align: left;
  margin: 5px 0px 0px;
}
.single-input-container .special-explanatory-text {
  color: #007AFF;
  text-align: left;
  margin: 5px 0px 0px;
  font-size: 10px;
}
.single-input-container .label-container {
  color: #555;
  font-size: 12px;
  font-family: Noto Sans JP;
  line-height: 20px;
  text-align: left;
  margin: 20px 0px 5px;
  position: relative;
}
@media (max-width: 768px) {
  .single-input-container .label-container {
    font-size: 11px;
  }
}
.single-input-container .label-container .mandatory-container {
  border-radius: 2px;
  background: #33A8ED;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  left: 0;
  position: relative;
  color: #FFF;
  font-size: 12px;
  font-family: Noto Sans JP;
  line-height: 18px;
  gap: 8px;
}
.single-input-container .label-container .optional-container {
  background-color: #007AFF;
  color: #FFFFFF;
  font-size: 9px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  left: 0;
  position: relative;
}
.single-input-container .label-container .tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .single-input-container .label-container .tooltip-text {
    display: none;
  }
}
.single-input-container .label-container .action-button {
  color: #007AFF;
  position: absolute;
  right: 10px;
  cursor: pointer;
}