.drawer-styles {
  width: 100%;
  height: 100%;
}

.team-landing-container {
  margin: 160px auto;
  width: 100%;
  margin-left: 220px;
  width: calc(100% - 220px);
  position: relative;
}
@media (max-width: 768px) {
  .team-landing-container {
    margin: 140px auto;
    width: 100%;
  }
}
.team-landing-container .add-member-button {
  z-index: 99;
  background-color: #007AFF;
  width: 100px;
  height: 100px;
  position: fixed;
  right: 10px;
  bottom: 100px;
  color: #FFFFFF;
  border-radius: 60px;
  margin: 20px 0px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
}
@media (max-width: 768px) {
  .team-landing-container .add-member-button {
    width: 100px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.team-landing-container .guide-container {
  margin: 10px auto;
  width: 803px;
}
@media (max-width: 768px) {
  .team-landing-container .guide-container {
    margin: 15px;
    width: 350px;
  }
}
.team-landing-container .guide-container .guide {
  width: 100px;
  outline: none;
  background-color: #007AFF;
  color: #FFFFFF;
  cursor: pointer;
}
.team-landing-container .add-team-member-form-background {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6666666667);
  justify-content: center;
  align-items: center;
}
.team-landing-container .add-team-member-form-background .add-team-member-form-container {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #E9E9E9;
  width: 500px;
  padding: 12px 100px 32px;
  margin-left: 220px;
}
@media (max-width: 768px) {
  .team-landing-container .add-team-member-form-background .add-team-member-form-container {
    width: 350px;
    padding: 6px 20px 16px;
    margin-left: 0;
  }
}
.team-landing-container .add-team-member-form-background .add-team-member-form-container .submit-container {
  display: flex;
  width: 300px;
  margin: 20px auto;
  justify-content: space-between;
}

.snackbar-details-icon .MuiSnackbarContent-root {
  font-family: "Noto Sans JP" !important;
  padding-top: 24px;
  padding-bottom: 24px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 2px solid #ECECEC;
  background: #FFF;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
  width: 625px;
}
@media (max-width: 1024px) {
  .snackbar-details-icon .MuiSnackbarContent-root {
    width: 396px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #ECECEC;
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: flex-start;
  }
  .snackbar-details-icon .MuiSnackbarContent-root img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 4px !important;
  }
}
.snackbar-details-icon .MuiSnackbarContent-message {
  padding: 0;
}