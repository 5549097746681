.register-page-validation-tooltip-container {
  height: 150px;
}
.register-page-validation-tooltip-container .validation-header {
  height: 30px;
  border-bottom: 1px solid #D0021B;
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.register-page-validation-tooltip-container .validation-inner-container {
  margin: 0px auto;
  padding: 5px;
  width: 120px;
}
.register-page-validation-tooltip-container .validation-inner-container .single-validation {
  font-size: 14px;
}

@media (max-width: 768px) {
  .layer-popover {
    display: none;
  }
}

.login-modal-container {
  outline: none;
  width: 500px;
  margin: 100px auto;
}
@media (max-width: 1024px) {
  .login-modal-container {
    width: 450px;
  }
}
@media (max-width: 768px) {
  .login-modal-container {
    width: 100%;
  }
}
.login-modal-container .login-modal-inner {
  background-color: #FFFFFF;
}
.login-modal-container .login-modal-inner .responsive-header {
  height: 50px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login-modal-container .login-modal-inner .responsive-header .login-header-text {
  font-size: 25px;
  color: #484848;
}
.login-modal-container .login-modal-inner .responsive-header .burger {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.login-modal-container .login-modal-inner .login-inner-modal {
  display: block;
  width: 350px;
  padding: 10px;
  margin: 0px auto;
}
.login-modal-container .login-modal-inner .login-inner-modal .alert-danger {
  height: 40px;
  color: #ed5356;
  width: 100%;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  max-width: 500px;
  padding: 10px 0px;
  margin: 10px auto !important;
  color: #ed5356 !important;
  background-color: transparent !important;
}
.login-modal-container .login-modal-inner .login-inner-modal .submit-button-container {
  margin: 20px 0px;
}
.login-modal-container .login-modal-inner .login-inner-modal .submit-button-container .forgot-password-text {
  font-size: 13px;
  text-align: center;
  padding: 10px;
}

.register-page-container {
  width: 100%;
  height: 100%;
  padding: 50px 0px;
}
.register-page-container .login-text-container {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 500px;
  background-color: #FFFFFF;
  padding: 10px;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  border-radius: 5px;
}
.register-page-container .login-text-container button {
  margin: 10px auto;
}
@media (max-width: 1024px) {
  .register-page-container .login-text-container {
    width: 350px;
  }
}
@media (max-width: 768px) {
  .register-page-container .login-text-container {
    width: 350px;
    font-size: 12px;
  }
  .register-page-container .login-text-container button {
    min-width: 200px !important;
    margin: 20px auto;
  }
}
.register-page-container .register-form-container {
  margin: 0px auto;
  padding: 20px;
  width: 500px;
  min-height: 300px;
  background-color: #FFFFFF;
  -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow: 3px 3px 5px 6px #ccc;
  border-radius: 5px;
}
@media (max-width: 1024px) {
  .register-page-container .register-form-container {
    width: 350px;
  }
}
@media (max-width: 768px) {
  .register-page-container .register-form-container {
    width: 350px;
  }
}
.register-page-container .register-form-container .register-page-header-container {
  height: 150px;
  border-bottom: 1px solid #007AFF;
}
.register-page-container .register-form-container .register-page-header-container .header {
  color: #2C2C2C;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  width: 100%;
  text-align: center;
  margin: 10px 0px;
}
@media (max-width: 768px) {
  .register-page-container .register-form-container .register-page-header-container .header {
    font-size: 20px;
  }
}
.register-page-container .register-form-container .register-page-header-container .sub-header {
  color: #2C2C2C;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  opacity: 0.801827567;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .register-page-container .register-form-container .register-page-header-container .sub-header {
    font-size: 15px;
  }
}
.register-page-container .register-form-container .form-fields {
  width: 80%;
  margin: 10px auto;
}
.register-page-container .register-form-container .terms-and-conditions {
  text-align: center;
}
.register-page-container .register-form-container .terms-and-conditions label {
  margin: 0px;
}
.register-page-container .register-form-container .register-submit-button {
  background-color: #007AFF;
  border-radius: 27px;
  width: 250px;
  height: 47px;
  display: flex;
  justify-content: center;
  margin: 0px auto 10px;
  cursor: pointer;
  outline: none;
  align-items: center;
  color: #FFFFFF;
}