.register-page-validation-tooltip-container {
  height: auto;
  .validation-header {
    height: 30px;
    border-bottom: 1px solid #d0021b;
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .validation-inner-container {
    float: left;
    margin: 0px auto;
    padding: 5px;
    color: #888;
    font-size: 12px;
    font-family: Noto Sans JP;
    line-height: 20px;
  }
}

.layer-popover {
  @media (max-width: 768px) {
    display: none;
  }
}

.register-page-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-bottom: 25px;
  .login-text-container {
    .login-title {
      border-radius: 8px 8px 0px 0px;
      background: #33A8ED;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-family: Noto Sans JP;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: 0.72px;

      @media (min-width: 1024px) {
        font-weight: 400;
      }
    }

    .login-main-container {
      .login-text {
        color: #555;
        text-align: center;
        font-size: 16px;
        font-family: Noto Sans JP;
        line-height: 24px;
        letter-spacing: 0.32px;
      }

      display: flex;
      padding: 12px 56px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      @media (max-width: 350px) {
        padding: 12px 16px;
      }
    }

    display: block;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 500px;
    padding: 0;
    background-color: #ffffff;
    -moz-box-shadow: 3px 3px 12px 3px #ccc;
    -webkit-box-shadow: 3px 3px 12px 3px #ccc;
    box-shadow: 3px 3px 12px 3px #ccc;
    border-radius: 8px;
    button {
      display: flex;
      padding: 8px 64px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 24px;
      border-style: none;
      background: #33A8ED;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans JP;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.32px;
    }

    @media (max-width: 1024px) {
      width: 350px;
    }

    @media (max-width: 768px) {
      width: 350px;
      font-size: 12px;
      button {
        min-width: 200px !important;
      }
    }
    @media (max-width: 350px) {
      width: 95%;
    }
  }
  .login-modal-container {
    outline: none;
    width: 500px;
    margin: 100px auto;

    .login-modal-inner {
      .responsive-header {
        border-radius: 8px 8px 0px 0px;
        background: #33A8ED !important;
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-family: Noto Sans JP;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0.72px;
        @media (min-width: 1024px) {
          font-weight: 400;
        }
      }
    }
    @media (max-width: 1024px) {
      width: 450px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .login-modal-inner {
      // height: 300px;
      background-color: #ffffff;
      // padding: 30px;

      .responsive-header {
        border-radius: 8px 8px 0px 0px;
        background: #33A8ED !important;
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-family: Noto Sans JP;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0.72px;
        @media (min-width: 1024px) {
          font-weight: 400;
        }
        .login-header-text {
          line-height: 56px;
          font-size: 25px;
          color: #484848;
        }
        .burger {
          position: absolute;
          cursor: pointer;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .login-inner-modal {
        display: block;
        width: 350px;
        padding: 10px;
        margin: 0px auto;
        .alert-danger {
          height: 40px;
          color: #ed5356;
          width: 100%;
          font-size: 12px;
          text-align: center;
          background-color: transparent;
          max-width: 500px;
          padding: 10px 0px;
          margin: 10px auto !important;
          color: #ed5356 !important;
          background-color: transparent !important;
        }
        .submit-button-container {
          margin: 20px 0px;
          width: 100%;
          .forgot-password-text {
            font-size: 13px;
            text-align: center;
            padding: 10px;
          }
        }
      }
    }
  }
  .register-form-container {
    margin: 0px auto;
    padding: 0;
    width: 500px;
    min-height: 300px;
    background-color: #ffffff;
    -moz-box-shadow: 3px 3px 12px 3px #ccc;
    -webkit-box-shadow: 3px 3px 12px 3px #ccc;
    box-shadow: 3px 3px 12px 3px #ccc;
    border-radius: 8px;

    @media (max-width: 1024px) {
      width: 350px;
    }

    @media (max-width: 350px) {
      width: 95%;
    }

    .register-form-title {
      border-radius: 8px 8px 0px 0px;
      background: #33A8ED;
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-family: Noto Sans JP;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: 0.72px;
      @media (min-width: 1024px) {
        font-weight: 400;
      }
    }

    .register-form-main-container {
      display: flex;
      padding: 12px 56px;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      @media (max-width: 1024px) {
        padding: 12px 16px;
      }

      .register-form-top-text {
        color: #555;
        text-align: center;
        font-size: 14px;
        font-family: Noto Sans JP;
        line-height: 24px;
      }

      .register-steps {
        border-radius: 8px;
        border: 1px solid #33A8ED;
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .register-steps-title {
          border-bottom: 2px solid #33A8ED;
          color: #33A8ED;
          text-align: center;
          font-size: 16px;
          font-family: Noto Sans JP;
          font-weight: 500;
          width: fit-content;
          padding: 10px 0px 0px;
          @media (min-width: 1024px) {
            font-weight: 400;
          }
        }

        .flow-steps-container {
          display: flex;
          justify-content: space-between;
          padding: 3px 24px;
          width: 100%;
          align-items: stretch;

          @media (max-width: 1024px) {
            padding: 3px 12px;
          }

          .flow-step {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 12px 0px 3px 0px;

            .step-image {
            }

            .step-title {
              color: #555;
              text-align: center;
              font-size: 14px;
              font-family: Noto Sans JP;
              font-weight: 500;
              line-height: 16px;

              @media (max-width: 1024px) {
                font-size: 13px;
              }
            }
          }

          .flow-arrow {
          }
        }

        .register-steps-subtitle {
          color: #888;
          text-align: center;
          font-size: 13px;
          font-family: Noto Sans JP;
          line-height: 20px;
          padding: 10px 0px;
        }

        .register-steps-call {
          color: #33A8ED;
          text-align: center;
          font-size: 15px;
          font-family: Inter;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.3px;
          padding: 0px 0px 10px;
        }
      }

      .form-fields {
        width: 100%;
        margin: 10px 0;
      }
      .terms-and-conditions {
        text-align: left;
        width: 100%;
        font-size: 16px;
        font-family: Noto Sans JP;
        font-weight: 100;
        line-height: 40px;
        letter-spacing: 0.32px;
        color: #555555;
        label {
          margin: 0px;
          margin-right: 6px;
        }
        a {
          color: #33A8ED;
          text-decoration-line: underline;
          text-underline-offset: 3px;
          text-underline-position: above;
        }
        margin-bottom: 16px;
      }
      .register-submit-button {
        background-color: #33A8ED;
        border-radius: 24px;
        justify-content: center;
        margin: 0px auto 10px;
        cursor: pointer;
        outline: none;
        border: none;
        display: flex;
        max-width: 320px;
        width: 95%;
        padding: 12px 64px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: #fff;
        text-align: center;
        font-size: 18px;
        font-family: Noto Sans JP;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.36px;
        &:hover {
          background-color: #33A8ED;
        }
      }

      .disabled-register {
        background-color: #888;
        &:hover {
          background-color: #888;
        }
      }
    }
  }
}
