.company-details-part2-container .company-details-part2-form {
  width: 375px;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .company-details-part2-container .company-details-part2-form {
    width: 300px;
  }
}
.company-details-part2-container .company-details-part2-form .single-form-element {
  margin: 15px auto 5px;
}
.company-details-part2-container .company-details-part2-form .is-dispatch-agency-container .dispatch-agency-text {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: left;
  margin: 10px 0px;
}
.company-details-part2-container .company-details-part2-form .is-dispatch-agency-container .dispatch-agency-values .dispatch-agency-single {
  margin: 10px 0px;
  display: flex;
}
.company-details-part2-container .company-details-part2-form .is-dispatch-agency-container .dispatch-agency-values .dispatch-agency-single input {
  color: #007AFF;
}
.company-details-part2-container .company-details-part2-form .is-dispatch-agency-container .dispatch-agency-values .dispatch-agency-single .text {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 10px;
}
.company-details-part2-container .company-details-part2-form .street-address-container .street-address-header {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-top: 50px;
}