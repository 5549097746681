
.snackbar-filter {
  top: 320px !important;
  left: calc(50% + 110px) !important;
  @media (max-width: 1200px) { 
    top: 380px !important;
  }
  @media (max-width: 920px) { 
    top: 440px !important;
  }
  @media (max-width: 768px) { 
    top: 350px !important;
    left: 50% !important;
    transform: translateX(-50%);
    width: calc(100% - 16px);
  }
  @media (max-width: 370px) { 
    top: 400px !important;
  }
  .MuiSnackbarContent-root {
    font-family: 'Noto Sans JP'!important;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #ECECEC;
    background: #FFF;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
    height: 73px;
    width: 492px;

    @media (max-width: 1024px) {
      height: 50px;
      width: 306px;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #ECECEC;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.filter-bar-box {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0 auto;
    z-index: 99;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #FFF;
    padding-top: 12px;

    .filter-info-text {
      color: #000;
      font-family: "Noto Sans JP";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-align: left;
      width: 100%;
      padding: 4px 20px;
      @media (max-width: 768px) { 
        font-size: 10px;
      }
    }

    .filter-bar-type-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5px;
        width: -webkit-fill-available;

        .filter-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 8px 37px;
            flex-wrap: nowrap;
            position: relative;
            cursor: pointer;
            border-bottom: 1px solid #ACACAC;
            flex: 1;

            .filter-name {
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Noto Sans JP';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
                letter-spacing: -0.176px;
                text-wrap: nowrap;
            }

            .filter-count {
                color: #FFF;
                font-family: "Noto Sans JP";
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 10px;
                letter-spacing: -0.11px;

                background: #33A8ED;
                border-radius: 20px;
                width: 20px;
                height: 20px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .filter-type-highlighter {
                height: 3px;
                border-radius: 6px;
                background: #33A8ED;
                position: absolute;
                bottom: -1.5px;
                left: 0;
                width: 100%;
            }
        }
    }

    .filter-detail-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin: 4px 20px 0;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 16px;
      border-bottom: 1px solid #ACACAC;
      padding-bottom: 16px;

      @media (max-width: 1300px) {
        flex-direction: column;
        margin: 4px 16px;
      }
      
      .filter-dropdown-container {
        display: flex;
        flex-direction: row;
        gap: 16px;

        @media (max-width: 1300px) { 
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .filter-dropdown {
          padding: 8px 12px;
          display: flex;
          flex-direction: row;
          position: relative;
          align-items: center;
          justify-content: space-between;
          border-radius: 8px;
          border: 1px solid #E9E9E9;
          background: #FFF;
          width: 136px;
          flex-wrap: nowrap;
          min-width: 136px;
          cursor: pointer;

          &:hover {
            background: #E9E9E9;
          }

          @media (max-width: 768px) { 
            padding: 6px 8px;
            width: 117px;
          }

          @media (max-width: 500px) { 
            width: 100%!important;
            max-width: unset!important;
          }

          &__large {
            @extend .filter-dropdown;
            min-width: 376px;
            width: -webkit-fill-available;
            max-width: 376px;
            @media (max-width: 1500px) {
              min-width: 307px;
              max-width: 307px;
            }
            @media (max-width: 1410px) {
              min-width: 202px;
              max-width: 202px;
            }
            @media (max-width: 1300px) {
              min-width: 307px;
              max-width: 307px;
              width: unset;
            }

            @media (max-width: 400px) {
              min-width: unset;
            }
          }
          
          &__disabled {
            @extend .filter-dropdown;
            &:hover {
              background: #FFF;
              cursor: default;
            }
          }

          &__filled {
            @extend .filter-dropdown;
            border: 1px solid #A5DEFF;
            background: #A5DEFF;
            &:hover {
              background: #A5DEFF;
            }
          }

          .filter-dropdown-count {

          }

          .filter-dropdown-text {
            color: #000;
            font-family: "Noto Sans JP";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;

            @media (max-width: 768px) { 
            
            }

            &__disabled {
              @extend .filter-dropdown-text;
              color: #B8B8B8;
            }
          }

          .filter-dropdown-arrow {

            &__open {
              @extend .filter-dropdown-arrow;
            }

            &__disabled {
              @extend .filter-dropdown-arrow;
            }
  
            &__filled {
              @extend .filter-dropdown-arrow;
            }
          }

          @keyframes smooth-appear {
            to{
              opacity: 1;
            }
          }

          .filter-dropdown-menu {
            position: absolute;
            top: 100%;
            width: 100%;
            left: 0;
            opacity: 0;
            background: #FFF;
            animation: smooth-appear 0.5s ease-out forwards;
            .filter-drop-down-main {
              background: #FFF;
              width: 100%;
              margin-top: 4px;
              border-radius: 8px;
              box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.15), 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
              
              .os-scrollbar {
                bottom: 14px;
                width: 12px;
                .os-scrollbar-track{
                  background-color: #A4A4A4;
                  width: 2px;
                  .os-scrollbar-handle {
                    background-color: #1A9EFF;
                    width: 4px;
                    left: -1px;
                  }
                }
    
              }
            }
          }


          .filter-item1 {
            padding: 6px 10px;
            width: 100%;
            text-align: left;
            cursor: pointer;
            color: #202020;
            font-family: "Noto Sans JP";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:nth-child(even) {
              background-color: #F3F3F3;
            }
          }

          .search-box-container {
            padding: 8px;
            cursor: default;
            .search-box {
              border-radius: 28px;
              background: #F1F1F1;
              height: 40px;
              width: -webkit-fill-available;
              display: flex;
              padding: 8px 12px;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              cursor: default;
  
              .search-box-input {
                width: 100%;
                color: #000;
                font-family: "Noto Sans JP";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                outline: none;
                border: none;
                &:focus {
                  outline: none;
                  border: none;
                }
                &::placeholder {
                  color: #49454F;
                  opacity: 1;
                }
              }
  
              .search-box-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
          }
          

          .filter-options {
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
          }

          .filter-item2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            width: -webkit-fill-available;
            padding: 12px 0;
            margin: 0px 8px;
            border-bottom: 1px solid #ACACAC;
            text-align: left;

            &:last-child {
              border-bottom: none;
            }

            .checkbox {
              min-width: 16px;
              min-height: 16px;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              border: 2px solid #ACACAC;
              margin-right: 8px;
            }

            .checkbox-on {
              min-width: 16px;
              min-height: 16px;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              margin-right: 8px;
            }

            .value {
              text-align: left;
              line-break: anywhere;
              text-wrap: wrap;
              color: #000;
              font-family: "Noto Sans JP";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-wrap: wrap;
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 2;
              // overflow: hidden;
            }
          }
        }
      }

      .filter-action-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        flex: 1;

        @media (max-width: 1200px) { 
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .filter-action-box {
          gap: 16px;
          display: flex;
          flex-direction: row;

          @media (max-width: 920px) { 
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          @media (max-width: 768px) { 
            width: unset;
          }

          &__save {
            @extend .filter-action-box;
            flex: 1;
            justify-content: center;

            @media (max-width: 1300px) { 
              justify-content: unset;
              flex: unset;
            }
          }

          .filter-action-button {
            padding: 8px 12px;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            gap: 8px;
            border: 2px solid #33A8ED;
            color: #33A8ED;
            cursor: pointer;

            @media (max-width: 920px) { 
              flex: 1;
            }
            
            &:hover {
              background-color: #33A8ED20;
            }

            &__disabled {
              @extend .filter-action-button;
              border: 2px solid #B8B8B8;
              color: #B8B8B8;
              cursor: default;

              &:hover {
                background-color: #FFF;
              }
            }

            &__filled {
              @extend .filter-action-button;
              border: 2px solid #33A8ED;
              background: #33A8ED;
              color: #FFF;
              cursor: default;

              &:hover {
                background: #33A8ED;
              }
            }

            .filter-action-icon {
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
            }

            .filter-action-text {
              text-align: center;
              font-family: "Noto Sans JP";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-wrap: nowrap;

              @media (max-width: 768px) { 
                display: none;
              }
            }
          }
        }
      }
    }
}

.embla {
    width: 100%;
    padding: 0px 20px;
    position: relative;
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(1rem) * -1);
  }
  .embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    
  }
  .embla__buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
  }
  .embla__button {
    cursor: pointer;
    pointer-events: all;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4.5px;
    background: linear-gradient(270deg, #FFF 33.33%, rgba(255, 255, 255, 0.00) 104.76%);
  }
  .embla__arrow-left-container {
    background: linear-gradient(90deg, #FFF 33.33%, rgba(255, 255, 255, 0.00) 104.76%);
  }

  .embla__arrow {
    padding: 0px 0px 0px 20px;
  }

  .arrow_left {
    transform: scaleX(-1);
    padding: 0px 0px 0px 20px;
  }