.arrows {
  opacity: 1 !important;
}
.preview-jobs-container {
  font-size: 14px;
  text-align: left;
  // border: 4px solid #007AFF;
  margin: 20px auto;
  padding: 10px;
  .redirect-button-container {
    display: flex;
    width: 375px;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 330px;
    }
    button {
      background-color: #FFFFFF;
      border: 1px solid #007AFF;
      border-radius: 12px;
      min-width: 75px;
      height: 46px;
      color: #007AFF;
    }
  }
  .main-header {
    font-size: 21px;
    font-weight: bold;
    //font-weight: 400;
    letter-spacing: 1.38px;
    line-height: 25px;
    width: 317px;
    text-align: left;
    margin: 15px 0 0;
  }
  .inner-label {
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 23px;
    width: 317px;
    text-align: left;
    margin: 15px 0 0;
  }
  .value {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.05px;
    line-height: 19px;
    width: 317px;
    text-align: left;
    margin-bottom: 15px;
  }
  .brand-name {
    width: 100%;
    // height: 40px;
    text-align: left;
  }
  .salary-time-container {
    width: 100%;
    border-top: 1px solid gray;
    // border-bottom: 1px solid gray;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 10px;
    .label {
      width: 50%;
      text-align: left;
      color: gray;
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
  .location-container {
    width: 100%;
    min-height: 40px;
    padding: 5px 0px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    font-size: 10px;
    .label {
      width: 50%;
      text-align: left;
      color: gray;
      .tooltip-text {
        width: 12px !important;
        margin-bottom: -2px;
      }
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
  .heading {
    width: 100%;
    margin: 20px 0px 10px;
    text-align: left;
  }
  .sub-heading {
    width: 100%;
    text-align: left;
    margin: 20px 0px 10px;
    font-size: 14px;
  }
  .requirement-value {
    width: 100%;
    text-align: left;
    height: 40px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    font-size: 10px;
  }
  .work-images {
    width: 100%;
    .control-arrow {
      opacity: 1;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .merits-container {
    .single-merit-container {
      border-top: 1px solid gray;
      width: 100%;
      min-height: 40px;
      font-size: 10px;
      display: flex;
      align-items: center;
      .value {
        display: block;
        text-align: left;
      }
      .label {
        width: 50%;
        color: gray;
        text-align: left;
      }
    }
  }
  .work-hours-container {
    font-size: 10px;
    text-align: left;
    .single-time {
      display: flex;
      border-top: 1px solid gray;
      .label {
        // color: gray;
        width: 30%;
        text-align: left;
      }
      .value {
        width: 70%;
        text-align: left;
        display: flex;
        .single-day-container {
          flex: 1;
          .day-name {
            text-align: center;
            color: gray;
          }
          .day-present {
            text-align: center;
          }
        }

      }
    }
  }
  .training-container {
    width: 100%;
    height: 40px;
    font-size: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid gray;
    .label {
      width: 50%;
      color: gray;
      text-align: left;
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
}

.redirect-button {
  background-color: #fff;
  border: 1px solid #007aff;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #007aff;
}

.preview-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #aaaaaa66;
  backdrop-filter: blur(2px);
  z-index: 999;
}

.preview-popup-box {
  width: 500px;
  height: 834px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  background-color: #FFF;
  margin-left: 220px;
  max-height: calc(100% - 115px);
  margin-top: 50px;
  @media (max-width: 1300px) {
    width: 375px;
  }
  @media (max-width: 768px) {
    margin-left: unset;
    max-height: calc(100% - 130px);
  }
}

.preview-popup-close {
  margin-left: auto;
  width: 20px;
  height: 21.168px;
  cursor: pointer;
}

.preview-popup-title {
  color: #08105D;
  font-family: "Noto Sans JP";
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.456px;
  margin-bottom: 12px;
}

.preview-popup-sub {
  color: #000;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  width: 231px;
}

.preview-popup-frame {
  border: 0;
  flex: 1;
  width: 100%;
}

.redirect-button-disabled {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #707070;
}

hr {
  margin: 8px 0;
  border: 1px solid #707070
}

.header {
  color:#263242;
  font-size: 22px;
  font-weight: 500
}

.key-text {
  color: #000000;
  font-size: 18px;
  font-weight: 500
}

.mt-10 {
  margin-top: 10px;
}

.d-flex {
  display: flex !important;;
}

.flex-auto {
  flex: auto !important;
  width: 75% !important;
}

.mt-20 {
  margin-top: 20px;
}

#job-detail-text {
  border: none !important;;
  pointer-events: none !important;
  padding: 0 !important;
}

.preview-image-container {
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .preview-image-box {
    position: relative;
    
    .preview-image {
      width: 291.164px;
      height: 214.547px;
      border-radius: 6px;
      background: #A6A6A6;
      object-fit: cover;
      border: 1px solid #A6A6A6;
    }

    .preview-image-text {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #A6A6A660;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(1px);
      border-radius: 6px;

      color: #000;
      font-family: "Noto Sans JP"!important;
      font-size: 14px;
      font-weight: 500;
      line-height: 190%;
      letter-spacing: -0.154px;
      padding: 12px;

      .main-preview {
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 8px;
      }
    }
  }

  .preview-image-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 29px;

    .preview-image-arrow {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    .preview-image-dot-container {
      display: flex;
      flex-direction: row;
      gap: 6px;

      .preview-image-dot {
        width: 13px;
        height: 13px;
        background-color: #D9D9D9;
        border-radius: 13px;
        &-selected {
          @extend .preview-image-dot;
          background-color: #33A8ED;
        }
      }
    }
  }

  .preview-first-text {
    color: #6F6E6E;
    text-align: center!important;
    font-family: "Noto Sans JP"!important;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
  }
}

.job-images-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    input {
      display: none;
    }
    label {
      margin-bottom: 0px !important;
    }
    .already-added-image-container-2 {
      width: 90px;
      height: 90px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #007AFF;
      border-radius: 10px;
      position: relative;
      margin-top: 0px;
      .close-icon {
        position: absolute;
        top: -11px;
        height: 28px;
        width: 28px;
        right: -11px;
        cursor: pointer;
        img {
          height: 28px;
          width: 28px;
        }
      }
      .move-icon {
        position: absolute;
        width: 28px;
        height: 28px;
        top: 50%;
        right: calc(100% - 2px);
        transform: translateY(-50%);
        cursor: pointer;
      }
      .already-added-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .single-job-image {
      margin: 10px;
      width: 90px;
      height: 90px;
      background-size: contain;
      cursor: pointer;
      background-repeat: no-repeat;
      background-image: url('../../../images/image-upload-icon-normal.png');
      &:hover {
        background-image: url('../../../images/image-upload-icon-hover.png');
      }
      &-disabled {
        @extend .single-job-image;
        cursor: default;
        &:hover {
          background-image: url('../../../images/image-upload-icon-normal.png');
        }
      }
      // height: 125px;
    }
  }
