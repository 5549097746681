
@import './variables';
@import './elements.scss';
@import './mixin.scss';

.App {
  text-align: center;
}

.drawer-styles {
  width: 100%;
  height: 100%;
}
