.company-details-part5-container {
  margin: 0px auto;
  width: 400px;
}
@media (max-width: 768px) {
  .company-details-part5-container {
    width: 300px;
  }
}
.company-details-part5-container .main-detail {
  color: #000000;
  font-size: 19px;
  font-weight: bold;
  margin: 30px auto;
  line-height: 23px;
  opacity: 0.8;
  width: 400px;
  text-align: center;
}
@media (max-width: 768px) {
  .company-details-part5-container .main-detail {
    width: 300px;
    font-size: 14px;
    margin: 20px auto;
  }
}
.company-details-part5-container .sub-detail {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.8;
  text-align: center;
}
@media (max-width: 768px) {
  .company-details-part5-container .sub-detail {
    width: 300px;
    font-size: 13px;
  }
}