.registration-success-modal {
  margin-left: 0!important;
  @media (max-width: 768px) {
    margin-left: 0!important;
  }
  .container {
    width: 493px;
    height: 307px;
    gap: 8px;
    border-radius: 8px 0 0 0;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;

    .close-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      fill: #000;
      position: absolute;
      top: 12.6px;
      right: 16.8px;
      cursor: pointer;
    }

    .mail-icon {
      width: 57px;
      height: 56px;
      flex-shrink: 0;
      display:block;
      margin:auto;
    }

    .title {
      color: #08105D;
      font-family: 'Noto Sans JP'!important;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.264px;
    }

    .email {
      color: #33A8ED;
      text-align: center;
      font-family: "Noto Sans JP"!important;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.22px;

      .confirmation-message {
        color: #4E4E4E;
        font-family: "Noto Sans JP"!important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.154px;
      }
    }

    .verify-email-message {
      color: #4E4E4E;
      text-align: center;
      font-family: "Noto Sans JP"!important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.154px;
    }

    .check-spam-message {
      color: #5A5A5A;
      text-align: center;
      font-family: "Noto Sans JP"!important;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.132px;
    }
  }
}
