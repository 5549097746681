.application-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 80px;
  background-color: #FFFFFF;
  padding-bottom: 50px;
  min-height: 100vh;
}
@media (max-width: 768px) {
  .application-container {
    width: 100%;
    margin-left: 0px;
  }
}
.application-container .main-inner-header-container {
  z-index: 99;
}
.application-container .sidebar-container {
  z-index: 1000;
}
.application-container .vertical-spacer {
  height: 5px;
}
@media (max-width: 768px) {
  .application-container .vertical-spacer {
    height: 0;
  }
}
.application-container .download-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px;
  margin-bottom: 8px;
}
@media (max-width: 850px) {
  .application-container .download-button-container {
    width: unset;
    margin: 20px 16px;
    margin-bottom: 8px;
  }
}
.application-container .download-button {
  border-radius: 8px;
  border: 2px solid #33A8ED;
  display: inline-flex;
  padding: 10px 24px 10px 16px;
  align-items: flex-start;
  gap: 4px;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
}
.application-container .download-button .download-text {
  color: #33A8ED;
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.176px;
}
.application-container .show-filters {
  display: flex;
  justify-content: space-between;
  width: 850px;
  margin: 20px auto;
}
.application-container .show-filters button {
  min-width: 150px;
  outline: none;
  border: 1px solid #007AFF;
  font-size: 13px;
  height: 30px;
  border-radius: 19px;
}
@media (max-width: 768px) {
  .application-container .show-filters button {
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .application-container .show-filters {
    width: 350px;
    flex-wrap: wrap;
  }
}
.application-container .application-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin: 16px 20px;
  font-family: "Noto Sans JP" !important;
}
@media (max-width: 768px) {
  .application-container .application-list {
    margin: 16px;
  }
}
.application-container .application-list .no-applicants-text {
  margin-top: 40px;
}
.application-container .appl-container {
  border-radius: 5px;
  border: 1px solid rgba(0, 122, 255, 0.2);
  box-shadow: 0px 0px 35px 4px rgba(0, 0, 0, 0.1);
  max-width: 850px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 14px 11px;
  position: relative;
  gap: 16px;
}
@media (max-width: 768px) {
  .application-container .appl-container {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }
}
.application-container .appl-main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 24px;
  flex: 1;
  cursor: pointer;
}
@media (max-width: 768px) {
  .application-container .appl-main-container {
    padding-bottom: 6px;
    align-items: center;
    border-bottom: 1px solid rgba(85, 85, 85, 0.4);
    flex: auto;
  }
}
@media (max-width: 300px) {
  .application-container .appl-main-container {
    flex-direction: column;
  }
}
.application-container .appl-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  cursor: pointer;
}
@media (max-width: 768px) {
  .application-container .appl-data-container {
    flex: auto;
  }
}
.application-container .appl-icon-container {
  display: flex;
  gap: 7px;
  position: absolute;
  top: 8px;
  right: 8px;
}
@media (max-width: 768px) {
  .application-container .appl-icon-container {
    top: unset;
    right: unset;
    padding-bottom: 8px;
    position: relative;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(85, 85, 85, 0.4);
  }
}
.application-container .appl-action-icon {
  cursor: default;
}
.application-container .appl-action-icon * {
  padding: 2px;
  box-sizing: content-box;
}
.application-container .appl-enabled {
  cursor: pointer;
}
.application-container .appl-enabled :hover {
  border-radius: 30px;
  background: rgba(85, 85, 85, 0.15);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.application-container .appl-main-img {
  cursor: "pointer";
  width: 118px;
  height: 118px;
  border-radius: 100%;
  border: 1px solid #D9D9D9;
  object-fit: cover;
}
@media (max-width: 768px) {
  .application-container .appl-main-img {
    width: 30%;
    height: fit-content;
    aspect-ratio: 1;
  }
}
.application-container .appl-main-info-container {
  position: relative;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  text-align: left;
  padding-top: 32px;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .application-container .appl-main-info-container {
    margin-top: 0;
    padding-top: 28px;
    padding-bottom: 0px;
  }
}
.application-container .appl-main-status {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 10px;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  text-wrap: nowrap;
  white-space: nowrap;
  max-width: 300px;
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 768px) {
  .application-container .appl-main-status {
    font-size: 10px;
  }
}
.application-container .appl-main-name {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 768px) {
  .application-container .appl-main-name {
    font-size: 16.5px;
  }
}
.application-container .appl-main-date {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 24px;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
}
@media (max-width: 768px) {
  .application-container .appl-main-date {
    text-wrap: wrap !important;
    white-space: wrap !important;
    font-size: 16.5px;
    height: auto;
  }
}
.application-container .appl-data-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}
.application-container .appl-data-label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-wrap: nowrap;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .application-container .appl-data-label {
    font-size: 9px;
    line-height: 24px;
  }
}
.application-container .appl-data-value {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24px;
  text-align: left;
}
.application-container .appl-data-value * {
  margin-right: 4px;
}
@media (max-width: 768px) {
  .application-container .appl-data-value {
    font-size: 9px;
  }
  .application-container .appl-data-value * {
    margin-right: 2px;
  }
}
.application-container .screening-container {
  display: flex;
  gap: 21px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
@media (max-width: 768px) {
  .application-container .screening-container {
    top: unset;
    right: unset;
    padding-bottom: 8px;
    position: relative;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(85, 85, 85, 0.4);
  }
}
.application-container .screening-container .option-container {
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.application-container .screening-container .option-container .option-icon {
  height: 10px;
  width: 10px;
}
.application-container .screening-container .option-container .option-text {
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
}

.main-box-list {
  padding: 0px;
  max-width: 1189px;
  width: 100%;
}
@media (max-width: 1024px) {
  .main-box-list {
    padding: 0 0;
    padding-top: 0px;
  }
}
.main-box-list .first-box {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  background: #FFF;
  border: 2px solid #5FBBFF;
  border-radius: 16px;
  position: relative;
  text-decoration: none;
}
.main-box-list .first-box :hover {
  text-decoration: none;
}
@media (max-width: 1024px) {
  .main-box-list .first-box {
    flex-direction: column;
    padding: 8px;
  }
}
.main-box-list .first-box .details {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details {
    gap: 8px;
  }
}
.main-box-list .first-box .details .profile-details {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.main-box-list .first-box .details .profile-details .appl-main-img {
  border-radius: 100%;
  border: 1px solid #D9D9D9;
  width: 88px;
  height: fit-content;
  aspect-ratio: 1;
  object-fit: cover;
}
.main-box-list .first-box .details .profile-details .name-info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.main-box-list .first-box .details .profile-details .name-info-box .app-name {
  color: #3B3B3B;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .profile-details .name-info-box .app-name {
    font-size: 16px;
  }
}
.main-box-list .first-box .details .profile-details .name-info-box .dot-infos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .profile-details .name-info-box .dot-infos {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 10px;
  }
}
.main-box-list .first-box .details .profile-details .name-info-box .dot-infos .info-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
}
.main-box-list .first-box .details .profile-details .name-info-box .dot-infos .info-box .dot {
  min-width: 10px;
  min-height: 10px;
  background-color: #FFBE00;
  border-radius: 10px;
  margin-right: 2px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .profile-details .name-info-box .dot-infos .info-box .dot {
    min-width: 6px;
    min-height: 6px;
  }
}
.main-box-list .first-box .details .profile-details .name-info-box .dot-infos .info-box .info {
  color: #3B3B3B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .profile-details .name-info-box .dot-infos .info-box .info {
    font-size: 12px;
  }
}
.main-box-list .first-box .details .icon-appl-mobile {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .icon-appl-mobile {
    display: flex;
    flex-wrap: wrap;
  }
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile {
  display: none;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .icon-appl-mobile .icon-mobile {
    display: flex;
  }
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img {
  width: 60px;
  height: 60px;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container {
  position: relative;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container .icon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0F1A81;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container .tline {
  display: flex;
  flex-direction: row;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container .t1 {
  font-weight: 500;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container .t2 {
  font-weight: 600;
}
.main-box-list .first-box .details .icon-appl-mobile .icon-mobile .icon-img-container .t3 {
  font-weight: 700;
}
.main-box-list .first-box .details .icon-appl-mobile .appl-btns-mobile {
  display: none;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 0px;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .icon-appl-mobile .appl-btns-mobile {
    display: flex;
  }
}
.main-box-list .first-box .details .icon-appl-mobile .appl-btns-mobile .appl-enabled {
  cursor: pointer;
}
.main-box-list .first-box .details .detail-divider {
  width: calc(100% - 32px);
  height: 2px;
  background-color: #C1C1C1;
  border: 2px;
  margin: 0px 16px;
  display: none;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .detail-divider {
    display: block;
    width: 100%;
    margin: 0;
  }
}
.main-box-list .first-box .details .status-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.main-box-list .first-box .details .status-line .job-type-box {
  display: flex;
  min-width: 102px;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.154px;
  line-height: 21px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .status-line .job-type-box {
    padding: 0px 8px;
    height: 24px;
    min-width: 66px;
    font-size: 10px;
    letter-spacing: -0.11;
  }
}
.main-box-list .first-box .details .status-line .job-status-box {
  display: flex;
  min-width: 298px;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.154px;
  line-height: 24px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .status-line .job-status-box {
    padding: 0px 8px;
    height: 24px;
    min-width: 206px;
    font-size: 10px;
    letter-spacing: -0.11;
  }
}
.main-box-list .first-box .details .status-line .job-date {
  color: #08105D;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .status-line .job-date {
    font-size: 14px;
  }
}
.main-box-list .first-box .details .main-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .main-line {
    font-size: 12px;
    align-items: flex-start;
  }
}
.main-box-list .first-box .details .main-line .line-title {
  color: #08105D;
  margin-right: 12px;
  min-width: 56px;
  text-align: left;
  font-weight: 700;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .main-line .line-title {
    min-width: 60px;
    margin-right: 2px;
  }
}
.main-box-list .first-box .details .main-line .line-title2 {
  color: #000;
  margin-left: 0px;
  margin-right: 12px;
  min-width: 70px;
  text-align: left;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .main-line .line-title2 {
    margin-right: 0px;
    margin-left: 12px;
  }
}
.main-box-list .first-box .details .main-line .line-text {
  color: #000;
  text-align: left;
}
.main-box-list .first-box .details .main-line-reverse {
  width: 100%;
  gap: 72px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .details .main-line-reverse {
    flex-direction: row-reverse;
    gap: 18px;
    justify-content: space-between;
  }
}
.main-box-list .first-box .apply-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  right: 16px;
  height: calc(100% - 16px);
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box {
    padding-top: 8px;
    position: relative;
    right: unset;
    height: unset;
  }
}
.main-box-list .first-box .apply-box .appl-btns {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 6px;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .appl-btns {
    display: none;
  }
}
.main-box-list .first-box .apply-box .appl-btns .appl-enabled {
  cursor: pointer;
}
.main-box-list .first-box .apply-box .icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .icon-box {
    display: none;
  }
}
.main-box-list .first-box .apply-box .icon-box .icon-img {
  width: 60px;
  height: 60px;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container {
  position: relative;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container .icon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0F1A81;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container .tline {
  display: flex;
  flex-direction: row;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container .t1 {
  font-weight: 500;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container .t2 {
  font-weight: 600;
}
.main-box-list .first-box .apply-box .icon-box .icon-img-container .t3 {
  font-weight: 700;
}
.main-box-list .first-box .apply-box .approve-box {
  display: flex;
  flex-direction: row;
  gap: 8px;
  text-wrap: nowrap;
  width: 100%;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .approve-box {
    flex-wrap: wrap;
  }
}
@media (max-width: 350px) {
  .main-box-list .first-box .apply-box .approve-box {
    align-items: center;
  }
}
.main-box-list .first-box .apply-box .approve-box .accept-btn {
  display: inline-flex;
  cursor: pointer;
  padding: 10px 32px 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #33A8ED;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  height: 44px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .approve-box .accept-btn {
    font-size: 14px;
    height: 36px;
    padding: 8px 16px;
  }
  .main-box-list .first-box .apply-box .approve-box .accept-btn svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 500px) {
  .main-box-list .first-box .apply-box .approve-box .accept-btn {
    padding: 8px;
    height: 30px;
    border-radius: 5px;
    gap: 6px;
  }
  .main-box-list .first-box .apply-box .approve-box .accept-btn svg {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 350px) {
  .main-box-list .first-box .apply-box .approve-box .accept-btn {
    flex: 1;
  }
}
.main-box-list .first-box .apply-box .approve-box .open-btn {
  display: inline-flex;
  cursor: pointer;
  padding: 10px 32px 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #FFF;
  color: #FFBE00;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #FFBE00;
  height: 44px;
  margin-right: 16px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .approve-box .open-btn {
    font-size: 14px;
    height: 36px;
    padding: 8px 16px;
    margin-right: auto;
  }
}
@media (max-width: 500px) {
  .main-box-list .first-box .apply-box .approve-box .open-btn {
    padding: 8px;
    height: 30px;
    border-radius: 5px;
    gap: 6px;
  }
  .main-box-list .first-box .apply-box .approve-box .open-btn svg {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 350px) {
  .main-box-list .first-box .apply-box .approve-box .open-btn {
    width: 100%;
  }
}
.main-box-list .first-box .apply-box .approve-box .reject-btn {
  display: inline-flex;
  cursor: pointer;
  padding: 10px 32px 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #FFF;
  color: #33A8ED;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #33A8ED;
  height: 44px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .approve-box .reject-btn {
    font-size: 14px;
    height: 36px;
    padding: 8px 16px;
  }
  .main-box-list .first-box .apply-box .approve-box .reject-btn svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 500px) {
  .main-box-list .first-box .apply-box .approve-box .reject-btn {
    padding: 8px;
    height: 30px;
    border-radius: 5px;
    gap: 6px;
  }
  .main-box-list .first-box .apply-box .approve-box .reject-btn svg {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 350px) {
  .main-box-list .first-box .apply-box .approve-box .reject-btn {
    flex: 1;
  }
}
.main-box-list .first-box .apply-box .approve-box .disabled-btn {
  display: inline-flex;
  background-color: rgba(85, 85, 85, 0.6);
  padding: 10px 32px 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  height: 44px;
}
@media (max-width: 1024px) {
  .main-box-list .first-box .apply-box .approve-box .disabled-btn {
    font-size: 14px;
    height: 36px;
    padding: 8px 24px 8px 16px;
  }
  .main-box-list .first-box .apply-box .approve-box .disabled-btn svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 500px) {
  .main-box-list .first-box .apply-box .approve-box .disabled-btn {
    padding: 8px;
    height: 30px;
    border-radius: 5px;
    gap: 6px;
  }
  .main-box-list .first-box .apply-box .approve-box .disabled-btn svg {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 350px) {
  .main-box-list .first-box .apply-box .approve-box .disabled-btn {
    flex: 1;
  }
}