.main-inner-header-container {
  height: 75px;
  display: block;
  justify-content: flex-start;
  align-items: flex-end;
  color: #08105D;
  font-size: 48px;
  font-weight: normal;
  position: fixed;
  max-width: 100vw;
  top: 0;
  z-index: 999;
  width: calc(100% - 220px);
  background-color: #FFFFFF;
}
@media (max-width: 768px) {
  .main-inner-header-container {
    height: 75px;
    top: 0;
    width: 100%;
    left: 0;
    margin-left: 0px;
    background-color: #253242;
    color: #FFFFFF;
  }
}
.main-inner-header-container .header-mobile-number-message {
  width: 100%;
  position: absolute;
  top: 0px;
  font-size: 12px;
  padding: 5px;
  background-color: #cce5ff;
  color: #004085;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.18);
}
@media (max-width: 1024px) {
  .main-inner-header-container .header-mobile-number-message {
    font-size: 10px;
  }
}
.main-inner-header-container .header-mobile-number-message .message {
  font-size: 11px;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
}
@media (max-width: 1024px) {
  .main-inner-header-container .header-mobile-number-message .message {
    font-size: 11px;
    text-align: left;
    padding: 0px 10px;
  }
}
.main-inner-header-container .header-notification-message {
  width: 100%;
  position: absolute;
  top: calc(100vh - 40px);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.18);
}
.main-inner-header-container .header-notification-message .message {
  font-size: 11px;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
}
@media (max-width: 1024px) {
  .main-inner-header-container .header-notification-message .message {
    font-size: 11px;
    text-align: left;
    padding: 0px 10px;
  }
}
.main-inner-header-container .header-notification-message .blue {
  background-color: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}
.main-inner-header-container .header-notification-message .black {
  color: #000000;
}
.main-inner-header-container .header-notification-message .red {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}
.main-inner-header-container .inner-header-container {
  display: flex;
  position: relative;
  height: 75px;
  justify-content: center;
  background-color: #FFFFFF;
  align-items: center;
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container {
    width: 85%;
  }
}
@media (max-width: 768px) {
  .main-inner-header-container .inner-header-container {
    background-color: #253242;
    width: 100%;
  }
}
.main-inner-header-container .inner-header-container .icon {
  color: #007AFF;
  font-size: 25px;
  top: 25px;
  left: 10px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 40;
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container .icon {
    color: #FFFFFF;
  }
}
.main-inner-header-container .inner-header-container .sidebar-icon {
  display: none;
  margin-left: 10px;
  font-size: 25px;
  color: #FFFFFF;
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container .sidebar-icon {
    display: block;
    z-index: 999;
  }
}
.main-inner-header-container .inner-header-container .header-text {
  min-width: 140px;
  width: 100%;
  font-size: 48px !important;
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container .header-text {
    text-align: left;
    padding-left: 10px;
  }
}
@media (max-width: 768px) {
  .main-inner-header-container .inner-header-container .header-text {
    text-align: center;
    padding-left: 0px;
    margin-left: -30px;
    font-size: 28px !important;
  }
}
.main-inner-header-container .inner-header-container .header-text-new {
  color: #08105D;
  font-family: "Noto Sans JP";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.44px;
  min-width: 140px;
  width: 100%;
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container .header-text-new {
    text-align: left;
    padding-left: 10px;
    min-width: 140px;
  }
}
@media (max-width: 768px) {
  .main-inner-header-container .inner-header-container .header-text-new {
    text-align: center;
    padding-left: 0px;
    margin-left: -30px;
    font-size: 32px !important;
    min-width: 140px;
    width: 100%;
    color: #FFFFFF;
  }
}
.main-inner-header-container .inner-header-container .back-arrow {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.main-inner-header-container .inner-header-container .button-container {
  display: flex;
  position: absolute;
  right: 20px;
}
@media (max-width: 768px) {
  .main-inner-header-container .inner-header-container .button-container {
    right: 5px;
  }
}
@media (max-width: 1024px) {
  .main-inner-header-container .inner-header-container .button-container .button-two {
    display: none;
  }
}
.main-inner-header-container .inner-header-container .button-container .button {
  border: 1px solid #007AFF;
  margin: 5px;
}
.main-inner-header-container .inner-header-container .button-container .close-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}