.add-jobs-main-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  margin-left: 220px;
  width: calc(100% - 220px);
  overflow-x: hidden;
  display: flex;
}
@media (max-width: 768px) {
  .add-jobs-main-container {
    top: 0;
    margin: 0px auto;
    width: 100%;
    background-color: #FFFFFF;
  }
}
@media (max-width: 768px) {
  .add-jobs-main-container {
    left: 0px;
  }
}
.add-jobs-main-container .add-jobs-inner-header-container {
  display: none;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-jobs-inner-header-container {
    display: block;
  }
}
.add-jobs-main-container .help-icon {
  position: absolute;
  top: 10%;
  right: 20%;
  cursor: pointer;
}
.add-jobs-main-container .add-job-outer-container {
  margin: 0px auto;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container {
    margin: 0px auto;
    width: 100%;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container {
  width: 500px;
  top: 50px;
  background-color: #FFFFFF;
  box-shadow: 0 20px 35px 1px rgba(0, 0, 0, 0.08);
  margin: 80px 0 50px;
  border-radius: 15px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container {
    width: calc(100% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-header-container {
  background-color: #263242;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  height: 100px;
  padding: 0 16px;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-header-container .header {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-header-container .header {
    font-size: 20px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-header-container .sub-header {
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  height: 40px;
  line-height: 20px;
  opacity: 0.8;
  text-align: center;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-header-container .sub-header {
    font-size: 12px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .single-stage {
  max-width: 375px;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .single-stage {
    width: calc(100% - 32px);
    margin: 20px 16px;
    max-width: unset;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 10px auto;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container {
    width: 300px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage {
  width: 75px;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage {
    width: 75px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage .stage-id {
  border: 1px solid #263242;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage .stage-id {
    font-size: 15px;
    height: 35px;
    width: 35px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage .stage-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .stage-container .single-stage .stage-name {
    font-size: 10px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .company-details-close-button {
  background-color: #007AFF;
  border-radius: 27px;
  width: 150px;
  height: 47px;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  line-height: 20px;
  outline: none;
  text-align: center;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container {
  width: 375px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container {
    width: 300px;
  }
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .submit-container .submit-warning {
  color: #FF6565;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.154px;
  margin-left: 12px;
  margin-top: 6px;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .next-button {
  background-color: #007AFF;
  border-radius: 27px;
  width: 150px;
  height: 47px;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  text-align: center;
  border: none;
  text-wrap: nowrap;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .next-button:hover {
  background-color: #006ce0;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .previous-button {
  color: #007AFF;
  border-radius: 27px;
  width: 50px;
  display: flex;
  align-items: center;
  height: 47px;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  text-align: left;
  border: none;
}
.add-jobs-main-container .add-job-outer-container .add-job-inner-container .details-page-button-container .dummy-button {
  width: 150px;
  height: 2px;
  background-color: transparent;
}

.video-modal-container {
  margin-left: 220px !important;
}
@media (max-width: 768px) {
  .video-modal-container {
    margin-left: 0 !important;
  }
}
.video-modal-container .video-desktop {
  display: block;
}
@media (max-width: 768px) {
  .video-modal-container .video-desktop {
    display: none;
  }
}
.video-modal-container .video-desktop .close-button-container {
  width: 100%;
  height: 40px;
  display: flex;
  background-color: #000000;
  align-items: center;
  justify-content: flex-end;
}
.video-modal-container .video-desktop .close-button-container .close-video-button {
  cursor: pointer;
  font-size: 30px;
  margin-right: 10px;
  color: #FFFFFF;
}
.video-modal-container .video-mobile {
  display: none;
}
@media (max-width: 768px) {
  .video-modal-container .video-mobile {
    display: block;
  }
}

.image-modal-container {
  min-width: 800px;
  overflow-y: scroll;
}
@media (max-width: 1024px) {
  .image-modal-container {
    min-width: 100% !important;
  }
}
@media (max-width: 768px) {
  .image-modal-container {
    min-width: 100% !important;
  }
}
.image-modal-container::-webkit-scrollbar {
  width: 20px;
  -webkit-appearance: none;
}
.image-modal-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.image-modal-container::-webkit-scrollbar:horizontal {
  height: 10px;
}
.image-modal-container::-webkit-scrollbar:vertical {
  height: 10px;
}
.image-modal-container .image-modal-inner {
  width: 800px;
  margin: 0px auto;
  min-width: 800px;
  position: relative;
}
@media (max-width: 1024px) {
  .image-modal-container .image-modal-inner {
    width: 760px;
    min-width: 760px;
  }
}
@media (max-width: 768px) {
  .image-modal-container .image-modal-inner {
    width: 300px;
    min-width: 300px;
  }
}
.image-modal-container .image-modal-inner .create-post-modal-image {
  width: 800px !important;
  margin: 0px auto;
}
@media (max-width: 1024px) {
  .image-modal-container .image-modal-inner .create-post-modal-image {
    width: 760px !important;
  }
}
@media (max-width: 768px) {
  .image-modal-container .image-modal-inner .create-post-modal-image {
    width: 300px !important;
  }
}
.image-modal-container .image-modal-inner .close-button-container {
  width: 100%;
  height: 40px;
  position: sticky;
  display: flex;
  top: 0px;
  background-color: #000000;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .image-modal-container .image-modal-inner .close-button-container {
    width: 760px;
  }
}
@media (max-width: 768px) {
  .image-modal-container .image-modal-inner .close-button-container {
    width: 300px;
  }
}
.image-modal-container .image-modal-inner .close-button-container .close-video-button {
  cursor: pointer;
  font-size: 30px;
  margin-right: 10px;
  color: #FFFFFF;
}

.MuiRadio-root {
  width: auto !important;
}

.PT1-color {
  background-color: #FC6B6D !important;
}

.PT2-color {
  background-color: #28C1DD !important;
}

.FT-color {
  background-color: #1A9EFF !important;
}

.SSV-color {
  background-color: #7B70F7 !important;
}