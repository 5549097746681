.sales-merit-container {
  width: 100%;
  display: flex;
  height: 40px;
  border-bottom: 1px solid #DDD;
}
.sales-merit-container .text {
  color: #000000;
  font-size: 11px;
  font-weight: normal;
  padding: 13px 10px;
  width: 240px;
  text-align: left;
}
@media (max-width: 768px) {
  .sales-merit-container .text {
    width: 270px;
  }
}