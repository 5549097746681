.initial-company-details-modal-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.85);
}
@media (max-width: 768px) {
  .initial-company-details-modal-container {
    left: 0px;
  }
}
.initial-company-details-modal-container .modal-outer-container {
  margin-left: 220px;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container {
    margin-left: 0px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container {
  width: 500px;
  top: 50px;
  background-color: #FFFFFF;
  margin: 50px auto;
  border-radius: 15px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container {
    width: 350px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-header-container {
  background-color: #263242;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  height: 100px;
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-header-container .header {
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  text-align: center;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-header-container .header {
    font-size: 20px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-header-container .sub-header {
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  height: 40px;
  line-height: 20px;
  opacity: 0.8;
  text-align: center;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-header-container .sub-header {
    font-size: 12px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 10px auto;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container {
    width: 300px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage {
  width: 75px;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage {
    width: 75px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage .stage-id {
  border: 1px solid #263242;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage .stage-id {
    font-size: 15px;
    height: 35px;
    width: 35px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage .stage-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .stage-container .single-stage .stage-name {
    font-size: 10px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .company-details-close-button {
  background-color: #007AFF;
  border-radius: 27px;
  width: 150px;
  height: 47px;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  line-height: 20px;
  outline: none;
  text-align: center;
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .details-page-button-container {
  width: 375px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .initial-company-details-modal-container .modal-outer-container .modal-inner-container .details-page-button-container {
    width: 300px;
  }
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .details-page-button-container .next-button {
  background-color: #007AFF;
  border-radius: 27px;
  width: 150px;
  height: 47px;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  text-align: center;
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .details-page-button-container .previous-button {
  color: #007AFF;
  border-radius: 27px;
  width: 50px;
  display: flex;
  align-items: center;
  height: 47px;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  text-align: left;
}
.initial-company-details-modal-container .modal-outer-container .modal-inner-container .details-page-button-container .dummy-button {
  width: 150px;
  height: 2px;
  background-color: transparent;
}