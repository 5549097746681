.company-details-part2-container {
  .company-details-part2-form {
    width: 375px;
    margin: 20px auto;
    @media (max-width: 768px) {
      width: 300px;
    }
    .single-form-element {
      margin: 15px auto 5px;
    }
    .is-dispatch-agency-container {
      .dispatch-agency-text {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        text-align: left;
        margin: 10px 0px
      }
      .dispatch-agency-values {
        .dispatch-agency-single {
          margin: 10px 0px;
          display: flex;
          input {
            color: #007AFF;
          }
          .text {
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
    .street-address-container {
      .street-address-header {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        margin-top: 50px;
      }
    }
  }
}
