.register-page-validation-tooltip-container {
  height: auto;
}
.register-page-validation-tooltip-container .validation-header {
  height: 30px;
  border-bottom: 1px solid #d0021b;
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.register-page-validation-tooltip-container .validation-inner-container {
  float: left;
  margin: 0px auto;
  padding: 5px;
  color: #888;
  font-size: 12px;
  font-family: Noto Sans JP;
  line-height: 20px;
}

@media (max-width: 768px) {
  .layer-popover {
    display: none;
  }
}

.register-page-container {
  width: 100%;
  height: 100%;
  padding: 0px;
  padding-bottom: 25px;
}
.register-page-container .login-text-container {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 500px;
  padding: 0;
  background-color: #ffffff;
  -moz-box-shadow: 3px 3px 12px 3px #ccc;
  -webkit-box-shadow: 3px 3px 12px 3px #ccc;
  box-shadow: 3px 3px 12px 3px #ccc;
  border-radius: 8px;
}
.register-page-container .login-text-container .login-title {
  border-radius: 8px 8px 0px 0px;
  background: #33A8ED;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.72px;
}
@media (min-width: 1024px) {
  .register-page-container .login-text-container .login-title {
    font-weight: 400;
  }
}
.register-page-container .login-text-container .login-main-container {
  display: flex;
  padding: 12px 56px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.register-page-container .login-text-container .login-main-container .login-text {
  color: #555;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP;
  line-height: 24px;
  letter-spacing: 0.32px;
}
@media (max-width: 350px) {
  .register-page-container .login-text-container .login-main-container {
    padding: 12px 16px;
  }
}
.register-page-container .login-text-container button {
  display: flex;
  padding: 8px 64px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border-style: none;
  background: #33A8ED;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
}
@media (max-width: 1024px) {
  .register-page-container .login-text-container {
    width: 350px;
  }
}
@media (max-width: 768px) {
  .register-page-container .login-text-container {
    width: 350px;
    font-size: 12px;
  }
  .register-page-container .login-text-container button {
    min-width: 200px !important;
  }
}
@media (max-width: 350px) {
  .register-page-container .login-text-container {
    width: 95%;
  }
}
.register-page-container .login-modal-container {
  outline: none;
  width: 500px;
  margin: 100px auto;
}
.register-page-container .login-modal-container .login-modal-inner .responsive-header {
  border-radius: 8px 8px 0px 0px;
  background: #33A8ED !important;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.72px;
}
@media (min-width: 1024px) {
  .register-page-container .login-modal-container .login-modal-inner .responsive-header {
    font-weight: 400;
  }
}
@media (max-width: 1024px) {
  .register-page-container .login-modal-container {
    width: 450px;
  }
}
@media (max-width: 768px) {
  .register-page-container .login-modal-container {
    width: 100%;
  }
}
.register-page-container .login-modal-container .login-modal-inner {
  background-color: #ffffff;
}
.register-page-container .login-modal-container .login-modal-inner .responsive-header {
  border-radius: 8px 8px 0px 0px;
  background: #33A8ED !important;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.72px;
}
@media (min-width: 1024px) {
  .register-page-container .login-modal-container .login-modal-inner .responsive-header {
    font-weight: 400;
  }
}
.register-page-container .login-modal-container .login-modal-inner .responsive-header .login-header-text {
  line-height: 56px;
  font-size: 25px;
  color: #484848;
}
.register-page-container .login-modal-container .login-modal-inner .responsive-header .burger {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.register-page-container .login-modal-container .login-modal-inner .login-inner-modal {
  display: block;
  width: 350px;
  padding: 10px;
  margin: 0px auto;
}
.register-page-container .login-modal-container .login-modal-inner .login-inner-modal .alert-danger {
  height: 40px;
  color: #ed5356;
  width: 100%;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  max-width: 500px;
  padding: 10px 0px;
  margin: 10px auto !important;
  color: #ed5356 !important;
  background-color: transparent !important;
}
.register-page-container .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container {
  margin: 20px 0px;
  width: 100%;
}
.register-page-container .login-modal-container .login-modal-inner .login-inner-modal .submit-button-container .forgot-password-text {
  font-size: 13px;
  text-align: center;
  padding: 10px;
}
.register-page-container .register-form-container {
  margin: 0px auto;
  padding: 0;
  width: 500px;
  min-height: 300px;
  background-color: #ffffff;
  -moz-box-shadow: 3px 3px 12px 3px #ccc;
  -webkit-box-shadow: 3px 3px 12px 3px #ccc;
  box-shadow: 3px 3px 12px 3px #ccc;
  border-radius: 8px;
}
@media (max-width: 1024px) {
  .register-page-container .register-form-container {
    width: 350px;
  }
}
@media (max-width: 350px) {
  .register-page-container .register-form-container {
    width: 95%;
  }
}
.register-page-container .register-form-container .register-form-title {
  border-radius: 8px 8px 0px 0px;
  background: #33A8ED;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.72px;
}
@media (min-width: 1024px) {
  .register-page-container .register-form-container .register-form-title {
    font-weight: 400;
  }
}
.register-page-container .register-form-container .register-form-main-container {
  display: flex;
  padding: 12px 56px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
@media (max-width: 1024px) {
  .register-page-container .register-form-container .register-form-main-container {
    padding: 12px 16px;
  }
}
.register-page-container .register-form-container .register-form-main-container .register-form-top-text {
  color: #555;
  text-align: center;
  font-size: 14px;
  font-family: Noto Sans JP;
  line-height: 24px;
}
.register-page-container .register-form-container .register-form-main-container .register-steps {
  border-radius: 8px;
  border: 1px solid #33A8ED;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.register-page-container .register-form-container .register-form-main-container .register-steps .register-steps-title {
  border-bottom: 2px solid #33A8ED;
  color: #33A8ED;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP;
  font-weight: 500;
  width: fit-content;
  padding: 10px 0px 0px;
}
@media (min-width: 1024px) {
  .register-page-container .register-form-container .register-form-main-container .register-steps .register-steps-title {
    font-weight: 400;
  }
}
.register-page-container .register-form-container .register-form-main-container .register-steps .flow-steps-container {
  display: flex;
  justify-content: space-between;
  padding: 3px 24px;
  width: 100%;
  align-items: stretch;
}
@media (max-width: 1024px) {
  .register-page-container .register-form-container .register-form-main-container .register-steps .flow-steps-container {
    padding: 3px 12px;
  }
}
.register-page-container .register-form-container .register-form-main-container .register-steps .flow-steps-container .flow-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 3px 0px;
}
.register-page-container .register-form-container .register-form-main-container .register-steps .flow-steps-container .flow-step .step-title {
  color: #555;
  text-align: center;
  font-size: 14px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 16px;
}
@media (max-width: 1024px) {
  .register-page-container .register-form-container .register-form-main-container .register-steps .flow-steps-container .flow-step .step-title {
    font-size: 13px;
  }
}
.register-page-container .register-form-container .register-form-main-container .register-steps .register-steps-subtitle {
  color: #888;
  text-align: center;
  font-size: 13px;
  font-family: Noto Sans JP;
  line-height: 20px;
  padding: 10px 0px;
}
.register-page-container .register-form-container .register-form-main-container .register-steps .register-steps-call {
  color: #33A8ED;
  text-align: center;
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
  padding: 0px 0px 10px;
}
.register-page-container .register-form-container .register-form-main-container .form-fields {
  width: 100%;
  margin: 10px 0;
}
.register-page-container .register-form-container .register-form-main-container .terms-and-conditions {
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-family: Noto Sans JP;
  font-weight: 100;
  line-height: 40px;
  letter-spacing: 0.32px;
  color: #555555;
  margin-bottom: 16px;
}
.register-page-container .register-form-container .register-form-main-container .terms-and-conditions label {
  margin: 0px;
  margin-right: 6px;
}
.register-page-container .register-form-container .register-form-main-container .terms-and-conditions a {
  color: #33A8ED;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-underline-position: above;
}
.register-page-container .register-form-container .register-form-main-container .register-submit-button {
  background-color: #33A8ED;
  border-radius: 24px;
  justify-content: center;
  margin: 0px auto 10px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  max-width: 320px;
  width: 95%;
  padding: 12px 64px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Noto Sans JP;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.36px;
}
.register-page-container .register-form-container .register-form-main-container .register-submit-button:hover {
  background-color: #33A8ED;
}
.register-page-container .register-form-container .register-form-main-container .disabled-register {
  background-color: #888;
}
.register-page-container .register-form-container .register-form-main-container .disabled-register:hover {
  background-color: #888;
}