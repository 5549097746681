.popup-modal {

    &__container {
        border-radius: 8px;
        border: 1px solid #E9E9E9;
        background: #FFF;
        padding: 24px 46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            padding: 18px 14px;
        }
    }
    &__icon {
        width: 65px;
        height: 65px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            width: 47px;
            height: 47px;
            margin-bottom: 16px;
        }
    }

    &__title {
        color: #EF4D4D;
        font-family: "Noto Sans JP";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: -0.264px;
        margin-bottom: 16px;
        @media (max-width: 768px) {
            font-size: 18px;
            letter-spacing: -0.198px;
            margin-bottom: 12px;
        }
    }

    &__subtitle {
        color: rgba(0, 0, 0, 0.80);
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.176px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            font-size: 14px;
            letter-spacing: -0.154px;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__button {
        border-radius: 8px;
        padding: 10px 24px;
        min-width: 104px;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.176px;
        cursor: pointer;
        @media (max-width: 768px) {
            font-size: 14px;
            letter-spacing: -0.154px;
        }
    }

    &__cancel1 {
        border: 2px solid #FFBE00;
        background-color: #FFF;
        color: #FFBE00;
        &:hover {
            background-color: #FFBE0020;
        }
    }

    &__confirm1 {
        background: #33A8ED;
        color: #FFF;
        &:hover {
            background: #2f8ec5; 
        }
    }
}