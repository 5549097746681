.company-details-part3-container .company-details-part3-form {
  width: 375px;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .company-details-part3-container .company-details-part3-form {
    width: 300px;
  }
}
.company-details-part3-container .company-details-part3-form .single-form-element {
  margin: 15px auto 5px;
}