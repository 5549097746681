.success-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px!important;
  @media (max-width: 768px) {
    height: 450px;
    margin-left: 0!important;
  }
  .dialog-title {
    text-align: center;
    padding: 10px;
    background-color: #6FCCCF;
    height: 45px;
    // width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .main-close-icon {
      cursor: pointer;
    }
  }
  .dialog-message-container {
    text-align: center;
    height: 290px;
    width: 90%;
    overflow: hidden;
    @media (max-width: 768px) {
      width: 265px;
      height: 225px;
    }
    .ok-button {
      border: 2px solid #6FCCCF;
      color: #6FCCCF;
      width: 150px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 20px auto;
    }
    .close-icon-center {
      margin: 0px auto;
      .success-modal-header-text {
        color: #6FCCCF;
        font-size: 30px;
      }
    }
  }
}
