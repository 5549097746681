.company-details-part3-container {
  .company-details-part3-form {
    width: 375px;
    margin: 20px auto;
    @media (max-width: 768px) {
      width: 300px;
    }
    .single-form-element {
      margin: 15px auto 5px;
    }
  }
}
