.section-container {
  margin: 25px 0px;
  text-align: left;
}
.section-container .add-edit-button-description {
  font-size: 12px;
  margin: 5px 0px;
  text-align: left;
}
.section-container .section-header {
  color: #666666;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 5px;
}
.section-container .resubscribe-description-text {
  margin: 0px;
  margin-top: 5px;
}
.section-container .section-link {
  background-color: #007AFF;
  color: #FFFFFF;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.billing-page-container {
  margin-left: 220px;
  width: calc(100% - 220px);
  font-family: "Noto Sans JP";
  margin-top: 100px !important;
}
@media (max-width: 1024px) {
  .billing-page-container {
    width: 100%;
    margin-left: 0px;
  }
}
.billing-page-container .before-trial-text {
  margin: 20px auto;
  width: 731px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .billing-page-container .before-trial-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-page-container .before-trial-text {
    width: 350px;
  }
}
.billing-page-container .billing-failed-container {
  width: 700px !important;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .billing-page-container .billing-failed-container {
    width: 350px !important;
  }
}
.billing-page-container .billing-failed-container .status-description-text {
  margin: 20px auto;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .billing-page-container .billing-failed-container .status-description-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-page-container .billing-failed-container .status-description-text {
    width: 350px;
  }
}
.billing-page-container .status-description-text {
  margin: 20px auto;
  width: 550px;
  text-align: left;
}
@media (max-width: 1024px) {
  .billing-page-container .status-description-text {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-page-container .status-description-text {
    width: 350px;
  }
}
.billing-page-container .resubscribe {
  height: 40px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 100px;
  width: 300px;
  color: #007AFF;
  background-color: #FFFFFF;
  border: 1px solid #007AFF;
}
@media (max-width: 1024px) {
  .billing-page-container .resubscribe {
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-page-container .resubscribe {
    width: 350px;
  }
}
.billing-page-container .add-edit-button-description {
  font-size: 12px;
  margin: 5px 0px;
  text-align: left;
}
.billing-page-container .billing-page-inner {
  margin: 100px auto;
  width: 500px;
}
@media (max-width: 1024px) {
  .billing-page-container .billing-page-inner {
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-page-container .billing-page-inner {
    width: 350px;
    margin: 20px auto !important;
  }
}
.billing-page-container .billing-page-inner .transactions-button-container {
  width: 500px;
  margin: 20px auto !important;
}
@media (max-width: 768px) {
  .billing-page-container .billing-page-inner .transactions-button-container {
    width: 350px;
  }
}
.billing-page-container .billing-page-inner .transactions-button-container .section-link {
  margin: 20px auto;
  width: 350px !important;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px;
  border-radius: 4px !important;
  border: 2px solid #1A9EFF !important;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.8) !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important; /* 82.5px */
  letter-spacing: -0.605px !important;
}
.billing-page-container .billing-page-inner .single-box {
  width: 500px;
  border: 1px solid #007AFF;
  margin: 50px auto;
}
@media (max-width: 768px) {
  .billing-page-container .billing-page-inner .single-box {
    width: 350px;
    height: auto;
  }
}
.billing-page-container .billing-page-inner .single-box .single-box-header {
  background-color: #007AFF;
  color: #FFFFFF;
  height: 60px;
  line-height: 60px;
  font-size: 22px;
}
.billing-page-container .billing-page-inner .single-box .details-container {
  min-height: 120px;
  font-size: 18px;
  padding-top: 20px;
}
.billing-page-container .billing-page-inner .single-box .details-container .description {
  font-size: 12px;
  margin: 10px auto;
}
.billing-page-container .billing-page-inner .single-box .details-container .section-link {
  background-color: #FFFFFF;
  color: #007AFF;
  border: 1px solid #007AFF;
  margin: 20px auto;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.billing-page-container .billing-page-inner .usage-container {
  height: 200px;
  width: 300px;
  margin: 10px auto;
}
.billing-page-container .billing-page-inner .usage-container .header {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.billing-page-container .billing-page-inner .usage-container .header .tooltip-text {
  width: 12px;
  margin: 0px 5px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .billing-page-container .billing-page-inner .usage-container .header .tooltip-text {
    display: none;
  }
}
.billing-page-container .billing-page-inner .usage-container .usage-inner {
  display: flex;
  width: 300px;
  justify-content: center;
  margin: 20px auto;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .application {
  width: 145px;
  text-align: left;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .application .application-cells .blue-user-icon {
  font-size: 40px;
  margin: 0px 5px;
  color: #007AFF;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .application .application-cells .gray-user-icon {
  font-size: 40px;
  margin: 0px 5px;
  color: #E6E6E6;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .application .application-text {
  margin-top: 10px;
  font-size: 11px;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .divider {
  background-color: #D8D8D8;
  border: 1px solid #979797;
  width: 1px;
  margin: 0px 10px;
  height: 100px;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .usage .days {
  width: 182px;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container {
  max-width: 182px;
  display: flex;
  flex-wrap: wrap;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container .gray-cell {
  margin: 2px;
  min-height: 22px;
  min-width: 22px;
  background-color: #E6E6E6;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-cell-container .blue-cell {
  margin: 2px;
  min-height: 22px;
  min-width: 22px;
  background-color: #007AFF;
}
.billing-page-container .billing-page-inner .usage-container .usage-inner .usage .days .days-text {
  margin-top: 10px;
  font-size: 11px;
}
.billing-page-container .billing-page-inner .current-pack-container .pack-header-container {
  display: flex;
  justify-content: space-between;
}
.billing-page-container .billing-page-inner .current-pack-container .pack-header-container .header {
  color: #666666;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.billing-page-container .billing-page-inner .current-pack-container .pack-header-container .change-pack-button {
  border: 1px solid #007AFF;
  width: 105px;
  height: 26px;
  background-color: #FFFFFF;
  color: #007AFF;
  outline: none;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.29px;
  line-height: 11px;
  text-align: center;
}
.billing-page-container .billing-page-inner .current-pack-container .pack-name {
  color: #4A4A4A;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.billing-page-container .billing-page-inner .section-container {
  margin: 25px 0px;
  text-align: left;
}
.billing-page-container .billing-page-inner .section-container .add-edit-button-description {
  font-size: 12px;
  margin: 5px 0px;
  text-align: left;
}
.billing-page-container .billing-page-inner .section-container .section-header {
  color: #666666;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 5px;
}
.billing-page-container .billing-page-inner .section-container .resubscribe-description-text {
  margin: 0px;
  margin-top: 5px;
}
.billing-page-container .billing-page-inner .section-container .section-link {
  background-color: #007AFF;
  color: #FFFFFF;
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.width-auto {
  width: auto !important;
}

.billing-heading-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 120px */
  letter-spacing: -1.52px;
}

.billing-header-red {
  color: #F24822;
}

.billing-container-2 {
  margin: 24px auto;
  width: 731px;
}
@media (max-width: 1024px) {
  .billing-container-2 {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-container-2 {
    width: auto;
    margin: 24px 12px;
  }
}

.billing-warning-container {
  border-radius: 12px;
  border: 2px solid #197DFB;
  background: rgba(217, 217, 217, 0);
  padding: 24px 36px;
  margin: 12px auto;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 160%; /* 72px */
  letter-spacing: -0.495px;
  width: 731px;
}
@media (max-width: 1024px) {
  .billing-warning-container {
    width: 500px;
    margin: 12px auto;
  }
}
@media (max-width: 768px) {
  .billing-warning-container {
    width: auto;
    margin: 12px;
  }
}
.billing-warning-container .billing-warning-1 {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
}
.billing-warning-container .billing-warning-2 {
  color: #EA696C;
  font-weight: 700;
}
.billing-warning-container .billing-warning-3 {
  color: #000;
  font-weight: 400;
}
.billing-warning-container .billing-warning-3 a {
  color: #000;
  font-weight: 900;
}

.divider-line {
  margin: 24px auto;
  border: none;
  border-top: 2px dashed rgba(85, 85, 85, 0.6);
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 731px;
}
@media (max-width: 1024px) {
  .divider-line {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .divider-line {
    width: auto;
    margin: 24px 12px;
  }
}

.billing-subtext {
  margin: 8px auto;
  color: #1E1E1E;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.385px;
  width: 731px;
}
@media (max-width: 1024px) {
  .billing-subtext {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-subtext {
    width: auto;
    margin: 24px 12px;
  }
}

.billing-body-container {
  margin: 24px auto;
  padding: 12px 36px;
  border-radius: 4px;
  border: 1px solid rgba(85, 85, 85, 0.1);
  background: #FFF;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.15);
  width: 731px;
}
@media (max-width: 1024px) {
  .billing-body-container {
    width: 500px;
    margin: 20px auto;
    padding: 12px 24px;
  }
}
@media (max-width: 768px) {
  .billing-body-container {
    width: auto;
    margin: 24px 12px;
  }
}
.billing-body-container .billing-body-text {
  border-radius: 2px;
  background: #BDE3FF;
  backdrop-filter: blur(2px);
  margin: 24px 0px;
  text-align: left;
  padding: 20px 24px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 57px */
  letter-spacing: -0.418px;
}
.billing-body-container .billing-body-text strong {
  font-weight: 900;
  letter-spacing: 0.2px;
}

.billing-body-container-2 {
  margin: 24px auto;
  padding: 12px 12px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2470588235);
  background: rgba(217, 217, 217, 0);
  width: 731px;
}
@media (max-width: 1024px) {
  .billing-body-container-2 {
    width: 500px;
    margin: 20px auto;
  }
}
@media (max-width: 768px) {
  .billing-body-container-2 {
    width: auto;
    margin: 24px 12px;
  }
}
.billing-body-container-2 img {
  margin-top: 24px;
  margin-bottom: 12px;
  width: 100%;
}
.billing-body-container-2 .inquiry-line {
  text-align: right;
  color: #0D99FF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.44px;
}
.billing-body-container-2 .inquiry-line a {
  color: #0D99FF;
  text-decoration-line: underline;
}