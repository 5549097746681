$font: "M PLUS 1p";
$primary: #007AFF;
$secondary: #FFFFFF;
$danger: #ED5356;
$disabled: #9B9B9B;
$primaryTextColor: #FFFFFF;
$secondaryTextColor: #007AFF;
$headerBackground: #FFFFFF;
$modalBackground: #FFFFFF;
$headerBorderBottom: #E4E4E4;
$headerTextColor: #484848;
