.seeker-message-modal-container {
  outline: none;
  width: 600px;
  margin: 50px auto;
  background-color: #FFFFFF;
}
@media (max-width: 1024px) {
  .seeker-message-modal-container {
    margin: 0px auto;
    width: 450px;
  }
}
@media (max-width: 768px) {
  .seeker-message-modal-container {
    width: 90%;
  }
}
.seeker-message-modal-container .responsive-header {
  min-height: 50px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 50px;
}
.seeker-message-modal-container .responsive-header .login-header-text {
  font-size: 25px;
  color: #484848;
}
.seeker-message-modal-container .responsive-header .burger {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}
.seeker-message-modal-container .options-container {
  height: auto;
  padding: 30px 0px;
}
.seeker-message-modal-container .button-container {
  display: flex;
  padding-bottom: 30px;
  justify-content: right;
  gap: 24px;
  margin: 0px auto;
  width: 90%;
}

/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 5px;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #727272;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 5px;
  width: 7px;
  height: 13px;
  border: solid #FFFFFF;
  border-width: 0 1px 1px 0;
  transform: rotate(40deg);
}

.check-container input:checked ~ .checkmark {
  background-color: #0A57BF;
  border: 2px solid #0A57BF;
}

.badge-div {
  display: flex;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 13px;
  align-items: center;
  padding-left: 0px;
}

.badge {
  padding: 5px;
  background-color: #9F9F9F;
  color: #FFFFFF;
  border-radius: 10px;
}

.submitted-text {
  margin-left: 10px;
  color: #959494;
}

.flex-item {
  display: flex;
}

.flex-with-padding {
  display: flex;
  padding-left: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.responsive-display {
  display: flex;
}
@media (max-width: 768px) {
  .responsive-display {
    display: block;
  }
}

.responsive-width {
  width: 20%;
}
@media (max-width: 768px) {
  .responsive-width {
    width: 100%;
    margin-bottom: 5px;
  }
}